import { getData } from "./Storage";

const beep = require('../res/sounds/beep.mp3');
const click1 = require('../res/sounds/Button1.mp3');
const click2 = require('../res/sounds/Button2.mp3');
const click3 = require('../res/sounds/Button3.mp3');
const click4 = require('../res/sounds/Button4.mp3');
const click5 = require('../res/sounds/Button5.mp3');
const card_shuffle = require('../res/sounds/Cards Shuffling Long.wav');
const card_pick1 = require('../res/sounds/Mountain Audio - Cardboard Game Card Flip - 2.mp3');
const card_pick2 = require('../res/sounds/Mountain Audio - Cardboard Game Card Flip - 3.mp3');
const card_release = require('../res/sounds/Mountain Audio - Cardboard Game Card Flip - 5.mp3');
const card_drop = require('../res/sounds/Cards Shuffling Short.wav');
const card_declare = require('../res/sounds/Cards Shuffling Short.wav');
const winner = require('../res/sounds/Card Win.mp3');
const pop = require('../res/sounds/pop.wav');

export default class SoundFx{

  static soundOn = true;
  static beep = true;

  
  static beepFX = new Audio(beep);
  
  static click1FX = new Audio(click3);
  static click2FX = new Audio(click4);
  static toggleFX = new Audio(click5);

  static openFX = new Audio(click1);
  static closeFX = new Audio(click2);

  static card_shuffleFX = new Audio(card_shuffle);
  static card_pick1FX = new Audio(card_pick1);
  static card_pick2FX = new Audio(card_pick2);
  static card_releaseFX = new Audio( card_release);
  static card_dropFX = new Audio(card_drop);
  static card_declareFX = new Audio(card_declare);
  static winnerFX = new Audio(winner);
  static popFX = new Audio(pop);

  static onSoundSettingUpdate = () => {
    const setting = getData("sound");
    if(setting !== null && setting === "0"){
      SoundFx.soundOn = false;

      const beep = getData("beep");

      if(beep !== null && beep === "0"){
        SoundFx.beep = false;
      }    
      else{
        SoundFx.beep = true;
      }
    }    
    else{
      SoundFx.soundOn = true;
    }


  }

  static play = async (sound: "beep" | "open"| "close"| "click" | "click2"|"toggle"| "shuffle"| "card-pick"| "deck-pick"| "release"| "drop"| "declare"| "winner" | "pop") => {

    if(!SoundFx.soundOn && sound !== "beep")
      return;
    
    
    
    try{
      switch(sound){
        case "open":
          SoundFx.openFX.volume = .30;
          await SoundFx.openFX.play()
          break;
        case "close":
          SoundFx.closeFX.volume = .30;
          await SoundFx.closeFX.play()
        break;
        case "beep":
          if(!SoundFx.soundOn && !SoundFx.beep)
            return;

          await SoundFx.beepFX.play()
          break;
        case "click":
          SoundFx.click1FX.volume = .30;
          await SoundFx.click1FX.play();
          break;  
        case "click2":
          SoundFx.click2FX.volume = .30;
          await SoundFx.click2FX.play();
          break;
        case "toggle":
          SoundFx.toggleFX.volume = 0.30;
          await SoundFx.toggleFX.play();
          break;
        case "shuffle":
          await SoundFx.card_shuffleFX.play();
          break;
        case "card-pick":
          await SoundFx.card_pick1FX.play();
          break;
        case "deck-pick":
          await SoundFx.card_pick2FX.play();
          break;
        case "release":
          await SoundFx.card_releaseFX.play();
          break;
        case "drop":
          await SoundFx.card_dropFX.play();
          break;
        case "declare":
          await SoundFx.card_declareFX.play();
          break;
        case "winner":
          await SoundFx.winnerFX.play();
          break;
        case "pop":
          await SoundFx.popFX.play();
          break;
      }
    }
    catch(e){
      console.log(e);
    }
  }
}


SoundFx.onSoundSettingUpdate();