import AppConfig from "../Config";
import { gameMessage } from "../Helpers/Popups";


export const fetch_config = () => {
  // @ts-ignore

  const firebase = window.FirebasePlugin;

  firebase.getValue("ad_after_release_count",function(d:string){ 
    if(d)
      AppConfig.ad_after_release_count = parseInt(d);
  });
  firebase.getValue("initial_playing_points",function(d:string){ 
    if(d)
      AppConfig.initial_playing_points = parseInt(d);
  });

  firebase.getValue("playing_points_per_reward",function(d:string){ 
    if(d)
      AppConfig.playing_points_per_reward = parseInt(d);
  });

  firebase.getValue("show_reward_ad",function(d:string){ 
    if(d)
      AppConfig.show_reward_ad = d.toLowerCase() === "true";
  });
  firebase.getValue("force_reward_ad",function(d:string){ 
    if(d)
      AppConfig.force_reward_ad = d.toLowerCase() === "true";
  });

  firebase.getValue("show_ad_on_room_exit",function(d:string){ 
    if(d)
      AppConfig.show_ad_on_room_exit = d.toLowerCase() === "true";
  });
  firebase.getValue("show_ad_on_game_exit",function(d:string){ 
    if(d)
      AppConfig.show_ad_on_game_exit = d.toLowerCase() === "true";
  });
  firebase.getValue("show_ad_on_drop",function(d:string){ 
    if(d)
      AppConfig.show_ad_on_drop = d.toLowerCase() === "true";
  });
  firebase.getValue("show_ad_on_declare",function(d:string){ 
    if(d)
      AppConfig.show_ad_on_declare = d.toLowerCase() === "true";
  });
  firebase.getValue("show_ad_on_ready",function(d:string){ 
    if(d)
      AppConfig.show_ad_on_ready = d.toLowerCase() === "true";
  });

  firebase.getValue("game_summery_premium_only",function(d:string){ 
    if(d)
      AppConfig.game_summery_premium_only = parseInt(d)
  });

  firebase.getValue("premium_ad_after_count",function(d:string){ 
    if(d)
      AppConfig.premium_ad_after_count =  parseInt(d);
  });


  firebase.getValue("show_premium_ad_at",function(d:string){ 
    if(d)
      AppConfig.show_premium_ad_at =  parseInt(d);
  });

  
  firebase.getValue("load_fb_ads",function(d:string){ 
    if(d)
      AppConfig.load_fb_ads =  d.toLowerCase() === "true";
  });

  
  firebase.getValue("load_admob_ads",function(d:string){ 
    if(d)
      AppConfig.load_admob_ads =  d.toLowerCase() === "true";
  });
  
  firebase.getValue("show_interstitial_if_no_reward",function(d:string){ 
    if(d)
      AppConfig.show_interstitial_if_no_reward =  d.toLowerCase() === "true";
  });

    
  firebase.getValue("show_reward_video_ads",function(d:string){ 
    if(d)
      AppConfig.show_reward_video_ads =  d.toLowerCase() === "true";
  });

  firebase.getValue("retry_ad_video_timer",function(d:string){ 
    if(d)
      AppConfig.retry_ad_video_timer =  parseInt(d);
  });


  firebase.getValue("retry_ad_interstitial_timer",function(d:string){ 
    if(d)
      AppConfig.retry_ad_interstitial_timer =  parseInt(d);
  });

  firebase.getValue("show_reward_video_ads",function(d:string){ 
    if(d)
      AppConfig.show_reward_video_ads =  d.toLowerCase() === "true";
  });

  firebase.getValue("price_menu_text",function(d:string){ 
    if(d){
      AppConfig.price_menu_text =  d;
    }
  });

  firebase.getValue("pricing",function(d:any){ 
    if(d){
      try{
        AppConfig.pricing = JSON.parse(d);
      }
      catch(e){

      }
    }
  });

  firebase.getValue("minimimum_games_for_review",function(d:any){ 
    if(d){
      try{
        AppConfig.minimimum_games_for_review = JSON.parse(d);
      }
      catch(e){

      }
    }
  });
  
  firebase.getValue("force_update_versions",function(d:number[]){ 
    if(d && d.length){
      console.log("force_update_versions",d);
      
      const requestUpdate = () => {
        gameMessage(
          "An important update is available, please update your game to continue playing.",
          {
            button_text:"Update",
            closeButton: false,                        
        })
        .then(function(val){
          if(val){
            window.open('https://play.google.com/store/apps/details?id=com.rummy.friends', '_system'); 
          }          
          requestUpdate();
        })
      }

      if(d.indexOf(AppConfig.current_version) !== -1)
        requestUpdate();
    }            
  });

  firebase.getValue("info_update_version",function(d:string){ 
    if(d && parseInt(d) > AppConfig.current_version){
      console.log("info_update_versions",d);      
      
      const requestUpdate = () => {
        gameMessage("A new update is available, would you like to update your game for better experience?",{button_text:"Update"})
        .then(function(button){
          if(button)
            window.open('https://play.google.com/store/apps/details?id=com.rummy.friends', '_system');           
        })
      }
      requestUpdate();
    }           
  });

  firebase.getValue("start_message",function(d: string){ 
    if(d && d.length){
      gameMessage(d);
    }            
  });

  firebase.getValue("support_number",function(d: number){ 
    if(d){
      AppConfig.support_number = d;
    }            
  });

  firebase.getValue("api_url",function(d: string){ 
    if(d){
      AppConfig.api_url = d;
    }            
  });

  if(AppConfig.debug){
    // @ts-ignore
    window.AppConfig = AppConfig;
  }
}