import Firebase from "./Firebase";
import ReactGA from 'react-ga';

export interface ProductDetails{
  billingPeriodUnit?: string;
  id: string;
  price: string;
  currency: string;// INR
  owned: boolean;
  state: string;
  priceMicros: number; // 30000000 for 30 rs
  loaded: boolean;
  description: string;
  title: string;
  type: string; // non consumable
  canPurchase: boolean;
  verify: () => void;
  finish: () => void;
}

interface StoreProduct{
  no_ads:ProductDetails|undefined, 
  unlock_premium:ProductDetails|undefined, 
  no_ads_only:ProductDetails|undefined, 
  super_premium:ProductDetails|undefined, 
  no_ads_only_sub:ProductDetails|undefined, 
}

export enum Products{
  no_ads = "no_ads",
  no_ads_only = "no_ads_only",
  unlock_premium = "unlock_premium",
  super_premium = "super_premium",
  no_ads_only_sub = "no_ads_only_sub"

}

const ProductType = {
  no_ads: "non_consumable",
  no_ads_only: "non_consumable",
  unlock_premium: "non_consumable",
  super_premium: "non_consumable",
  subscription: "paid_subscription"
}

export default class Store{  

  static _product : StoreProduct = {
    "no_ads": undefined,
    "unlock_premium": undefined,
    "no_ads_only": undefined,
    "super_premium": undefined,
    "no_ads_only_sub": undefined
  }



  static onChange: ((product: ProductDetails) => void)|undefined
  static onOwned: ((product: ProductDetails) => void)|undefined

  static init(){

    // @ts-ignore
    const store = window.store;
    // @ts-ignore
    const cordova = window.cordova;

    if (store && cordova.platformId === "android") {

      for(var item in Products){

        // unlock premium & no ads
        store.register({
            id:item,
            // @ts-ignore
            type:ProductType[item] === "non_consumable"? store.NON_CONSUMABLE : store.PAID_SUBSCRIPTION
        });

        store.error(function(error:any) {
            console.log('ERROR ' + error.code + ': ' + error.message);
        });

        store.when(item).updated((product:ProductDetails) => {
          console.log("no_ads product",product);
          // @ts-ignore
          Store._product[product.id] = product;
          this.onChange && this.onChange(product);
        });        

        store.when(item).approved(function(p:ProductDetails) {
          // console.log("no_ads purchase approved")
          p.verify();
        });

        store.when(item).verified(function(p:ProductDetails){
          // console.log("no_ads purchase verified")

          p.finish();
        });

        store.when(item).owned((p:ProductDetails) => {
          // @ts-ignore
          Store.onOwned && Store._product[p.id] !== undefined && Store.onOwned(Store._product[p.id]);
        })
      }

      store.refresh();
    }
  }

  static isOwned(item: Products){
    // if(item === "no_ads")
    //   return false;
      
    return Store._product[item]?.owned;
  }

  static getInfo(item: Products){
    if(Store._product[item]){
      
      return Store._product[item];
    }
    return null;
  }

  static manageSubscriptions(){
    // @ts-ignore
    const store = window.store;
    if(store){
      store.manageSubscriptions();
    }
  }

  
  static orderItem(item: Products){
    
    // @ts-ignore
    const store = window.store;
    // @ts-ignore
    const cordova = window.cordova;

    if (store && cordova.platformId === "android") {

      store.order(item);
      Firebase.logEvent("seek_purchase",{item:item});
      ReactGA.event({
        category: 'SeekPurchase',
        action: item
      });

    }
  }

  static setChangeTrigger(onChange: ((p:ProductDetails) => void)|undefined){

    this.onChange = onChange;
    for(var item in Products){
      // @ts-ignore
      if(Store._product[item]){
      // @ts-ignore
        this.onChange && this.onChange(Store._product[item]);
      }
    }
   
  }

  static setOwnedTrigger(onOwned: ((p:ProductDetails) => void)|undefined){

    this.onOwned = onOwned;
    for(var item in Products){
      // @ts-ignore
      if(Store._product[item] && Store._product[item].owned){
      // @ts-ignore
        this.onOwned && this.onOwned(Store._product[item]);
      }
    }    
  }

}