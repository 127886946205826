import React, { useState, useEffect, useLayoutEffect, useCallback } from "react";
import { TheCard, CardType } from "../Helpers/Models";
import { ReactSortable } from "react-sortablejs";
import { cloneObject } from "../Helpers/Helper";
import { Card } from "./Card";
import CardModel from "../Helpers/CardModel";
import { gameMessage } from "../Helpers/Popups";
import { useTranslation } from "react-i18next";

interface TheProps{
    cards: TheCard[];
    onSelect?: (card: TheCard | undefined) => void;
    onSort?: (cards: TheCard[]) => void;
    selectedId?: string;
    onChange?: (hand: TheCard[][]) => void;
    deckJoker: TheCard|undefined;
}
let oldval = "";

function useManageCardUpdates(
    cards: TheCard[], 
    handCards: TheCard[][],
    removeEmptyGroup : (cards: TheCard[][]) => void,
    setHandCards :React.Dispatch<React.SetStateAction<TheCard[][]>>,
    setGroupCards :React.Dispatch<React.SetStateAction<TheCard[]>>,
    setHighlightCard  :React.Dispatch<React.SetStateAction<TheCard | undefined>>, 
){
    // check for changes in new props and update them, keeping the grouping
    useEffect(() => {
            
        if(oldval === JSON.stringify(cards)){
            return;
        }

        oldval = JSON.stringify(cards);

        const flatCards = handCards.flat();

        const newCards:TheCard[] = [];
        cards.forEach(c => {
            const i = flatCards.findIndex(a => a.id === c.id)
            if(i === -1){
                // new card
                newCards.push(c);
            }
        })    

        const remCards:TheCard[] = [];
        if(flatCards.length !== cards.length + newCards.length){
            flatCards.forEach((c) => {
                const i = cards.findIndex(a => a.id === c.id)
                if(i === -1){
                    // remove card
                    remCards.push(c);
                }
            }) 
        }

        
        const clone = cloneObject(handCards);
        remCards.reverse().forEach(a => {
            clone.findIndex(b => {
                const found = b.findIndex(c => {
                    return c.id === a.id;
                })

                if(found > -1){
                    b.splice(found,1)
                    return true;
                }
                return false;
            })
        })
        
        if(clone.length > 0)
            newCards.forEach(a => {
                clone[clone.length-1].push(a)
            })
        else
            clone.push(newCards)
        
        if(newCards.length === 1){
            // drawn card
            setHighlightCard(newCards[0]);
        }
        else if(remCards.length > 0){
            setHighlightCard(undefined);
        }

        removeEmptyGroup(clone);
        setHandCards(clone);
        setGroupCards([]);

    },[cards,handCards,removeEmptyGroup,setHandCards,setGroupCards,setHighlightCard]);

}

function useWindowSize() {
    const [size, setSize] = useState({width:0,height:0});
    useLayoutEffect(() => {
        function updateSize() {
            setSize({width:window.innerWidth, height:window.innerHeight});
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

export function PlayerHand(props : TheProps){
    const { onChange, cards } = props;
    const minLandscapeSize = 550;

    const [handCards,setHandCards] = useState<TheCard[][]>([]);
    const [handCardsUG,setHandCardsUG] = useState<TheCard[]>([]);
    const [groupCards,setGroupCards] = useState<TheCard[]>([]);
    const [highlightCard,setHighlightCard] = useState<TheCard>();
    const [score,setScore] = useState<number>();
    const windowSize = useWindowSize();
    const { onSelect } = props;
    const { t } = useTranslation();

    const removeEmptyGroup = (cards: TheCard[][]) => {
        const removeItems:number[] = [];
        cards.forEach((a,i) => {
            if(a.length === 0)
                removeItems.push(i);
        })

        removeItems.reverse().forEach(a => {
            cards.splice(a,1);
        });        
    }

    useManageCardUpdates(cards,handCards,removeEmptyGroup,setHandCards,setGroupCards,setHighlightCard);
    
    const onGroupingClick = () => {
        groupItems();       
    }

    useEffect(() => {
        if(groupCards.length === 1){
            onSelect && onSelect(groupCards[0]);
        }
        else{
            onSelect && onSelect(undefined);
        }    

    },[groupCards,onSelect])

    const onGroupSelect = useCallback((theCard:TheCard) => {        
        if(theCard.type === CardType.closed)
            return;

        const i = groupCards.findIndex(a => a.id === theCard.id);
        const clone : TheCard[] = [...groupCards];
        if(i > -1){
            clone.splice(i,1);
        }
        else{
            clone.push(theCard);
        }
        
        if(JSON.stringify(clone) !==  JSON.stringify(groupCards))
            setGroupCards(clone);


    },[groupCards])

    const groupItems = useCallback(() => {
        if(groupCards.length === 0)
            return;
        if(handCards.length >= 6){
            gameMessage(t("You cannot create more than {{theCount}} groups.",{theCount:t("6")}))
            return;
        }
            
        const clone : TheCard[][] = JSON.parse(JSON.stringify(handCards))
        groupCards.forEach(a => {
            clone.find(b => {
                const i = b.findIndex(c => c.id === a.id);
                if(i > -1){
                    b.splice(i,1);
                    return true;
                }
                return false;
            })
        })

        clone.splice(0,0,groupCards);
        removeEmptyGroup(clone);

        if(JSON.stringify(clone) !== JSON.stringify(handCards))
            setHandCards(clone);

        if(groupCards.length !== 0)
            setGroupCards([]);
    },[groupCards, handCards, t])


    const setCardsList = useCallback((newlist: TheCard[],index:number) => {
        const clone = [...handCards];
        clone[index] = newlist;

        if(JSON.stringify(clone) !== JSON.stringify(handCards))
            setHandCards(clone);

    },[handCards])

    const onSortClick = useCallback(() => {
        const clone = cloneObject(handCards);
        const clubs:TheCard[] = [];
        const spades:TheCard[] = [];
        const hearts:TheCard[] = [];
        const diamonds:TheCard[] = [];
        const others:TheCard[] = [];

        const flat = clone.flat();

        flat.forEach(a => {
            switch(a.type){
                case CardType.clubs:
                    clubs.push(a);
                    break;
                case CardType.hearts:
                    hearts.push(a);
                    break;
                case CardType.spades:
                    spades.push(a);
                    break;
                case CardType.dimonds:
                    diamonds.push(a);
                    break;
                default:
                    others.push(a);
            }
        });

        const newCards : TheCard[][] = [];
        [clubs,hearts,spades,diamonds,others].forEach(a => {
            if(!a.length)
                return;

            a.sort((a,b) => { if(a.number && b.number) return a.number - b.number; return 0;});
            newCards.push(a)
        })
   
        if(JSON.stringify(newCards) !== JSON.stringify(handCards))
            setHandCards(newCards);
    },[handCards])

    // when actualCards update, update hand cards
    useEffect(() => {
        const newList : TheCard[] = [];
        handCards.forEach(a => {
            a.forEach(b => {
                newList.push(b);
            });
            newList.push(new TheCard(CardType.closed));
        })

        newList.pop();

        setHandCardsUG(newList);
        onChange && onChange(handCards);

    },[handCards, onChange])

    const updateHand = useCallback((newList: TheCard[]) => {
        const newCards : TheCard[][] = [];

        let group: TheCard[] = [];
        newList.forEach(a => {
            if(a.type !== CardType.closed){
                group.push(a);
            }
            else{
                newCards.push(group);
                group = [];
            }
        })

        if(group.length)
            newCards.push(group);

        removeEmptyGroup(newCards);

        if(JSON.stringify(handCards) !== JSON.stringify(newCards))
            setHandCards(newCards);

    },[handCards])

    const filterGroupCards = useCallback((e: any,h: Element,s: any) => {
        if(h.querySelector(".closed")){
            return true;
        }
        return false;
    },[])

    const getCardHolderClass = useCallback((card: TheCard) => {
        return "holder clickable half" + 
            (groupCards.findIndex(z => z.id === card.id) > -1 ? " selected":"") +
            (highlightCard && highlightCard.id === card.id ? " highlight":"")
    },[groupCards, highlightCard])

    useEffect(() => {
        
        const cardModel  = new CardModel(props.deckJoker);        
        const cardsClone = handCards.map(a => {            
            return a.filter(c => {
                return !!!groupCards.find(g => g.id === c.id);
            });
        });
        const score = cardModel.getCount(cardsClone);
        setScore(score);
        
    },[handCards,props.deckJoker,groupCards]);

    const isJoker = useCallback((card: TheCard) => {        
        if(props.deckJoker){
            const cardModel = new CardModel(props.deckJoker);
            return cardModel.isJokerCard(card) && card.type !== CardType.joker;
        }

        return false;
    },[props.deckJoker])

    return (
        <div className='player-hand ' >
            {props.cards.length > 0 && <div className='score' >{score}</div>}
            {windowSize.width > minLandscapeSize?
                (
                    <div className='list landscape'>
                        <ReactSortable group="shared"  list={handCardsUG} fallbackTolerance={6} filter={(a,b,c) => filterGroupCards(a,b,c)} className='card-list' setList={updateHand}  >
                        {handCardsUG.map((a,i) => {
                            return (
                                <div key={a.id} className={getCardHolderClass(a)} onClick={(e) => onGroupSelect(a)} >
                                    <Card type={a.type} number={a.number} half={true} cutJoker={isJoker(a)} />
                                </div>
                            )
                        })}
                        </ReactSortable>
                    </div>
                ):
                (
                <div className='list portrait'>
                    {handCards.map((c,j) => {
                        return (
                            <ReactSortable group="shared-within" key={j} fallbackTolerance={6} list={c} className='card-list' setList={(l) => setCardsList(l,j)}  >
                            {c.map((a,i) => {
                                return (
                                    <div key={a.id} className={getCardHolderClass(a)} onClick={(e) => onGroupSelect(a)} >
                                        <Card type={a.type} number={a.number} half={true} cutJoker={isJoker(a)} />
                                    </div>
                                )
                            })}
                            </ReactSortable>
                        )
                    })}  
                </div>
                )}

            
            
            {props.cards.length > 0 && 
                (
                    <div className='hand-buttons' >
                        {handCards.length <= 1 &&
                            <button type='button' style={{marginRight:"5px"}} onClick={onSortClick} className={'btn btn-inair'} >{t("Sort")}</button>}
                        {groupCards.length >= 1 &&
                            <button type='button' style={{marginRight:"5px"}} onClick={onGroupingClick} className={'btn btn-inair '} >{t("Group")}</button>}
                    </div>
                )}
        </div>
    )
}