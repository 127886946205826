import SoundFx from "../../Helpers/SoundFx";
import { setLoader } from "../../Helpers/Helper";
import { httpLoad } from "../../Helpers/Http";
import { setData, getData } from "../../Helpers/Storage";
import { gameMessage, gameInput } from "../../Helpers/Popups";
import ReactGA from 'react-ga';
import Firebase from "../../Phonegap/Firebase";
import { useCallback } from "react";
import { TFunction } from "i18next";
import { isSuperUser } from "../../Helpers/Profits";
import { useHistory } from "react-router-dom";
import AppConfig from "../../Config";

export default function useCreateRoomHelper(
  t: TFunction,
  playerName : string,
  collectName : () => Promise<string | null>,
  setShowNewRoomOptions: React.Dispatch<React.SetStateAction<boolean>>,
){
  
  const history = useHistory();

  const createRoom = async () => {
    SoundFx.play("click");


    ReactGA.event({
        category: 'Main',
        action: 'New Room'
      });


    let tempName = null;
    if(!playerName){
      tempName = await collectName();
      if(!tempName)
            return;
    }
    
    setShowNewRoomOptions(true);
  }

  
  
  const joinRoom = useCallback(async (room?:string) => {
    SoundFx.play("click");
    ReactGA.event({
        category: 'Main',
        action: 'Join Room'
      });

    let tempName = null;
    if(!playerName){
        tempName = await collectName();
        if(!tempName)
            return;
    }        

    let roomId : string = "";
    if(!room){
        let cancel;
        [roomId,cancel] = await gameInput(t("Enter Room Id")+":","number");

        if(cancel)
            return;            
    }
    else{
        roomId = room;
    }

    
    if(roomId.length < 3){
        await gameMessage(t("Invalid Room Id"));
        joinRoom();
        return;
    }        

    const lastPid = getData("last-pid");        

    Firebase.logEvent("enter_room",{method: room?"rejoin":"join"});

    setLoader(true);
    const options = {
      roomId: roomId,
      playerName: (playerName?playerName:tempName),
      oldPid: lastPid,
      gameOn: isSuperUser() ? true : undefined,
      version: AppConfig.current_version
    };
    const res = await httpLoad("/joinroom",options);
    
    setLoader(false);
    if(res.ok){
        const pid = res.data;
        setData("last-pid",pid);    
        setData("last-roomid",roomId);    
        // window.location.href = "/room/"+roomId+"/"+pid+'?u='+uuid();
        history.push("/room/"+roomId+"/"+pid)
    }
    else{
        gameMessage(res.data)           
    }
  },[collectName,playerName,history,t]);

  return {
    createRoom: createRoom, 
    joinRoom: joinRoom
  }
}