import FacebookAds from "./Facebook";
import Admob from "./Admob";
import Firebase from "../Firebase";
import ReactGA from 'react-ga';
import AppConfig from "../../Config";


export interface AdsOptions{
  debug: boolean;
  onInterstitialLoad? :(() => void);
  onVideoLoad? :(() => void);
  onVideoReward? :(() => void);
  onVideoClose? :(() => void);
  onInterstitialClose?: (() => void)
}

export interface AdsLibrary{  
  name: string;
  loadInterstitial:(() => void);
  loadVideo:(() => void);
  showInterstital:(() => void);
  showVideo:(() => void);
  init:(() => void);
  isInterstialAvailable:(() => boolean);

}


export default class Ads{ 
  static _debug = false;
  static _fbAds:FacebookAds|undefined = undefined;
  static _admob:Admob|undefined = undefined;
  static _onRewardVideoComplete?: () => void;

  static isRewardClosed = -1;
  static isRewardSuccess = 0;
  static isInterstialClosed = -1;

  static init(debug: boolean, onRewardVideoComplete?: () => void){
    Ads._debug = debug;
    Ads._onRewardVideoComplete = onRewardVideoComplete;

    // @ts-ignore
    if(window.cordova.platformId === "android"){

    // Facebook Ads
      if(AppConfig.load_fb_ads)
      Ads._fbAds = new FacebookAds({
        debug:Ads._debug, 
        onVideoReward: () => Ads._onVideoReward(true),
        onVideoClose: () => Ads._onVideoClose(),
        onInterstitialClose: () => Ads._onInterstialClose()
      });

      if(AppConfig.load_admob_ads)
      Ads._admob = new Admob(
        {
          debug:Ads._debug, 
          onVideoReward: () => Ads._onVideoReward(false),
          onVideoClose: () => Ads._onVideoClose(),
          onInterstitialClose: () => Ads._onInterstialClose()
        });
    }

  }

  static showInterstitial(){
    console.log("show interstitial");

    const available = [];
    if(this._fbAds?.isInterstialAvailable())
      available.push(this._fbAds);
      
    if(this._admob?.isInterstialAvailable())
      available.push(this._admob);

    if(available.length){
      const lib = available[Math.floor(Math.random() * available.length)];

      lib.showInterstital();
      Firebase.logEvent("show_ad",{type: "interstitial",provider:lib.name});
      ReactGA.event({
        category: 'ShowAd',
        action: 'Reward',
        label: lib.name
      });      
    }
    else{
      return;
    }

    return new Promise((res) => {
      Ads.isInterstialClosed = -1;
      let t: NodeJS.Timeout;
      let max_tries = 100;

      const checkFinish = () => {

          clearTimeout(t);
          t =  setTimeout(() => {
          if(Ads.isInterstialClosed !== -1){            
              res()
          }
          else{
            max_tries--;
            if(max_tries > 0)
              checkFinish(); 
            else  
              res();
          }
        },500)
      }

      checkFinish();

    })
    
  }


  static showRewardVideo(){
    const available = [];
    if(this._fbAds?.isVideoAvailable())
      available.push(this._fbAds);
      
    if(this._admob?.isVideoAvailable())
      available.push(this._admob);

    if(available.length){
      const lib = available[Math.floor(Math.random() * available.length)];

      lib.showVideo();
      Firebase.logEvent("show_ad",{type: "reward",provider:lib.name});
      ReactGA.event({
        category: 'ShowAd',
        action: 'Reward',
        label: lib.name
      });
    }
    else{
      return;
    }
    
    return new Promise((res) => {
      Ads.isRewardClosed = -1;
      Ads.isRewardSuccess = 0;
      let t: NodeJS.Timeout;
      let max_tries = 100;

      const checkFinish = () => {

          clearTimeout(t);
          t =  setTimeout(() => {
          if(Ads.isRewardClosed !== -1){
            if(Ads.isRewardSuccess === 1)
              res(true)
            else
              res(false)
          }
          else{
            max_tries--;
            if(max_tries > 0)
              checkFinish(); 
            else  
              res(false);
          }
        },500)
      }

      checkFinish();

    })
  }

  static isRewardVideoAvailable(){
    return this._admob?.isVideoAvailable();
  }

  static _onVideoReward(fb:boolean){
    this._onRewardVideoComplete && this._onRewardVideoComplete();
    Ads.isRewardSuccess = 1;
    Firebase.logEvent("complete_ad",{type: "reward",provider: fb?"facebook":"admob"});
    ReactGA.event({
      category: 'CompleteAd',
      action: 'Reward',
      label: fb?"facebook":"admob"
    });
  }

  static _onVideoClose(){
      Ads.isRewardClosed = 1;
  }

  static _onInterstialClose(){
    Ads.isInterstialClosed = 1;
  }


}