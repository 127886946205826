import { CardType } from "../Helpers/Models";
import React from "react";

interface CardProps{
    type: CardType;
    number?: number;
    half? : boolean;
    className?: string;
    cutJoker?: boolean;
}

export function Card(props :CardProps){

    let str = undefined;
    if(props.number){
        switch(props.number){
            case 1:
                str = 'A';
                break;
            case 11:
                str = 'J';
                break;
            case 12:
                str = 'Q';
                break;
            case 13:
                str='K';
                break;
            default:
                str = props.number.toString();
        }
    }
    
    return (        
        <div className={"card " + (props.className ? props.className+" " : "") + props.type  + (props.half?" half":"")+ (props.cutJoker?" cut-joker":"")} >
            <div className='display top' >{str}</div>
            <div className='display bottom' >{str}</div>
        </div>
    )
}