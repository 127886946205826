import React, { useState, useEffect, useCallback } from 'react';

import { GameController } from './GameController';
import { Game} from '../Helpers/Models';
//import { currentPlayerId, currentRoomId } from './Helpers/Player';
import { useParams } from 'react-router-dom';
import { httpLoadX } from '../Helpers/Http';
import { gameMessage } from '../Helpers/Popups';
import { getData } from '../Helpers/Storage';
import { unlockOrientation, lockOrientation } from '../Helpers/Device';
import CompComm from '../Helpers/CompComm';
import AppConfig from '../Config';

export default function TheGame() {
  const [game,setGame] = useState<Game>();
  const [lastUpdate,setLastUpdate] = useState<number>();
  const { roomid,playerid } = useParams<{roomid:string,playerid:string}>();

  const onGameChange = useCallback((newgame: Game) => {
    console.log("change")
    setGame(newgame);
    setLastUpdate(Date.now());
  },[])

  useEffect(() => {

    // @ts-ignore    
    const orientationSaved = getData("orientation");
    if(orientationSaved && orientationSaved === "free"){      
      unlockOrientation()
    }
    else
      lockOrientation("landscape")
      

  },[]);

  useEffect(function() {
    let timer:NodeJS.Timeout;
    let abortHttp:() => void;
    let isPageValid = true;

    const checkGame = () => {
      
      const [abort,p] = httpLoadX("/game/"+roomid+"/"+playerid);

      abortHttp = abort;

      p.then(async res => {
        setLastUpdate(Date.now());
        if(res.ok){
          setGame(res.data);
        }
        else{
          await gameMessage(res.data);
          if(!isPageValid){
            return;
          }
            
        }
  
        timer = setTimeout(function() {
         checkGame();
        },AppConfig.gameRefreshSpeed)  
      })
     
    };

    checkGame();

    const abortGameListner = CompComm.listen("AbortGameRequests",() => {
      abortHttp();
      clearTimeout(timer);
    })

    const continueGameRequests = CompComm.listen("ContinueGameRequests",() => {
      checkGame();
    })
    
    return () => {
      clearTimeout(timer);
      abortHttp && abortHttp();
      console.log("game exit call");
      isPageValid = false;
      CompComm.destroy(abortGameListner);
      CompComm.destroy(continueGameRequests);
    }
  
  },[playerid,roomid]);

  return (
    <>      
        {playerid && roomid &&
          <GameController lastUpdate={lastUpdate} game={game} onGameChange={onGameChange} playerId={playerid} roomId={roomid} />
        }
        
    </>
  );
}


