import { useCallback } from "react";
import { gameInput, gameMessage } from "../../Helpers/Popups";
import { setData } from "../../Helpers/Storage";
import ReactGA from 'react-ga';
import  Firebase from "../../Phonegap/Firebase";
import { TFunction } from "i18next";

export default function useCollectName(
  t: TFunction,
  setPlayerName: React.Dispatch<React.SetStateAction<string>>,
) {

  const collectName = useCallback(async () : Promise<string|null> => {
      console.log("collect anme");
      
      const [name,cancel] = await gameInput(t("Enter your Name")+":");
      
      if(cancel){
          return null;
      }

      if(name === null || name === undefined || name === "null" || !name){
          await gameMessage(t("Enter a valid name"));            

          return collectName();
      }
  
      if(name.length < 3){
          await gameMessage(t("Name should be more than {{theCount}} characters",{theCount: t("2")}));            
          return collectName();
      }

      if(name.length > 8){
          await gameMessage(t("Name should not be more than {{theCount}} characters",{theCount: t("8")}));            
          return collectName();
      }

      setData("name",name);
      setPlayerName(name);


      ReactGA.set({"dimension1": name});
      Firebase.setUserProperty("Name",name);
      
      return name;
  },[t,setPlayerName]);

  return collectName;

}