import s1F602 from "./res/emojis/1F602.svg";
import s1F603 from "./res/emojis/1F603.svg";
import s1F604 from "./res/emojis/1F604.svg";
import s1F605 from "./res/emojis/1F605.svg";
import s1F606 from "./res/emojis/1F606.svg";
import s1F607 from "./res/emojis/1F607.svg";
import s1F609 from "./res/emojis/1F609.svg";
import s1F60B from "./res/emojis/1F60B.svg";
import s1F60D from "./res/emojis/1F60D.svg";
import s1F60E from "./res/emojis/1F60E.svg";
import s1F610 from "./res/emojis/1F610.svg";
import s1F611 from "./res/emojis/1F611.svg";
import s1F612 from "./res/emojis/1F612.svg";
import s1F61B from "./res/emojis/1F61B.svg";
import s1F61C from "./res/emojis/1F61C.svg";
import s1F61D from "./res/emojis/1F61D.svg";
import s1F61E from "./res/emojis/1F61E.svg";
import s1F622 from "./res/emojis/1F622.svg";
import s1F624 from "./res/emojis/1F624.svg";
import s1F627 from "./res/emojis/1F627.svg";
import s1F62C from "./res/emojis/1F62C.svg";
import s1F62D from "./res/emojis/1F62D.svg";
import s1F62F from "./res/emojis/1F62F.svg";
import s1F631 from "./res/emojis/1F631.svg";
import s1F634 from "./res/emojis/1F634.svg";
import s1F635 from "./res/emojis/1F635.svg";
import s1F636 from "./res/emojis/1F636.svg";
import s1F642 from "./res/emojis/1F642.svg";
import s1F643 from "./res/emojis/1F643.svg";
import s1F644 from "./res/emojis/1F644.svg";
import s1F910 from "./res/emojis/1F910.svg";
import s1F911 from "./res/emojis/1F911.svg";
import s1F913 from "./res/emojis/1F913.svg";
import s1F914 from "./res/emojis/1F914.svg";
import s1F915 from "./res/emojis/1F915.svg";
import s1F917 from "./res/emojis/1F917.svg";
import s1F923 from "./res/emojis/1F923.svg";
import s1F924 from "./res/emojis/1F924.svg";
import s1F925 from "./res/emojis/1F925.svg";
import s1F928 from "./res/emojis/1F928.svg";
import s1F929 from "./res/emojis/1F929.svg";
import s1F92A from "./res/emojis/1F92A.svg";
import s1F92B from "./res/emojis/1F92B.svg";
import s1F92C from "./res/emojis/1F92C.svg";
import s1F92D from "./res/emojis/1F92D.svg";
import s1F92F from "./res/emojis/1F92F.svg";
import s1F973 from "./res/emojis/1F973.svg";
import s1F974 from "./res/emojis/1F974.svg";
import s1F975 from "./res/emojis/1F975.svg";
import s1F976 from "./res/emojis/1F976.svg";
import s1F97A from "./res/emojis/1F97A.svg";
import s1F9D0 from "./res/emojis/1F9D0.svg";
import s1F440 from "./res/emojis/1F440.svg";
import s1F444 from "./res/emojis/1F444.svg";
import s1F445 from "./res/emojis/1F445.svg";
import s1F447 from "./res/emojis/1F447.svg";
import s1F448 from "./res/emojis/1F448.svg";
import s1F449 from "./res/emojis/1F449.svg";
import s1F44A from "./res/emojis/1F44A.svg";
import s1F44B from "./res/emojis/1F44B.svg";
import s1F44C from "./res/emojis/1F44C.svg";
import s1F44D from "./res/emojis/1F44D.svg";
import s1F44E from "./res/emojis/1F44E.svg";
import s1F44F from "./res/emojis/1F44F.svg";
import s1F476 from "./res/emojis/1F476.svg";
import s1F4AA from "./res/emojis/1F4AA.svg";
import s1F590 from "./res/emojis/1F590.svg";
import s1F647 from "./res/emojis/1F647.svg";
import s1F64B from "./res/emojis/1F64B.svg";
import s1F64C from "./res/emojis/1F64C.svg";
import s1F918 from "./res/emojis/1F918.svg";
import s1F919 from "./res/emojis/1F919.svg";
import s1F91D from "./res/emojis/1F91D.svg";
import s1F91E from "./res/emojis/1F91E.svg";
import s1F91F from "./res/emojis/1F91F.svg";
import s1F926 from "./res/emojis/1F926.svg";
import s1F937 from "./res/emojis/1F937.svg";
import s1F9B4 from "./res/emojis/1F9B4.svg";
import s1F9B7 from "./res/emojis/1F9B7.svg";
import s1F9BB from "./res/emojis/1F9BB.svg";
import s1F9E0 from "./res/emojis/1F9E0.svg";
import s1FAC0 from "./res/emojis/1FAC0.svg";
import s270B from "./res/emojis/270B.svg";
import s270C from "./res/emojis/270C.svg";

const EmojiData = [

  { name: "s1F44B", path: s1F44B, group: "hand" },
  { name: "s1F44C", path: s1F44C, group: "hand" },
  { name: "s1F44D", path: s1F44D, group: "hand" },
  { name: "s1F44E", path: s1F44E, group: "hand" },
  { name: "s1F44F", path: s1F44F, group: "hand" },
  { name: "s1F4AA", path: s1F4AA, group: "hand" },
  { name: "s1F590", path: s1F590, group: "hand" },

  { name: "s1F64C", path: s1F64C, group: "hand" },
  { name: "s1F918", path: s1F918, group: "hand" },
  { name: "s1F919", path: s1F919, group: "hand" },
  { name: "s1F91D", path: s1F91D, group: "hand" },
  { name: "s1F91E", path: s1F91E, group: "hand" },
  { name: "s1F91F", path: s1F91F, group: "hand" },
  { name: "s270B", path: s270B, group: "hand" },
  { name: "s270C", path: s270C, group: "hand" },
  { name: "s1F447", path: s1F447, group: "hand" },
  { name: "s1F448", path: s1F448, group: "hand" },
  { name: "s1F449", path: s1F449, group: "hand" },
  { name: "s1F44A", path: s1F44A, group: "hand" },
  
  { name: "s1F602", path: s1F602, group: "laugh" },
  { name: "s1F603", path: s1F603, group: "laugh" },
  { name: "s1F604", path: s1F604, group: "laugh" },
  { name: "s1F605", path: s1F605, group: "laugh" },
  { name: "s1F606", path: s1F606, group: "laugh" },
  { name: "s1F642", path: s1F642, group: "laugh" },
  { name: "s1F911", path: s1F911, group: "laugh" },
  { name: "s1F913", path: s1F913, group: "laugh" },
  { name: "s1F923", path: s1F923, group: "laugh" },

  { name: "s1F607", path: s1F607, group: "notty" },
  { name: "s1F609", path: s1F609, group: "notty" },
  { name: "s1F60B", path: s1F60B, group: "notty" },
  { name: "s1F60D", path: s1F60D, group: "notty" },
  { name: "s1F60E", path: s1F60E, group: "notty" },
  { name: "s1F61B", path: s1F61B, group: "notty" },
  { name: "s1F61C", path: s1F61C, group: "notty" },
  { name: "s1F61D", path: s1F61D, group: "notty" },
  { name: "s1F643", path: s1F643, group: "notty" },

  { name: "s1F914", path: s1F914, group: "notty" },
  { name: "s1F917", path: s1F917, group: "notty" },
  { name: "s1F924", path: s1F924, group: "notty" },
  { name: "s1F925", path: s1F925, group: "notty" },

  { name: "s1F929", path: s1F929, group: "notty" },
  { name: "s1F92A", path: s1F92A, group: "notty" },
  { name: "s1F92B", path: s1F92B, group: "notty" },
  { name: "s1F973", path: s1F973, group: "notty" },
  { name: "s1F974", path: s1F974, group: "notty" },
  { name: "s1F975", path: s1F975, group: "notty" },
  { name: "s1F976", path: s1F976, group: "notty" },
  { name: "s1F9D0", path: s1F9D0, group: "notty" },

  

  { name: "s1F610", path: s1F610, group: "unhappy" },
  { name: "s1F611", path: s1F611, group: "unhappy" },
  { name: "s1F612", path: s1F612, group: "unhappy" },

  { name: "s1F61E", path: s1F61E, group: "unhappy" },
  { name: "s1F622", path: s1F622, group: "unhappy" },
  { name: "s1F624", path: s1F624, group: "unhappy" },
  { name: "s1F627", path: s1F627, group: "unhappy" },
  { name: "s1F62C", path: s1F62C, group: "unhappy" },
  { name: "s1F62D", path: s1F62D, group: "unhappy" },

  { name: "s1F634", path: s1F634, group: "unhappy" },
  { name: "s1F635", path: s1F635, group: "unhappy" },
  { name: "s1F636", path: s1F636, group: "unhappy" },
  { name: "s1F915", path: s1F915, group: "unhappy" },
  { name: "s1F928", path: s1F928, group: "unhappy" },
  { name: "s1F92C", path: s1F92C, group: "unhappy" },
  { name: "s1F97A", path: s1F97A, group: "unhappy" },
  { name: "s1F644", path: s1F644, group: "unhappy" },
  { name: "s1F910", path: s1F910, group: "unhappy" },

  { name: "s1F92D", path: s1F92D, group: "shock" },
  { name: "s1F92F", path: s1F92F, group: "shock" },
  { name: "s1F62F", path: s1F62F, group: "shock" },
  { name: "s1F631", path: s1F631, group: "shock" },


  // person expressing
  { name: "s1F476", path: s1F476, group: "person" },
  { name: "s1F647", path: s1F647, group: "person" },
  { name: "s1F64B", path: s1F64B, group: "person" },
  { name: "s1F926", path: s1F926, group: "person" },
  { name: "s1F937", path: s1F937, group: "person" },
  // organs
  { name: "s1F9B4", path: s1F9B4, group: "organs" },
  { name: "s1F9B7", path: s1F9B7, group: "organs" },
  { name: "s1F9BB", path: s1F9BB, group: "organs" },
  { name: "s1F9E0", path: s1F9E0, group: "organs" },
  { name: "s1FAC0", path: s1FAC0, group: "organs" },
  { name: "s1F440", path: s1F440, group: "organs" },
  { name: "s1F444", path: s1F444, group: "organs" },
  { name: "s1F445", path: s1F445, group: "organs" },  
]

export default EmojiData;