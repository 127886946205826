import React, { useState, useEffect, useCallback } from "react";
import { Modal, Tab, Tabs } from "react-bootstrap";
import SoundFx from "../Helpers/SoundFx";
import { httpLoad } from "../Helpers/Http";

import { Chat, ChatType, Player } from "../Helpers/Models";
import { useInputFocusFix } from "../Helpers/Helper";
import EmojiData from "../Emojis";
import { ChatText } from "./ChatMessage";

const textTemplates = [
  "Hi","Bye", "Ok", "Yes", "Are you there?",
  "Turn on mic",
  "Last game", "Last round", 
  "Your turn", 
  "Press Ready",   
  "New game?", "Play again?",
  "2 mins", "5 mins"
];

interface TheProps{
  roomId: string;
  playerId: string;
  players: Player[];
  chatData: Chat[];
  onUserChat?: (chat: Chat) => void;
}

const getPlayerName = (id:string,players:Player[]) => {
  return players.find(a => a.id === id)?.name || "[Left Player]";
}

const getPlayerIndex = (id:string,players:Player[]) => {
  return players.findIndex(a => a.id === id);
};

let chatBox:HTMLDivElement|null = null;
let oldChatBoxString: string ="[]";
let usersMessages:Chat[] = [];

export default function ChatPopup({roomId, playerId, chatData,players,onUserChat}: TheProps){
  const [show,setShow] = useState(false);
  const [showNewMsgIcon,setShowNewMsgIcon] = useState(false);
  const [activeTab,setActiveTab] = useState("chat");

  const [inputMessage,setInputMessage] = useState("");
  const [chatDataManaged,setChatDataManaged] = useState<Chat[]>([]);

  useEffect(() => {


      return () => {        
        chatBox = null;
        oldChatBoxString = "[]";
        usersMessages = [];
      }
  },[])

  useEffect(() => {
    const messagesThatHaventLoaded = usersMessages.filter(a => chatData.findIndex(b => b.id === a.id) === -1);
    setChatDataManaged([...chatData,...messagesThatHaventLoaded]);    
    usersMessages = messagesThatHaventLoaded;

  },[chatData])

  const onInputChange = useCallback((e:React.ChangeEvent<HTMLInputElement>) => {
    setInputMessage(e.target.value);
  },[])

  const onShowClick = useCallback(() => {
    SoundFx.play("open");
    setShow(true);
    setShowNewMsgIcon(false);

  },[]);

  useEffect(() => {
    if(show === true){
      chatBox?.scrollTo(0,chatBox?.scrollHeight);

      // inputElement?.focus();
    }

  },[show,activeTab])

  useEffect(() => {
    const newChatBoxString = JSON.stringify(chatDataManaged.map(a => a.id));
    if(newChatBoxString !== oldChatBoxString){
      console.log("new chat",newChatBoxString);

      chatBox?.scrollTo(0,chatBox?.scrollHeight);
      oldChatBoxString = newChatBoxString;
      if(!show){
        setShowNewMsgIcon(true);
        SoundFx.play("pop");
      }
    }
  },[chatDataManaged,show])


  const onCloseClick = useCallback(() => {
    SoundFx.play("close");

    setShow(false)
  },[setShow])


  const sendMessage = useCallback((e:React.FormEvent|React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();  

    if(inputMessage === "")
      return;

    SoundFx.play("toggle");


    const chat = new Chat(playerId,(inputMessage));
    chat.type = ChatType.Message;
    onUserChat && onUserChat(chat);

    setChatDataManaged([...chatDataManaged,chat]);
    usersMessages.push(chat);

    setInputMessage("");

    httpLoad("/chat/"+roomId+"/"+playerId+"/"+chat.id,[chat.message]);

  },[ playerId,onUserChat, roomId,inputMessage,chatDataManaged]);


  const sendEmoji = useCallback((name: string) => {
    SoundFx.play("toggle");

    const chat = new Chat(playerId,name);
    onUserChat && onUserChat(chat);
    setChatDataManaged([...chatDataManaged,chat]);
    usersMessages.push(chat);

    httpLoad("/chat/"+roomId+"/"+playerId+"/"+name+"/"+chat.id);

    setTimeout(function() { setShow(false) }); // to avoid new chat notifcation temp fix

  },[onUserChat, playerId, roomId,chatDataManaged])

  useEffect(() => {
    const onBackButton = (e:Event) => {
        e.preventDefault();
        const gameModalAvailable = document.querySelector(".modal-dialog.game-modal");
        if(!gameModalAvailable?.classList.contains("chatpopup")){
            // ignore
        }       
        else if(show){
          onCloseClick();
        } 
    }

    document.addEventListener("backbutton",onBackButton)

    return () => {
        document.removeEventListener("backbutton",onBackButton);
    }

  },[show,onCloseClick])

  useInputFocusFix([show])

  return <>
    <div className='button clickable' onClick={onShowClick} >
        <i className={"far fa-comment-dots "+(showNewMsgIcon?"new-chat-noti":"")}></i>
    </div>  
    {show && <Modal show={show}  animation={false} size={"lg"}  onHide={onCloseClick}  dialogClassName='game-modal chatpopup' >
        <Modal.Header closeButton>Room Chat</Modal.Header>
        <Modal.Body>
          <>
          <Tabs activeKey={activeTab} onSelect={(k: any) => setActiveTab(k)} id="chat" >
           
            <Tab eventKey="chat" title="Chat" >
              <div className='chat-box' ref={ x => chatBox = x } >
                {chatDataManaged.map(msg => (
                  <div key={msg.id} >
                    <div className='chat-message' >
                      <span className={'player pos'+getPlayerIndex(msg.playerId,players)} >{getPlayerName(msg.playerId,players)}</span>
                      <span className='message' >
                        <ChatText chat={msg} />
                      </span>
                    </div>
                  </div>
                ))}
                {chatDataManaged.length === 0 && <div className='info-box' >
                    No Messages
                  </div>}
              </div>
            </Tab>

            <Tab eventKey="emoji" title="Emoji's" >
              <div className='emoji-list' >
                {textTemplates.map((a,i) => (
                  <div key={i} onClick={() => sendEmoji(a)} className={"emoji text-template"} >
                    {a}
                  </div>
                ))}
                {EmojiData.map((a,i) => (
                  <div key={i} onClick={() => sendEmoji(a.name)} className={"emoji "+a.group} >
                    <img src={a.path}  alt={"Smiley"+a.name} />
                  </div>
              ))}
              </div>
            </Tab>
          </Tabs>
          

           
            </>
        </Modal.Body>
        <Modal.Footer>
          <form onSubmit={sendMessage} className='chat-input '>
            <div className='input-group' >
              <input type='text' placeholder='Type your message...' className='form-control' value={inputMessage} onChange={onInputChange} maxLength={256} />
              <div className="input-group-append">
                <button type='submit' className='btn btn-secondary'  >Send</button>
              </div>
            </div>
          </form>
        </Modal.Footer>
    </Modal>}
  </>
}