/**
 * A helper that helps 2 or more components to talk with each other
 * Currently it uses event listeners in windows
 */
export default class CompComm {
  static listen = (
    eventName: string,
    callMethod: (e?:any) => void) : [ string, (e:any
      ) => void] => {
    const cb = (e : any) => {
      callMethod(e.detail);
    };

    window.addEventListener(eventName, cb);

    return [eventName, cb];
  }

  static destroy = (eventObj: [string, (e:any) => void]) => {
    const [e, c] = eventObj;
    window.removeEventListener(e, c);
  }

  static trigger = (eventName: string, data?: any) => {
    const event = new CustomEvent(eventName, { detail: data });
    window.dispatchEvent(event);
  }
}
