import { AdsLibrary, AdsOptions } from "./Ads";
import AppConfig from "../../Config";

export default class Admob implements AdsLibrary {


  debug = false;
  // @ts-ignore
  plugin = window.admob;
  interstialAvailable = false;
  videoAvailable = false;
  
  onInterstitialLoad?:(() => void) = undefined;
  onVideoLoad?:(() => void) = undefined;
  onVideoReward?:(() => void) = undefined;
  onVideoClose?:(() => void) = undefined;
  onInterstitialClose?:(() => void) = undefined;

  name = "admob";

  constructor(options: AdsOptions){
    this.debug = options.debug; 
    this.onInterstitialLoad = options.onInterstitialLoad;
    this.onVideoLoad = options.onVideoLoad;
    this.onVideoReward = options.onVideoReward;
    this.onVideoClose = options.onVideoClose;
    this.onInterstitialClose = options.onInterstitialClose;

    this.init();
  }

  init(){


    this.plugin.interstitial.config({
      id: AppConfig.admob_interstitial_id,
      isTesting: this.debug,
      autoShow: false,
      
    })

    
    /// reward ads
    this.plugin.rewardvideo.config({
      id: AppConfig.admob_reward_vid_id,
      isTesting: this.debug,
      autoShow: false
    })

    this.loadInterstitial();
    //this.loadVideo();

    // events
    let interstitialTimeout: NodeJS.Timeout;

    const thePlugin = this.plugin;

    document.addEventListener("admob.interstitial.events.LOAD_FAIL", () => {
     console.log('interstitial load failed');
      clearTimeout(interstitialTimeout);
      interstitialTimeout = setTimeout(() => { 
        thePlugin.interstitial.prepare();
      },AppConfig.retry_ad_interstitial_timer);
    })

    document.addEventListener("admob.interstitial.events.LOAD", () => {
      console.log('interstitial load');

      this.interstialAvailable = true;
      this.onInterstitialLoad && this.onInterstitialLoad();
    })
    
    document.addEventListener("admob.interstitial.events.CLOSE", () => {
      console.log('interstitial close event');
      thePlugin.interstitial.prepare();
      this.onInterstitialClose && this.onInterstitialClose();
    })

    

    document.addEventListener("admob.rewardvideo.events.CLOSE", () => {
      console.log('rewardvideo close event');
      thePlugin.rewardvideo.prepare();
      this.onVideoClose && this.onVideoClose();

    })

    document.addEventListener("admob.rewardvideo.events.REWARD", () => {
      console.log('reward video complete');
      this.onVideoReward && this.onVideoReward();
    })

    document.addEventListener("admob.rewardvideo.events.LOAD", () =>{
      console.log('reward video load');
      this.videoAvailable = true;
      this.onVideoLoad && this.onVideoLoad();
    })

    var rewardTimeout: NodeJS.Timeout;
    document.addEventListener("admob.rewardvideo.events.LOAD_FAIL", (e) => {
     console.log('rewardvideo load failed',e);

      clearTimeout(rewardTimeout);
      rewardTimeout = setTimeout(function(){ 
        thePlugin.rewardvideo.prepare();
      },AppConfig.retry_ad_video_timer);
    })
  }

  loadInterstitial(){
    this.plugin.interstitial.prepare();
  }

  loadVideo(){
    this.plugin.rewardvideo.prepare()
  }

  showInterstital(){
    this.plugin.interstitial.show();
    this.interstialAvailable = false;
  }

  showVideo(){
    this.plugin.rewardvideo.show();
    this.videoAvailable = false;
  }

  isInterstialAvailable(){
    return this.interstialAvailable && AppConfig.load_admob_ads;
  }

  isVideoAvailable(){
    return this.videoAvailable && AppConfig.load_admob_ads;
  }

}