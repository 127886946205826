import React, { useEffect, useState } from "react";
import {v4 as uuid} from "uuid";

interface Props{
  label: string;
  value: boolean;
  onChange: ((value: boolean) => void)
}

  
export default function ToggleSwitch({label,value,onChange}:Props){

  const [id,setId] = useState("");

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.checked);
  }  

  useEffect(() => {
    setId(uuid());
  },[])
  
  return (
    <table>
        <tbody>
            <tr>                                     
                <td>
                    <label className='switch' >
                        <input type='checkbox' id={id} checked={value} onChange={onInputChange} /> 
                        <span className='slider round' ></span>                                   
                    </label>
                </td>
                <td>
                    <label htmlFor={id} style={{marginLeft:10}} >{label}</label>
                </td>  
            </tr>
        </tbody>
    </table>
  )
}