const AppConfig = {// remove clear text
  debug: false,
  // browserDebug: true,
  // debug: true,// remove clearext
  api_url: "https://rummyfriends.com/api",
  debug_api_url: "https://rummyfriends.com/api",
  force_no_purchase: true,
  // debug_api_url: "http://localhost:3000/api",
  gameRefreshSpeed: 3000,
  admob_interstitial_id: "ca-app-pub-8310225347517763/5626444235",
  fan_interstitial_id: "278630273323510_278632376656633",
  admob_reward_vid_id: "ca-app-pub-7986215030796923/1484262123",
  fan_reward_vid_id: "278630273323510_294118428441361",
  playing_points_per_reward: 1,
  ad_after_release_count: 999,
  show_reward_ad: true,
  show_reward_video_ads: false,
  force_reward_ad: false,
  current_version: 755,
  premium_ad_after_count: 3,
  show_premium_ad_at: 3,
  load_fb_ads: true,
  load_admob_ads: false,
  initial_playing_points: 1,
  show_interstitial_if_no_reward: true,
  oath_token: "1004496872422-745ifbtik884lhtokue0mtoj348jlu31.apps.googleusercontent.com",
  retry_ad_video_timer: 10000,
  retry_ad_interstitial_timer: 10000,
  price_menu_text: "",
  show_ad_on_room_exit: true,
  show_ad_on_game_exit: true,
  show_ad_on_drop: true,
  show_ad_on_declare: false,
  show_ad_on_ready: false,
  game_summery_premium_only: 15,
  minimimum_games_for_review: 20,
  leaderboards: {
    stranger2player: "CgkI5se3hZ4dEAIQAw",
  },
  achievements: {
    play500Games: "CgkI5se3hZ4dEAIQEQ",
    win500Games: "CgkI5se3hZ4dEAIQIA",
    win100Strangers: "CgkI5se3hZ4dEAIQJg",
    win100GamesWithoutJoker: "CgkI5se3hZ4dEAIQJw",
    win100GamesWith4Plus: "CgkI5se3hZ4dEAIQKQ",
    win100GamesWith6Plus: "CgkI5se3hZ4dEAIQKw",
    win10GamesWith2Groups: "CgkI5se3hZ4dEAIQLg",
    winWith4Jokers: "CgkI5se3hZ4dEAIQLw",
    win10GamesWithTanala: "CgkI5se3hZ4dEAIQMQ",
    winWith1Color: "CgkI5se3hZ4dEAIQNA",
  },
  events: {
    gamesWith2:"CgkI5se3hZ4dEAIQNg",
    gamesWith3:"CgkI5se3hZ4dEAIQOA",
    gamesWith4:"CgkI5se3hZ4dEAIQOQ",
    gamesWith6:"CgkI5se3hZ4dEAIQOg",
    gamesWith8:"CgkI5se3hZ4dEAIQOw",
    wins:"CgkI5se3hZ4dEAIQPA",
    drops:"CgkI5se3hZ4dEAIQPQ",
    stragerplayGames:"CgkI5se3hZ4dEAIQPg"
  },
  pricing: null,
  support_number: 919000003832
};

export default AppConfig;
/*
  pricing: {
    "no_ads_only": {
      "active": true,
      "discounts": [
        {
          "currency": "INR",
          "value": "₹70"
        }
      ]
    },
    "no_ads": {
      "active": true,
      "discounts": [
        {
          "currency": "INR",
          "value": "₹100"
        }
      ]
    },
    "super_premium": {
      "active": true,
      "discounts": [
        {
          "currency": "INR",
          "value": "₹150"
        }
      ]
    },
    "unlock_premium": {
      "active": false,
      "discounts": [
      ]
    }
  }
  */
// remove clear text from manifest before launch