import { useState, useEffect } from "react";
import { httpLoadX, httpLoad } from "../../Helpers/Http";
import SoundFx from "../../Helpers/SoundFx";
import { setData } from "../../Helpers/Storage";
import { gameMessage } from "../../Helpers/Popups";
import { setLoader } from "../../Helpers/Helper";
import ReactGA from 'react-ga';
import {useHistory} from "react-router-dom"
import Firebase from "../../Phonegap/Firebase";
import AppConfig from "../../Config";

var strangerPlayTimer: NodeJS.Timeout;
var searchStrangerPlayAbort: () => void;
var strangerSlotId: string;
var startStrangerPlayAbort: () => void;

export default function useStrangerPlay(
  playerName : string,
  collectName :  () => Promise<string | null>,
  setShowSPModal : React.Dispatch<React.SetStateAction<boolean>>,

){

  const [strangerPlayAvail,setStrangerPlayAvail] = useState(false);

  const history = useHistory();


  useEffect(() => {
      let abortCheck : () => void;
      let timer : NodeJS.Timer;

      const check = () => {
          timer = setTimeout(() => {
              const [abort,p] = httpLoadX("/strangerplay/isopen");
              p.then(res => {
                  if(res.ok){
                      setStrangerPlayAvail(res.data.toString() === "true"?true:false);
                  }

                  check();
              })
              abortCheck = abort;
          },3000)
      }

      check();
      console.log("check sp");

      return () => {
          clearTimeout(timer);
          abortCheck && abortCheck();
      }

  },[])

  const strangerPlay = async () =>  {
      SoundFx.play("click");
      ReactGA.event({
        category: 'Main',
        action: 'Stranger Play',
        label: "Request"
    });

    Firebase.logEvent("stranger_play",{openSlot: strangerPlayAvail,type: "request"});

    
    let tempName = null;
    if(!playerName){
        tempName = await collectName();
        if(!tempName)
            return;
    }    
    // @ts-ignore
    startStrangerPlay(playerName?playerName:tempName);
  }

  const [strangerStartTime,setStrangerStartTime] = useState<Date>();
  const startStrangerPlay = (name:string) => {
      setShowSPModal(true);

      const [abort,p] = httpLoadX("/strangerplay/request/"+name,{empty:0,version: AppConfig.current_version});
      startStrangerPlayAbort = abort;

      p.then(ret => {
          if(ret.ok){
              const [type,id,rid] = ret.data;
              if(type === "room"){
                  setData("last-pid",id);
                  setData("last-roomid",rid);    
                  setShowSPModal(false);

                  ReactGA.event({
                    category: 'Main',
                    action: 'Stranger Play',
                    label: "Joined"
                  });
          
                  Firebase.logEvent("stranger_play",{type: "joined"});

                  history.push("/room/"+rid+"/"+id);
              }
              else{
                  strangerSlotId = id;
                  searchStrangerPlay();
                  setStrangerStartTime(new Date())
              }
          }
          else{
              setShowSPModal(false);
              gameMessage(ret.data);
          }
      })
  }

  const searchStrangerPlay = () => {
      strangerPlayTimer = setTimeout(function(){        
          
          const [abort,p] = httpLoadX("/strangerplay/check/"+strangerSlotId)
          searchStrangerPlayAbort = abort;

          p.then(ret => {
              if(ret.ok){
                  const [pid,roomId] = ret.data;
                  if(pid && roomId){
                      setData("last-pid",pid);    
                      setData("last-roomid",roomId);    
                      // window.location.href = "/room/"+roomId+"/"+pid+'?u='+uuid();
                      setShowSPModal(false);

                      history.push("/room/"+roomId+"/"+pid)

                      ReactGA.event({
                        category: 'Main',
                        action: 'Stranger Play',
                        label: "Found"
                      });
              
                      Firebase.logEvent("stranger_play",{type: "found"});
                  }
                  else{
                      searchStrangerPlay();
                  }
              }
              else{
                  gameMessage(ret.data);
              }
          })
      },3000);
  }

  const cancelStrangePlay = () => {
      startStrangerPlayAbort && startStrangerPlayAbort();
      searchStrangerPlayAbort && searchStrangerPlayAbort();
      clearTimeout(strangerPlayTimer);
      setShowSPModal(false);   
      setStrangerStartTime(undefined);

      if(strangerSlotId){
          setLoader(true);
          httpLoad("/strangerplay/cancel/"+strangerSlotId)
          .then((res) => {
              setLoader(false);
          });

          ReactGA.event({
            category: 'Main',
            action: 'Stranger Play',
            label: "Cancel"
          });
  
          Firebase.logEvent("stranger_play",{type: "cancel"});
      }
  }

  const [formTime,setFormTime] = useState<string>("00:00");

  useEffect(() => {
      let timer : NodeJS.Timeout;
      const check = () => {
          if(!strangerStartTime){
              setFormTime("00:00");
              return;
          }

          const diff = Math.abs((new Date().getTime() - strangerStartTime.getTime())/1000);
          
          const minutes = Math.floor(diff / 60);
          const seconds = Math.floor(diff % 60);
          const form = (minutes > 9?minutes:"0"+minutes)+":"+(seconds > 9 ? seconds : "0"+seconds);
          if(form !== formTime){
              setFormTime(form);
          }

          timer = setTimeout(() => {
              check();
          },1000)

      }

      check();
      console.log("check time");

      return () => {
          clearTimeout(timer);
      }



  },[strangerStartTime,formTime])

  return {
    strangerPlay,
    strangerPlayAvail,
    cancelStrangePlay,
    formTime
  }; 
} 