import Ads from "./Ads/Ads";
import Store from "./Store";
import { addPlayPoints } from "../Helpers/PlayPoints";
import AppConfig from "../Config";
import Firebase from "./Firebase";
import { fetch_config } from "./RemoteConfig";

const app = {
    // Application Constructor
    initialize: function() {
        this.bindEvents();       
    },
    bindEvents: function() {        
        document.addEventListener('deviceready', () => {
          this.onDeviceReady();
        });
    },
    onDeviceReady: function() {
      console.log("ondeviceready");

      this.loadEssentials();
    },
     
    loadEssentials: function(){
      // @ts-ignore
      if(window.MobileAccessibility){
        // @ts-ignore
        window.MobileAccessibility.usePreferredTextZoom(false);
      }

      const onRewardComplete = () => {
        addPlayPoints(AppConfig.playing_points_per_reward);
      }

      Ads.init(AppConfig.debug,onRewardComplete);
      Store.init();

      // @ts-ignore
      const globalization = navigator.globalization;
      if(globalization){
        globalization.getLocaleName(function (locale:any) {
          if(window.localStorage)
            window.localStorage.setItem("i18nextLng",locale.value)
            Firebase.setUserProperty("Language",locale.value);
        });
      }



      // firebase
      // @ts-ignore
      const firebase = window.FirebasePlugin;
      if(firebase){
        firebase.fetch(function(d:any){
          console.log("fb fetch",d);
          if(d === "OK"){
            firebase.activateFetched(function(d:any){ 
                console.log("active",d);
                fetch_config();
            },function(){
              fetch_config();
            });
          }
          else{
            fetch_config();
          }

        },function(){
          fetch_config();
        });
       
      }
      

    }
};

export default app;