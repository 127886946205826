
export function getData(key: string){
  if(!window.localStorage)
    return null;
  
  return window.localStorage.getItem(key);
}

export function setData(key: string, value: string){
  if(!window.localStorage)
    return null;
  
  window.localStorage.setItem(key,value);
}

export function getSessionData(key: string){
  if(!window.sessionStorage)
    return null;
  const d = window.sessionStorage.getItem(key);
  if(d){
    try{
      return JSON.parse(d);
    }
    catch(e){
      return d;
    }
  }

  return null;
}

export function setSessionData(key: string, value: any){
  if(!window.sessionStorage)
    return null;

  window.sessionStorage.setItem(key,JSON.stringify(value));
  // console.log("sessint session",key);
}
