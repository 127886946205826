import Modal from "react-bootstrap/Modal";
import React, { useState, useEffect, useCallback } from "react";
import SoundFx from "../../Helpers/SoundFx";
import ToggleSwitch from "../../Components/ToggleSwitch";
import { useTranslation } from 'react-i18next';
import {isAndroid as isAndroidUser, lockOrientation, unlockOrientation, updateDeviceFS} from "../../Helpers/Device"
import { setData, getData } from "../../Helpers/Storage";
import { toggleFS } from "../../Helpers/Device";

interface Props{
  show: boolean;
  mic?: boolean;
  sound?: boolean;
  onCloseClick: (() => void);
  setAllowMic?: ((val:boolean) => void);
  setAllowSound?: ((val:boolean) => void);
}

export default function SettingsMenu({
  show,
  setAllowSound, 
  setAllowMic, 
  sound, 
  onCloseClick,
  mic,
}: Props){


  const [landscapeLocked,setLandscapelock] = useState(true);
  const [fullscreen,setFullscreen] = useState(true);
  const [music, setAllowMusic] = useState(SoundFx.soundOn);
  const [beep, setAllowBeep] = useState(SoundFx.beep);

  const { t } = useTranslation();

  useEffect(() => {
    const orientation = getData("orientation");
    if(orientation === "free")
      setLandscapelock(false)

    const fs = getData("fullscreen");
    if(fs === "0")
      setFullscreen(false);
  },[])


  useEffect(() => {
    const voiceSetting = getData("voice") ;
    if(voiceSetting !== null && voiceSetting === "0"){
      setAllowSound && setAllowSound(false)
    }
    else{
      setAllowSound && setAllowSound(true)
    }
 },[setAllowSound])

  const toggleMusic = useCallback((newVal: boolean) => {

    setData('sound',newVal?"1":"0");
    SoundFx.onSoundSettingUpdate();

    if(!newVal){
      setAllowMusic(false);
    }
    else{
      SoundFx.play("toggle");
      setAllowMusic(true)
    }    
  },[])

  const toggleBeep = useCallback((newVal: boolean) => {

    setData('beep',newVal?"1":"0");
    SoundFx.onSoundSettingUpdate();

    SoundFx.play("toggle");
     

    setAllowBeep(newVal)

  },[])

  const toggleFullscreen = useCallback(() =>{
    SoundFx.play("toggle");
    toggleFS();
  },[])

  const toggleSound = useCallback((newval:boolean) => {
    SoundFx.play("toggle");
    setAllowSound && setAllowSound(newval)
    // setData('voice',newval?"1":"0");
  },[setAllowSound])

  const toggleMic = useCallback((newval:boolean) => {
    SoundFx.play("toggle");
    setAllowMic && setAllowMic(newval)    
  },[setAllowMic])

  const toggleLandscapeUnlock = useCallback(() => {
    SoundFx.play("toggle");
    setLandscapelock((isLocked) => {
      const newIsLocked = !isLocked;
      setData('orientation', newIsLocked?"landscape":"free");

      // @ts-ignore
      if(newIsLocked){        
        lockOrientation("landscape");
      }
      else
        unlockOrientation()  

      return newIsLocked;
    });
         
  },[])

  const toggleFullscreenUnlock = useCallback(() => {
    SoundFx.play("toggle");


    setFullscreen((fs) => {
      const newfs = !fs;
      setData('fullscreen',newfs?"1":"0");
      return newfs;
    });
    
    updateDeviceFS();
  },[setFullscreen])

  const [isAndroid,setIsAndroid] = useState(false);

  useEffect(() => {
    const android = isAndroidUser();
    setIsAndroid(android);
  },[]);

  return (
    <>
      {show && <Modal show={show}  animation={false}  onHide={onCloseClick} centered size="sm" >
        <Modal.Header closeButton>{t("Settings")}</Modal.Header>
        <Modal.Body>
            <div className='form-group menu'>           
                <div className='the-info' >
                    <div  >
                        {mic !== undefined && sound !== undefined && <div>
                          <div className='subtitle' >{t("Voice Chat Settings")+"("+t("beta")+")"}:</div>

                          <div className='row' >
                              <div className='col-auto' >
                              <ToggleSwitch label={t('Mic')} value={mic} onChange={toggleMic} ></ToggleSwitch>

                              </div>
                              <div className='col-auto' >
                              <ToggleSwitch label={t('Audio')} value={sound} onChange={toggleSound} ></ToggleSwitch>
                              </div>
                          </div>

                          <hr />
                        </div>}
                        <div >

                          <div className='subtitle' >{t("General Settings")}:</div>


                          <div className='row' >
                              <div className='col-auto  mb-3' >
                                <ToggleSwitch value={music} onChange={toggleMusic} label={t('Sound Effects')} />

                              </div>
                              {!music && <div className='col-auto mb-3' >
                                <ToggleSwitch value={beep} onChange={toggleBeep} label={t('Your Turn Beep')} />
                              </div>}
                          </div>

                          {!isAndroid &&
                              <button type='button' className='btn mb-3 btn-secondary' onClick={toggleFullscreen}  ><i className='fas fa-expand' ></i> {t("Fullscreen")}</button>}

                          {isAndroid && <div className='mb-3'>                      
                              <ToggleSwitch value={landscapeLocked} onChange={toggleLandscapeUnlock} label={t('Lock Landscape View')} />
                              </div>}

                          {isAndroid && <div className='mb-3'>                      
                          <ToggleSwitch value={fullscreen} onChange={toggleFullscreenUnlock} label={t('Fullscreen')} />
                          </div>}
                        </div>
                        
                    </div>
                   
                </div>         
            </div>
        </Modal.Body>
      </Modal>}
    </>
  )
}