import AppConfig from "../Config";

export default class Firebase{

  static FirebasePlugin:any;


  static init(){
    // @ts-ignore
    Firebase.FirebasePlugin = window.FirebasePlugin;
  }


  static setScreenName(name: string){
    if(Firebase.FirebasePlugin)
    Firebase.FirebasePlugin.setScreenName(name);
  }

  static logEvent(event: string, info:object){
    if(Firebase.FirebasePlugin && !AppConfig.debug)
    Firebase.FirebasePlugin.logEvent(event,info);
  }

  static setUserId(value:string){
    if(Firebase.FirebasePlugin)
    Firebase.FirebasePlugin.setUserId(value);

  }

  static setUserProperty(name: string,value:string){
    if(Firebase.FirebasePlugin)
    Firebase.FirebasePlugin.setUserProperty(name,value);

  }

}