import Modal from "react-bootstrap/Modal";
import React from "react";
import SoundFx from "../../Helpers/SoundFx";
import { useTranslation } from 'react-i18next';
import AppConfig from "../../Config";

interface Props{
  show: boolean;
  onCloseClick: (() => void);
  onRequestReload: (() => void)
}

export default function HelpMenu({
  show,
  onCloseClick,  
  onRequestReload
}: Props){

  const { t } = useTranslation();
  const onFeedbackClick = () => {
    SoundFx.play("click");
    window.open('https://wa.me/'+AppConfig.support_number, '_system');    
  }

  return (
    <>
      <Modal show={show}  animation={false}  onHide={onCloseClick} size="lg" centered>
        <Modal.Header closeButton>{t("Help & Support")}</Modal.Header>
        <Modal.Body>
          <div className='form-group menu'>           
            <div className='row' >
        
              <p className='col-lg-8 col-sm-6' >{t("Message us on whatsapp for any feedback or support")}</p>
              <div className=' col-lg-4 col-sm-6 '>
                <button type='button' className='btn btn-block btn-secondary' onClick={onFeedbackClick}  ><i className='fas fa-comments' ></i> {t("Chat In Whatsapp")}</button>

              </div>
            </div>
            <hr />
            <div className='row' >
              <p className='col-lg-8 col-sm-6' >{t("face_problem_solution")}</p>
              <div className='col-lg-4 col-sm-6' >
                <button type='button' className='btn btn-block btn-secondary' onClick={onRequestReload}  ><i className='fas fa-undo' ></i> {t("Reload Page")}</button>

              </div>

            </div>
          </div>
        </Modal.Body>
      </Modal>

    </>
  )
}