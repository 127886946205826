import en from "./en.json";
import ta from "./ta.json";
import te from "./te.json";
import hi from "./hi.json";

export default {
  en: {
    translation: en
  },
  ta: {
    translation: ta
  },
  te: {
    translation: te
  },
  hi: {
    translation: hi
  },
};