import AppConfig from "../Config";

interface HttpReturnType {
   ok: boolean;
   status: number;
   data: any;
}

export function httpLoad(api: string, data?: any) {
   const [, p] = httpLoadX(api, data);
   return p;
}

export function httpLoadX(api: string, data?: any): [() => void, Promise<HttpReturnType>] {
   let options = {};
   const apiUrl = AppConfig.debug ? AppConfig.debug_api_url : AppConfig.api_url;
   const controller = new AbortController();
   const signal = controller.signal;
   let aborted = false;


   if (data) {
      options = {
         method: 'POST',
         headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
         },
         body: JSON.stringify(data)
      };
   }


   const promise = new Promise<HttpReturnType>((resolve, reject) => {
      fetch(apiUrl + api, { ...options, signal })
         .then(res => {

            if (res.ok) {
               res.json()
                  .then(data => {
                     resolve({
                        ok: true,
                        status: res.status,
                        data: data
                     })
                  })
                  .catch(e => {
                     if (aborted) {
                        reject();
                     }
                     else {
                        resolve({
                           ok: false,
                           status: 500,
                           data: "Something went wrong."
                        })
                     }

                  })

            }
            else {
               if (res.status === 400) {
                  res.text()
                     .then(data => {
                        resolve({
                           ok: false,
                           status: res.status,
                           data: data
                        })
                     })
                     .catch(e => {
                        if (aborted) {
                           reject();
                        }
                        else {
                           resolve({
                              ok: false,
                              status: 500,
                              data: "Something went wrong."
                           })
                        }
                     })

               }
               else {
                  resolve({
                     ok: false,
                     status: res.status,
                     data: "Unable to process your request."
                  })
               }
            }
         })
         .catch(e => {
            if (aborted) {
               reject();
            }
            else{
               const res = {
                  ok: false,
                  status: 499,
                  data: "Something went wrong, check your internet connection."
               };
               resolve(res);
            }
            
         })
   });

   const abort = () => {
      aborted = true;
      controller.abort();
   }
   return [abort, promise];
}
