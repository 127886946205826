import AppConfig from "../Config";
import { RoomType, TheCard, Game, GameState } from "../Helpers/Models";
import { getHandJokers, checkIfHandHasTanala, checkIfHandHas1Color } from "../Helpers/Helper";

export interface CurrentUser{
  displayName: string;
  playerId: string;
  title: boolean;
  iconImageUrl: string;
  hiResIconImageUrl: string;
}

export default class Play{
  private static Plugin:any;

  private static SignedIn: boolean = false;

  static init(){
    // @ts-ignore
    Play.Plugin = window.cordova && window.cordova.plugins && window.cordova.plugins.playGamesServices;
  }


  static isUserSignedIn(){
    return new Promise<boolean>((res,rej) => {
      if(Play.Plugin){
        Play.Plugin.isSignedIn(function(signedIn:any){          
          res(signedIn && signedIn.isSignedIn)          
        });
      }
      else{
        rej();
      }
    })    
  }

  static signIn(){
    return new Promise<boolean>((res,rej) => {
      if(Play.Plugin)
      {
        Play.Plugin.auth(
          function suc(){ 
            Play.SignedIn = true;
            res(true);
          },
          function fail(){
            res(false);
          }
        )  
      }
      else{
        rej();
      }
    })
  }

  static signOut(){
    return new Promise<boolean>((res,rej) => {
      if(Play.Plugin)
      {
        Play.Plugin.signOut(
          function suc(){ 
            Play.SignedIn = false;
            res(true);
          }
        )  
      }
      else{
        rej();
      }
    })
  }

  static getUser(){
    return new Promise<CurrentUser>((res,rej) =>{
      if(Play.Plugin){
        Play.Plugin.showPlayer(
          function succ(a:CurrentUser){
            res(a);
          },
          function error(e:any){
            rej();
          }
        )
      }
      else{
        rej();
      }
    })
  }

  static submitScore(score:number, leaderboarid: string){
    return new Promise((res,rej) => {
      if(!Play.SignedIn){
        rej();
        return;
      }

      var data = {
        score: score,
        leaderboardId: leaderboarid
      };

      Play.Plugin.submitScore(data, function () {
          res();
      }, function() {
          rej();
      });
    })    
  }

  static showLeaderboard(leaderboarid: string){
    return new Promise((res,rej) => {
      if(!Play.SignedIn){
        rej();
        return;
      }
      var data = {
        leaderboardId: leaderboarid
      };
      Play.Plugin.showLeaderboard(data, function () {
          res();
      }, function() {
          rej();
      });
    })    
  }

  static showAllLeaderboards(){
    return new Promise((res,rej) => {
      if(!Play.SignedIn){
        rej();
        return;
      }

      Play.Plugin.showAllLeaderboards(function () {
          res();
      }, function() {
          rej();
      });
    })    
  }


  static unlockAchievement(achievementId: string){
    console.log("unlock achivement",achievementId);
    return new Promise((res,rej) => {
      if(!Play.SignedIn){
        rej();
        return;
      }
      var data = {
        achievementId: achievementId
      };
      Play.Plugin.unlockAchievement(data, function () {
          res();
      }, function() {
          rej();
      });
    })    
  }

  static revealAchievement(achievementId: string){
    console.log("reveal achivement",achievementId);
    return new Promise((res,rej) => {
      if(!Play.SignedIn){
        rej();
        return;
      }
      var data = {
        achievementId: achievementId,
      };
      Play.Plugin.revealAchievement(data, function () {
          res();
      }, function() {
          rej();
      });
    })    
  }

  static incrementAchievement(achievementId: string,numSteps: number){
    console.log("increment achivement",achievementId);
    return new Promise((res,rej) => {
      if(!Play.SignedIn){
        rej();
        return;
      }
      var data = {
        achievementId: achievementId,
        numSteps: numSteps
      };
      Play.Plugin.incrementAchievement(data, function () {
          res();
      }, function() {
          rej();
      });
    })    
  }

  static incrementEvent(eventId: string,numSteps: number){
    console.log("increment event",eventId);
    return new Promise((res,rej) => {
      if(!Play.SignedIn){
        rej();
        return;
      }
      var data = {
        eventId: eventId,
        numSteps: numSteps
      };
      Play.Plugin.incrementEvent(data, function () {
          res();
      }, function() {
          rej();
      });
    })    
  }

  static showAchievements(){
    return new Promise((res,rej) => {
      if(!Play.SignedIn){
        rej();
        return;
      }

      Play.Plugin.showAchievements( function () {
          res();
      }, function() {
          rej();
      });
    })    
  }

  

  static updatePlayerAchivements(noOfPlayers:number,game: Game,hand: TheCard[][]){
    Play.incrementAchievement(AppConfig.achievements.play500Games,1);
    const win = game.gameState === GameState.ShowDeclared;
    
    if(game.type === RoomType.Friends){
      if(noOfPlayers === 2){
        Play.incrementEvent(AppConfig.events.gamesWith2,1);      
      }
      else if(noOfPlayers === 3){
        Play.incrementEvent(AppConfig.events.gamesWith3,1);      
      }
      else if(noOfPlayers >= 4 && noOfPlayers < 6){
        Play.incrementEvent(AppConfig.events.gamesWith4,1);      
      }
      else if(noOfPlayers >= 6 && noOfPlayers < 8){
        Play.incrementEvent(AppConfig.events.gamesWith6,1);      
      }
      else if(noOfPlayers >= 8){
        Play.incrementEvent(AppConfig.events.gamesWith8,1);      
      }
    }
    else if(game.type === RoomType.StrangerPlay){
      Play.incrementEvent(AppConfig.events.stragerplayGames,1);      
    }

    if(!win)
      return

    Play.incrementEvent(AppConfig.events.wins,1);     
    Play.incrementAchievement(AppConfig.achievements.win500Games,1);
    

    if(noOfPlayers >= 4){
      Play.incrementAchievement(AppConfig.achievements.win100GamesWith4Plus,1);      
    }
    
    if(noOfPlayers >= 6)
      Play.incrementAchievement(AppConfig.achievements.win100GamesWith6Plus,1);
      
    if(game.type === RoomType.StrangerPlay)
      Play.incrementAchievement(AppConfig.achievements.win100Strangers,1);

    if(getHandJokers(hand,game.deckJoker) === 0)
      Play.incrementAchievement(AppConfig.achievements.win100GamesWithoutJoker,1);

    if(hand.length === 2)
      Play.incrementAchievement(AppConfig.achievements.win10GamesWith2Groups,1);

    if(checkIfHandHasTanala(hand))
      Play.incrementAchievement(AppConfig.achievements.win10GamesWithTanala,1);

    if(getHandJokers(hand,game.deckJoker) >= 4)
      Play.unlockAchievement(AppConfig.achievements.winWith4Jokers);

    if(checkIfHandHas1Color(hand))
      Play.unlockAchievement(AppConfig.achievements.winWith1Color);
  

  }

  static updatePlayerScores(score: number,noOfPlayers: number,roomType: RoomType|undefined){
    if(roomType !== RoomType.StrangerPlay){
      return;
    }

    console.log("playerScore",score);
  }
  // play is to use game history and gameid to pushing scores to db

}