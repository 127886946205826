import React, { useCallback } from "react";
import {  GameAction } from "../Helpers/Models";

interface StatusProps{
    message: string;
    type?:string;
    actions?: BtnAction[];
    onAction? : (action:GameAction) => void
}


export class BtnAction{
    label: string;
    action: GameAction;

    constructor(action:GameAction,label:string){
        this.label = label;
        this.action = action;      
    }
}

export function StatusBox(props: StatusProps){
    const { onAction } = props;
    const onBtnClick = useCallback((name:GameAction) => {
        if(onAction)
            onAction(name);
    },[onAction])

    return (
        <div className="statusbox" >
          
            <div className='msg' >{props.message}</div>
            <div className='buttons' >
                {props.actions?.map((a,i) => {
                    return (
                        <button key={i} type='button' className='btn btn-primary' onClick={() => onBtnClick(a.action)} >{a.label}</button>
                    )
                })}
                
            </div>
        </div>
    )
}