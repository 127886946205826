import { TheCard, CardType } from "./Models";
import { cloneObject as clone } from "./Helper";

export default class CardModel{
    private jokerCard: TheCard | undefined;
  
    constructor(jokerCard: TheCard | undefined){
        this.jokerCard = jokerCard;
    }
  
    getCount = (hand: TheCard[][]) => {
        let sequences = 0;
        let count = 0;
    
        // pure sequence
        hand.find(a => {
            if(this.isSequence(a,true)){
                sequences++;
                return true;
            }
                
            return false;
        })
    
        // sequence
        if(sequences > 0){
            sequences = 0;// since we r not removing above counted sequence
            hand.find(a => {
                if(this.isSequence(a)){
                    sequences++;
                    
                    if(sequences >= 2)
                        return true;
                }
                return false;
            })
        }
    
        hand.forEach(a => {
            if(this.isSequence(a,true)){
    
            }
            else if(sequences >= 1 && this.isSequence(a)){
    
            }
            else if(sequences >= 2 && this.isSet(a)){
               
            }
            else{
                a.forEach(b => {
                    if(!this.isJokerCard(b) && b.number){
                        if(b.number > 10)
                            count += 10;
                        else if(b.number === 1)
                            count += 10;
                        else
                            count += b.number;
                    }
                })
            }
        })
    
        return count;
    }
  
    checkShow = (hand: TheCard[][]) => {
        const count = this.getCount(hand);
        return count === 0?true:false;
    }
  
    isJokerCard = (card: TheCard,pure: boolean = false) => {
        if(card.type === CardType.joker){
            return true;
        }
  
        if(!pure && this.jokerCard && card.number){
            if(this.jokerCard.type === CardType.joker){
                if(card.number === 1)
                    return true;
            }
            
            if(this.jokerCard.number)
                return this.jokerCard.number === card.number;
        }
  
  
        return false;
    }  
   
    static isTanala = (cards: TheCard[]) => {
        let prevCard : TheCard|undefined = undefined;
        let tanala = true;
        
        if(cards.length < 3)
          return false;
  
        cards.find(a => {
            if(prevCard && (a.number !== prevCard.number || a.type !== prevCard.type))
            {
                tanala = false;
                return true;
            }
            prevCard = a;
            return false;
        });
    
        if(tanala){
            return true;
        }
        return false;
    }
  
    isSequence = (cards: TheCard[], pure = false) => {
        if(cards.length <= 2){
            return false;
        }
  
        let prevCard : TheCard|undefined;
        let valid = true;
        let isJokerTanala = true;
  
        cards.find(a => {
            if(!this.isJokerCard(a,true)){
                isJokerTanala = false;
                return true;
            }
            return false;
        });
  
        if(isJokerTanala)
            return true;
  
        cards.find(a => {
            if(this.isJokerCard(a,pure)){
                if(pure){
                    // no jokers in pure sequence
                    valid = false;
                    return true;
                }
                return false;
            }
                
  
            if(prevCard){
                if(a.type !== prevCard.type){
                    valid = false;
                    return true;
                }
  
                if(!a.number || !prevCard.number){
                    valid = false;
                    return true;
                }
            }
            prevCard = a;
            return false;
        });
  
        if(!valid)
            return false;
        
        if(CardModel.isTanala(cards)){
          return true;
        }
  
        let cloneObj = clone(cards);
        let jokers = 0;
        cloneObj = cloneObj.filter(a => {
            if(this.isJokerCard(a,pure)){
                jokers++;
                return false;
            }                
            return true;
        });
  
        let missing = this.checkCardsSequence(cloneObj);
  
        if(pure){
            return missing === 0;
        }
        else if(missing >= 0 && ((missing - jokers) <= 0)) {
            return true;
        }
  
        return false;
  
    }
  
    private checkCardsSequence = (cards: TheCard[],internalCall = false) : number => {
        let lowest: number = 15;
        let highest: number = 0;
        let numberAre:number[] = [];
        let duplicateCards = false;
  
        cards.find(a => {
            if(a.number){
                if(a.number < lowest)
                    lowest = a.number;
      
                if(a.number > highest)
                    highest = a.number;
      
                if(numberAre.find(b => b === a.number)){
                    duplicateCards = true;
                    return true;
                }                    
                numberAre.push(a.number);        
            }   
            return false;         
        });
        if(duplicateCards)
            return -1;
      
        let numberShouldBe = [];
        if(lowest){
            for(let i  = lowest;i <= highest;i++){
                numberShouldBe.push(i);
            }
        }
        // numberAre.sort((a,b) => a > b?1:-1);
  
        const missing = numberShouldBe.filter(a => {
            const i = numberAre.findIndex(b => b === a);
            return i > -1?false:true;
        });        
      //  console.log("missing",numberShouldBe,numberAre,missing);
      
        if(!internalCall && missing.length > 0){
            const cloneObj = clone(cards);
            const cardWithA = cloneObj.find(a => a.number === 1);
            if(cardWithA){
                cardWithA.number = 14;
                const len = this.checkCardsSequence(cloneObj,true)
                if(len < missing.length)
                    return len;
            }
        }
      
        return missing.length;
      }
  
  
    isSet = (cards: TheCard[]) => {
        if(cards.length <= 2){
            return false;
        }
  
        let prevCard : TheCard;
        let valid = true;
        const allSeqCards :TheCard[] = [];
        cards.forEach(a => {
            if(this.isJokerCard(a))
                return false;
  
            if(prevCard){
                if(allSeqCards.find(b => b.type === a.type)){
                    valid = false;
                    return true;
                }
  
                if(!a.number || !prevCard.number){
                    valid = false;
                    return true;
                }
  
                if(a.number !== prevCard.number){
                    valid = false;
                    return true;
                }
            }
            prevCard = a;
            allSeqCards.push(a);
            return false;
        })
  
        return valid;
    }
  
}