import React, { useState, useEffect, useCallback } from "react";
import { Game, GameState, PlayerState, TheCard, GameAction, AudioConnectedPlayer, AudioConnectedPlayerStatus, GameType, Player, Chat } from "../Helpers/Models";
import { SeatedPlayers, TheSeatedPlayer } from "../Components/SeatedPlayers";
import { PlayingDeck } from "../Components/PlayingDeck";
import { StatusBox, BtnAction } from "../Components/StatusBox";
import { PlayerHand } from "../Components/PlayerHand";
import { setLoader, animate, getHighestScore } from "../Helpers/Helper";
import { gameAction } from "../Helpers/GameAction";
import useOnAction, { actionReleaseCard } from "./OnAction";
import useVoiceChat, { destroyCurrentPeerNStream } from "./VoiceChat";
import SoundFx from "../Helpers/SoundFx";
import {useHistory} from "react-router-dom"
import { checkIfSuperUserExist, showPriceMenu, showAd } from "../Helpers/Profits";
import { gameMessage, gameConfirm } from "../Helpers/Popups";
import { httpLoad } from "../Helpers/Http";
import { useTranslation } from 'react-i18next';
import Firebase from "../Phonegap/Firebase";
import ChatPopup from "./ChatPopup";
import SettingsMenu from "./Menus/Settings";
import RoomInfoMenu from "./Menus/RoomInfo";
import GameSummeryMenu, {  useManageScore } from "./Menus/GameSummery";
import HelpMenu from "./Menus/Help";
import AdjustTotalsModal from "./AdjustTotalsModal";
import CompComm from "../Helpers/CompComm";
import AppConfig from "../Config";
import ChatMessage from "./ChatMessage";
import useDeviceAssist from "./Main/DeviceAssist";

interface Props{
    game: Game | undefined;
    playerId: string;
    lastUpdate?: number;
    roomId: string;
    onGameChange: (game: Game) => void;

}

let oldPlayerState : PlayerState;
let oldPlayersStates : PlayerState[] = [];
let oldCardId : string = "";
let executeOnceGameInfo = true;
// let summeryShown = false;

export function GameController (props: Props){

    const [selectedCard,setSelectedCard]  = useState<TheCard | undefined>();
    const [message,setMessage]  = useState<string>("");
    const [actions,setActions] = useState<BtnAction[]>([]);
    const [seatedPlayers, setSeatedPlayers] = useState<TheSeatedPlayer[]>([]);
    const [playerHand, setPlayerHand] = useState<TheCard[][]>([]);
    const [deckFlash, setDeckFlash] = useState(false);
    const [deckDrop, setDeckDrop] = useState(false);
    const [playerList, setPlayersList] = useState<string[]>([]);
    const [allowSound, setAllowSound] = useState(false);
    const [allowMic, setAllowMic] = useState(false);
    const [isAndroidUser, setIsAndroidUser] = useState(false);
    const [isPremium,setIsPremium] = useState(false);

    const [showSettingsMenu,setShowSettingsMenu] = useState(false);
    const [showGameSummeryMenu,setShowGameSummryMenu] = useState(false);
    const [showRoomInfoMenu,setShowRoomInfoMenu] = useState(false);
    const [showHelpMenu,setShowHelpMenu] = useState(false);
    const [audioRefreshCounter,setAudioRefreshCounter] = useState(0);
    const [modHandCards,setModHandCards] = useState<TheCard[]>();
    const [modPrevCards,setModPrevCards] = useState<TheCard[]>();

    const [totalStyle,setTotalStyle] = useState(false);
    const { historyPlayers, playerTotals } = useManageScore(props.game?.roomHistory || [],totalStyle);
    const { t } = useTranslation();

    const history = useHistory();

    const onPlayerHandChange = useCallback((hand: TheCard[][]) => {
        setPlayerHand(hand);
    },[])

    const onCardSelect = useCallback((card: TheCard| undefined)  => {
        setSelectedCard( card );
    },[])

    const onDeckClick = useCallback((isCard : boolean) => {
        if(props.game && props.game.gameState === GameState.Playing){
            let player = props.game.players.find(a => a.id === props.playerId);

            if(player?.state === PlayerState.TurnNotPicked){
                if(isCard){
                    const deckCards = [...props.game.previousCards];                        
                    const theCard = deckCards.pop();
                    if(theCard){
                        const modCards = [...props.game.myCards,theCard]
                        setModHandCards(modCards)
                        setModPrevCards(deckCards);
                    }                
                }
                else{
                    setLoader(true);
                }

                animate(true);
                SoundFx.play(isCard?"card-pick":"deck-pick")

                CompComm.trigger("AbortGameRequests");
                gameAction(props.roomId,props.playerId,isCard ? GameAction.PickCard : GameAction.PickDeck).then((g) => {
 
                    props.onGameChange(g);    
                    CompComm.trigger("ContinueGameRequests");

                    if(isCard){
                        setModHandCards(undefined)
                        setModPrevCards(undefined);
                    }
                    else{
                        setLoader(false);
                    }
                       
                },(e) => {
                    setLoader(false);
                    gameMessage(e);
                });
                
            }                
            else if(player?.state === PlayerState.TurnPicked)
                gameMessage(t("You already picked a card"));
        }
    },[props,t])


    useEffect(() => {
        if(!props.game)
            return;

        const oldval = JSON.stringify(playerList);
        const data = props.game.players.map(a => {
            return a.id;
        })
        const newval = JSON.stringify(data);

        if(oldval === newval)
            return;
        
        setPlayersList(data);
        // console.log("players",players);

    },[props,playerList])

    
    const [connectedPlayers,setConnectedPlayers] = useState<    AudioConnectedPlayer[]>([]);
    const voiceConnectedPlayers = useVoiceChat(props.roomId, props.playerId,playerList,allowSound,allowMic,audioRefreshCounter);    
    
    useEffect(() => {

        voiceConnectedPlayers.forEach(a => {
            if(!props.game)
                return;

            const n = props.game.players.find(p => p.id === a.id);
            if(n)
                a.name = n.name;        
            if(props.game.players.length && a.id === props.game.players[0].id)
                a.host = true;
        })

        if(!props.game){
            return;
        }

        const plrs:AudioConnectedPlayer[] = 
            props.game.players.map((a,i) => {
                let status = AudioConnectedPlayerStatus.notconnected;
                const vp = voiceConnectedPlayers.find(b => b.id === a.id);
                if(vp){
                    status = vp.status;
                }
                return {
                    id: a.id,
                    name: a.name,
                    host: i === 0,
                    status: status
                }
            })

        setConnectedPlayers(plrs)
    },[voiceConnectedPlayers,props])


    const onAction = useOnAction(
            playerHand,
            props.roomId,
            props.playerId,            
            selectedCard,
            props.game? props.game.players.filter(a => a.removeNextGame !== true).length : 0,
            setSelectedCard,
            props.onGameChange,
            setModHandCards,
            setModPrevCards,
            props.game?.myCards,
            props.game?.previousCards,
            props.game?.players
        );

    const seekReady = useCallback((player: Player,props: Props) => {  

        if(player?.state !==PlayerState.Ready){
                
            if(props.game?.gameType === GameType.Pool){

                let total = playerTotals.find(a => a.id === player?.id)?.score;

                if(total === undefined){
                    setMessage(t("Loading..."));                        
                    setActions([]);
                    return;
                }

                let playersBalance = 0;

                props.game.players.forEach(p => {
                    let total = playerTotals.find(a => a.id === p?.id)?.score;
                    if(total !== undefined && total < (props.game as Game).poolPoints){
                        playersBalance++
                    }
                });

                if(total >= props.game.poolPoints){
                    // when current player is out
                    if(props.game.reEntry && playersBalance > 1 
                        && (props.game.poolPoints-(getHighestScore(props.game.roomHistory,props.game.poolPoints)+props.game.dropPoints+1)) > 0){
                        // player out, more than 2 players, rentry enabled but highest player score is not having drop chance
                        setMessage( t("You LOST! You can re-enter the game, do you want to do Re-Entry?"));
                        setActions([new BtnAction(GameAction.ReEnter,t("Yes, Re-Enter")),new BtnAction(GameAction.SkipReEnter,t("No, Leave"))]);
                    }
                    else if(playersBalance > 1){
                        // player out, more than 2 players, no rentry option
                        setMessage( t("You LOST! Better luck next time."));
                        setActions([new BtnAction(GameAction.SkipReEnter,t("Leave"))]);
                    }                           
                    else{
                        // player out, last 2 players, no rentry option
                        setMessage( t("You LOST! Better luck next time."));
                        setActions([new BtnAction(GameAction.Ready,t("New Game")), new BtnAction(GameAction.SkipReEnter,t("Leave"))]);
                    } 

                    return;
                }

                if(playersBalance === 1){
                    setMessage( t("You WIN! Congratulations on your win."));
                    setActions([new BtnAction(GameAction.Ready,t("New Game")), new BtnAction(GameAction.SkipReEnter,t("Leave"))]);
                    return;
                }
             
                setMessage( t("Click ready to start next round.") );            
                setActions([new BtnAction(GameAction.Ready,t("Ready"))]);
            }
            else{
                setMessage( t("Click ready to start new game. Room Id")+": "+props.roomId );            
                setActions([new BtnAction(GameAction.Ready,t("Ready")), new BtnAction(GameAction.Invite,t("Invite"))]);
            }


        } 
        else{
            // const ready = props.game.players.filter(a => a.state === PlayerState.Ready);
            // if(isPlayerHost && ready.length >= 2){
            //     setActions([new BtnAction(GameAction.SkipStart,t("Start with ready players"))]);
            // }
            // else
            setMessage(t("Waiting for other player to be ready."));                        
            setActions([]);
        } 
    },[playerTotals,t])

    useEffect(() => {
       
        if(!props.game)
            return;

        let player = props.game.players.find(a => a.id === props.playerId);

        if(!player)
            return;

        let soundPlayed = false;
        const newPlayerState = (player.state);
        if(oldPlayerState !== newPlayerState){
            if((newPlayerState === PlayerState.TurnNotPicked ||  
            newPlayerState === PlayerState.NotDeclared)){
                SoundFx.play("beep")
                soundPlayed = true;
            }
            else if(newPlayerState === PlayerState.Show){
                SoundFx.play("winner")
                soundPlayed = true;
            }
            
        }  
        oldPlayerState = newPlayerState;    

        const newPlayersStates : PlayerState[] = [];
        let newCardId: string = "";
        props.game.players.forEach(a => {
            if(a.id !== props.playerId)
                newPlayersStates.push(a.state);
        });

        const lastCard = props.game.previousCards.slice(-1);
        if(lastCard.length > 0){
            newCardId = lastCard[0].id;
        }

        if(!soundPlayed && oldPlayersStates.length && newPlayersStates.length)
        newPlayersStates.find((a,i) => {
            if(a === PlayerState.TurnPicked && oldPlayersStates[i] !== PlayerState.TurnPicked){
                animate(true,true);
                if(oldCardId === newCardId)
                    SoundFx.play("deck-pick")
                else
                    SoundFx.play("card-pick")
                return true;
            }
            else if(a === PlayerState.Waiting && oldPlayersStates[i] === PlayerState.TurnPicked){
                animate(false,true);
                SoundFx.play("release")
                return true;
            }
            else if(a === PlayerState.Drop && oldPlayersStates[i] !== PlayerState.Drop){
                SoundFx.play("drop")
                return true;
            }
            else if(a === PlayerState.Declared && oldPlayersStates[i] !== PlayerState.Declared){
                SoundFx.play("declare")
                return true;
            }
            else if(a !== PlayerState.Ready && oldPlayersStates[i] === PlayerState.Ready){
                SoundFx.play("shuffle")
                return true;
            }
            else if(a === PlayerState.Show && oldPlayersStates[i] !== PlayerState.Show){
                SoundFx.play("beep")
                return true;
            }
            return false;
        })
        
        oldCardId = newCardId;
        oldPlayersStates = newPlayersStates;
        
        const _seatedPlayers = props.game.players.map(a => {
            let info = t("Waiting");
            let active = false;       

        
            switch(a.state){
                case PlayerState.TurnNotPicked:
                    info = `${t("Turn")}(${t("Not Picked")})`; 
                    active = true;
                    break;
                case PlayerState.TurnPicked:
                    info = `${t("Turn")}(${t("Picked")})`; 
                    active = true;
                    break;
                case PlayerState.HostWaiting:
                    info = t("Host");
                    active = true;
                    break;      
                case PlayerState.ShowNotDeclared:
                    info = `${t("Show")}(${t("Not Declared")})`; 
                    active = true;
                    break;       
                case PlayerState.Show:
                    info = t("Winner");
                    if(a.removeNextGame)
                        info+=`(${t("Left Room")})`;
                    active = true;
                    break;   
                case PlayerState.Declared:
                    info = a.score.toString();
                    if(a.removeNextGame)
                        info+= `(${t("Left Room")})`;
                    break; 
                case PlayerState.NotDeclared:
                    info = t("Not Declared");
                    break;  
                case PlayerState.Ready:
                    info = a.score.toString()+(a.removeNextGame?`(${t("Left Room")})`:`(${t("Ready")})`);
                    break;  
                case PlayerState.Drop:
                    info = a.score.toString()+(a.removeNextGame?`(${t("Left Room")},${t("Drop")})`:`(${t("Drop")})`);
                    break;  
            }

            if((props.game?.gameState === GameState.Playing || 
                (props.game?.gameState === GameState.ShowDeclared) ||                
                (props.game?.gameState === GameState.ShowNotDeclared))
                && !a.playing){
                info = t("Spectating");
            }
            
            const sp = new TheSeatedPlayer(a.id,a.name,active,info,a.state);
            sp.dealer = a.dealer;
            sp.super = a.superUser;
            return sp;
        });
        setSeatedPlayers(_seatedPlayers);

        let hostId = props.game.players.find(a => a.state === PlayerState.HostWaiting)?.id;
        let isPlayerHost = hostId? (props.playerId === hostId) : (props.game.players[0].id === props.playerId);
        

        switch(props.game.gameState){
            case GameState.WaitingToStart:{
                Firebase.setScreenName("WaitingToStart");



                setMessage(t("Welcome, waiting for host to start."));

                if(isPlayerHost){
                    setMessage(t("Welcome to Room Id {{roomId}}. ",{roomId: props.roomId})+t("Click Start once all players are in."));
                    setActions([new BtnAction(GameAction.Start,t("Start")), new BtnAction(GameAction.Invite,t("Invite"))]);
                }
                else{
                    setActions([new BtnAction(GameAction.Invite,t("Invite"))]);
                }

                break;
            }
            case GameState.Playing:{
                // summeryShown = false;
                Firebase.setScreenName("Playing");

                setDeckFlash(false);
                setDeckDrop(false);

                if(player?.state ===PlayerState.TurnNotPicked){
                    setMessage(t("Pick a card"));
                    setActions([new BtnAction(GameAction.Drop,t("Drop"))]);
                    setDeckFlash(true);
                }
                else if(player?.state === PlayerState.TurnPicked){

                    setDeckDrop(true);
                    setMessage(t("Discard a card or call show"));
                    setActions([
                        new BtnAction(GameAction.Show,t("Show")),
                        new BtnAction(GameAction.ReleaseCard,t("Discard")) 
                    ]);
                }
                else{
                    let playersTurn = props.game.players.find(a => a.state === PlayerState.TurnNotPicked || a.state === PlayerState.TurnPicked);                    
                    if(playersTurn){
                        setMessage(playersTurn.name +t("'s turn"))
                    }                          
                    setActions([]);
                }  
                break;
            }
            case GameState.ShowNotDeclared:{
                               
                Firebase.setScreenName("ShowNotDeclared");

                setMessage(t("Waiting for show declaration"));
                
                if(player?.state ===PlayerState.ShowNotDeclared){
                    setMessage(t("Group your cards and declare show"))
                    setActions([new BtnAction(GameAction.DeclareShow,t("Declare"))])                
                }

                break;
            }
            case GameState.ShowDeclared:{
  
                Firebase.setScreenName("ShowDeclared");
                
                setMessage(t("Waiting for other player to be ready."));
                
                if(player?.state ===PlayerState.NotDeclared){
                    setMessage(t("Group your cards & declare"))
                    setActions([new BtnAction(GameAction.Declare,t("Declare"))]);                    
                }           
                else{
                    seekReady(player,props);
                }

                break;
            }    
            case GameState.EveryoneDeclared:{
                Firebase.setScreenName("EveryoneDeclared");
                
                seekReady(player,props);

                break;
            }  
        }
    },[props,t,seekReady]);


    const onPlayingDrop = useCallback((card: TheCard) => {

        // console.log("drag rlease card",card);
        const noOfPlayers = props.game?.players.length || 0;
        actionReleaseCard(card,setSelectedCard,props.onGameChange,props.roomId,props.playerId,noOfPlayers,setModHandCards,setModPrevCards,props.game?.myCards,props.game?.previousCards,t)     
    },[props,t])

    const leaveTable = useCallback(async () => {     
        SoundFx.play("click");   
        const r = await gameConfirm(t("Do you want to leave this room?"));
        if(r){           
            setLoader(true);
            destroyCurrentPeerNStream();
            Firebase.logEvent("leave_room",{
                method: "leave",
            });

            httpLoad("/removeplayer/"+props.roomId+"/"+props.playerId)
            .then(() => {                
                // window.location.href = "/";
                setLoader(false);
                history.push("/");
                AppConfig.show_ad_on_room_exit && props.game && (checkIfSuperUserExist(props.game?.players) || showAd(t))
                
            })
        }
    },[props.roomId,props.playerId,history,t,props.game])

    const removePlayer = useCallback(async (player:AudioConnectedPlayer) => {
        SoundFx.play("click");
        const isHost = props.game && props.game.players && props.game.players.length && props.game.players[0].id === props.playerId;
        if(isHost){
            const res = await gameConfirm(<div>{t("Are you sure you want to remove")} <b>{player.name}</b></div>);
            if(res){
                setLoader(true);

              httpLoad("/removeplayer/"+props.roomId+"/"+player.id)
              .then((res) => {    
                setLoader(false);            
                if(res.ok){
                  gameMessage(t("Player has been removed"))
                  return;
                }
                else{
                  gameMessage(t("Unable to process your request"));
                }
                 
              })
            }
          }
          else{
            gameMessage(<div dangerouslySetInnerHTML={{__html: t("host_remove_player")}} ></div>)
          }
    },[props,t])

    
    const onMenuClose = useCallback((menu: "settings" | "room" | "game" | "help") => {
        SoundFx.play("close");
        switch(menu){
            case "settings": 
                setShowSettingsMenu(false)

            break;
            case "room": 
                setShowRoomInfoMenu(false)

            break;
            case "game":
                setShowGameSummryMenu(false)
            break;
            case "help":
                setShowHelpMenu(false)
            break;
        }

    },[])

    const showTheMenu = useCallback((menu: "settings" | "room" | "game" | "help") => {
        SoundFx.play("open");

        switch(menu){
            case "settings": 
                setShowSettingsMenu(true)

            break;
            case "room": 
                setShowRoomInfoMenu(true)

            break;
            case "game": 
                setShowGameSummryMenu(true)
            break;
            case "help":
                setShowHelpMenu(true)
            break;

        }

    },[])

    
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const setAudioReload = useCallback(() => {        

        setAudioRefreshCounter(audioRefreshCounter+1);
        
    },[audioRefreshCounter])

    useEffect(() => {
        if(!props.game)
            return;
        let hostId = props.game.players.find(a => a.state === PlayerState.HostWaiting)?.id;
        let isPlayerHost = (props.playerId === hostId);

        if(isPlayerHost)
            executeOnceGameInfo = false;

        if(executeOnceGameInfo){
            executeOnceGameInfo = false;
            setShowRoomInfoMenu(true);           
        }
    },[props.game,props.playerId])

    useEffect(() => {
        executeOnceGameInfo = true;
    },[])

    const reloadPage = useCallback(() => {
        SoundFx.play("click");
        // allowExit = true;
        // window.location.reload();
        history.go(0);

    },[history]);  

    useEffect(() => {
        const onBackButton = (e:Event) => {
            e.preventDefault();
            const gameModalAvailable = document.querySelector(".modal-dialog.game-modal");
            if(gameModalAvailable){
                // ignore
            }
            else if(showSettingsMenu){
                setShowSettingsMenu(false);
            }
            else{
                leaveTable()
            }
        }

        document.addEventListener("backbutton",onBackButton)

        return () => {
            document.removeEventListener("backbutton",onBackButton);
        }

    },[showSettingsMenu,leaveTable])


    const storeView = () => {
        showPriceMenu(t);
    }

    const toggleSound = () => {
        SoundFx.play("toggle");
        setAllowSound(!allowSound)
    // setData('voice',!allowSound?"1":"0");
    }
    
    const toggleMic = () => {
        SoundFx.play("toggle");        
        if(allowMic){// already on
            setMicSetting(false); 
        }
        else{
            setMicSetting(true);            
        }
    }

    const setMicSetting = (turnOn:boolean) =>{
        if(turnOn){
            if(checkPremium()){
                setAllowMic(true)   
                setAllowSound(true)
            }            
        }
        else{
            setAllowMic(false)   

        }

    }
 
    const getPremium = () => {
        SoundFx.play("click2");
        showPriceMenu(t);
    }

    const checkPremium = useDeviceAssist(
        setIsAndroidUser,
        setIsPremium,
        "playerName",
        isPremium,
        getPremium
    );


    const clearRoomData = useCallback(() => {
        SoundFx.play("click");
        
        const isHost = props.game && props.game.players && props.game.players.length && props.game.players[0].id === props.playerId;

        if(!isHost){
            gameMessage(<div>{t("Only host player can perform this action.")}</div>)
            return;
        }
        gameConfirm(t("Are you sure you want to clear all Game Data?"))
        .then(res => {
            if(res === true){
                setLoader(true)
                httpLoad("/resetroom/"+props.roomId+"/"+props.playerId,{}).finally(() => {
                  setLoader(false);          
                })
            }
        })
    },[props.roomId,props.playerId,props.game,t])

    const [showAdjustTotalModal,setShowAdjustTotalModal] = useState(false);

    const adjustTotalRequest = useCallback(() => {
        SoundFx.play("open");

        const isHost = props.game && props.game.players && props.game.players.length && props.game.players[0].id === props.playerId;

        if(!isHost){
            gameMessage(<div dangerouslySetInnerHTML={{__html: t("Only host player can perform this action.")}} ></div>)
            return;
        }

        setShowAdjustTotalModal(true);
    },[props.game,props.playerId,t]);

    const onSubmitNewAdjustTotals = (totals: any) => {
        setLoader(true)     
        setShowAdjustTotalModal(false);
        SoundFx.play("click");

        httpLoad("/adjusttotal/"+props.roomId+"/"+props.playerId,totals).finally(() => {
            setLoader(false);
        })
    }

    const hideAdjustTotalPopup = () => {
        SoundFx.play("close");
        setShowAdjustTotalModal(false);
    
    }

    const [lastUpdatedValue,setLastUpdate] = useState<number>();
    useEffect(() => {
        const timer = setInterval(() => {
            if(props.lastUpdate){
                const ago = Math.round((Date.now() - props.lastUpdate)/1000);
                setLastUpdate(ago) 
            }
        },3000);

        return () =>{
            clearInterval(timer);
        }
    },[props.lastUpdate]);

    const lastUpdateClass = (lastUpdatedValue && (lastUpdatedValue >= 18? 'level-5' : (lastUpdatedValue >= 15?'level-4':(lastUpdatedValue >= 12?'level-3':(lastUpdatedValue >= 9?'level-2':(lastUpdatedValue >= 6?'level-1':''))))));
    const [userChat,setUserChat] = useState<Chat>();
    
    const onUserChat = (chat: Chat) => {
        setUserChat(chat);
    }

    
    return (
        <div className={'game-page '+ lastUpdateClass } >
            <div className='game-warning show-min-level5' >Your internet connection is down!</div>

            <div className='top-bar' >         
                <div className='dropdown' >
                    {props.game && 
                        <ChatPopup roomId={props.roomId} playerId={props.playerId} players={props.game.players} onUserChat={onUserChat} chatData={props.game.roomChat}  />}
                </div>     
                

                <SeatedPlayers gameState={props.game?.gameState} timeLeft={props.game?.timeLeft} players={seatedPlayers} currentPlayerAudioStatus={allowMic ? "mic" : allowSound ? "sound" : ""} voiceConnections={connectedPlayers} currentPlayer={props.playerId} playerTotals={playerTotals} ></SeatedPlayers>
                
                <div className='dropdown' >
                    {/* <div className='button clickable' onClick={showTheMenu} > */}
                    <div className='button clickable' id="settingsDropdownMenuButton" onMouseUp={() => {SoundFx.play("open")}} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                        <i className="fas fa-list"></i>
                    </div>

                    <div className="dropdown-menu" aria-labelledby="settingsDropdownMenuButton"  >
                        <button className="dropdown-item" onClick={() => showTheMenu("settings")} ><i className='fas fa-cog' style={{opacity:0.5}} ></i> {t("Settings")}</button>
                        <button className="dropdown-item" onClick={() => showTheMenu("room")} ><i className='fas fa-info-circle' style={{opacity:0.5}} ></i> {t("Room Info")}</button>
                        <button className="dropdown-item" onClick={() => showTheMenu("game")} ><i className='fas fa-poll' style={{opacity:0.5}} ></i> {t("Games Summary")}</button>
                        {isAndroidUser && <button className="dropdown-item" onClick={storeView} ><i className='fas fa-store' style={{opacity:0.5}} ></i> {t("View Store")}</button>}
                        <button className="dropdown-item" onClick={reloadPage} ><i className='fas fa-sync' style={{opacity:0.5}} ></i> {t("Reload")}</button>
                        <button className="dropdown-item" onClick={() => showTheMenu("help")} ><i className='fas fa-question-circle' style={{opacity:0.5}} ></i> {t("Support/Feedback")}</button>
                        <button className="dropdown-item" onClick={leaveTable} ><i className='fas fa-sign-out-alt fa-rotate-180' style={{opacity:0.5}} ></i> {t("Leave Room")}</button>
                    </div>
                </div>
            </div>
            
            <div className='hanging-buttons' >
                <button type='button' className='btn' onClick={() => toggleMic()} >
                    {allowMic ? <i className='fas fa-microphone' ></i> : <i className='fas fa-microphone-slash' ></i>}
                </button>
                <button type='button' className='btn' onClick={() => toggleSound()} >
                    {allowSound ? <i className='fas fa-volume-up' ></i> : <i className='fas fa-volume-mute' ></i> }
                </button>
                
            </div>

            <div className='hanging-buttons right' >
                <button type='button' className='btn' onClick={() => showTheMenu("game")} >
                    <i className='fas fa-poll' ></i>
                </button>
                
                    <button id='last-updated-info' type='button' className='btn show-min-level1' onMouseUp={() => {SoundFx.play("open")}} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{fontSize:15}} >
                        <i className="fas fa-exclamation-circle show-min-level1 show-max-level2" ></i>
                        <i className="fas fa-exclamation-triangle show-min-level3" ></i>
                    </button>
                
                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="last-updated-info"  >
                    <button className="dropdown-item" type="button">Your internet connection is slow.</button>
                </div>                    
            </div>

            {(props.game?.gameState !== GameState.ShowDeclared && props.game?.gameState !== GameState.EveryoneDeclared ) ?
                (<PlayingDeck flash={!modPrevCards && deckFlash} deckJoker={props.game?.deckJoker} acceptDrops={deckDrop} cards={modPrevCards ? modPrevCards : props.game?.previousCards ?props.game?.previousCards:[] } onClick={onDeckClick} onDrop={onPlayingDrop} ></PlayingDeck>):
                (
                    <div className='show-deck' >
                        {props.game.players.map(a => (
                            a.declaredCards.length &&
                                <PlayingDeck key={a.id} name={a.name} handCards={a.declaredCards} deckJoker={props.game?.deckJoker} hideClosedCard={true} ></PlayingDeck>
                        ))}
                    </div>
                )
            }

            <StatusBox message={modPrevCards ? t("Updating")+"..." : message} actions={modPrevCards ? [] : actions} onAction={onAction} />
            
            <PlayerHand deckJoker={props.game?.deckJoker} onChange={onPlayerHandChange} cards={modHandCards ? modHandCards : props.game?.myCards?props.game?.myCards:[]} selectedId={selectedCard?.id} onSelect={onCardSelect} ></PlayerHand>
            
            <ChatMessage players={props.game?.players} chatData={props.game?.roomChat} userChat={userChat} ></ChatMessage>
            
            <div className={"card anim-card"} ></div>


            {showSettingsMenu && <SettingsMenu 
                show={showSettingsMenu}
                sound={allowSound}
                mic={allowMic} 
                setAllowSound={setAllowSound}
                setAllowMic={setMicSetting}
                onCloseClick={() => onMenuClose("settings")}              
            />}

            {showRoomInfoMenu && <RoomInfoMenu 
                roomId={props.roomId}
                show={showRoomInfoMenu}
                timer={props.game?.timer}
                deckJoker={props.game?.deckJoker ? true : false}
                jokerCount={props.game?.jokerCount}
                audioConnectedPlayers={connectedPlayers}
                onCloseClick={() => onMenuClose("room")} 
                onRemovePlayerClick={removePlayer}       
                minimumDecks={props.game?.minimumDecks}         
                dropPoints={props.game?.dropPoints}         
                middlePoints={props.game?.middlePoints}         
                poolRummy={props.game?.gameType === GameType.Pool}         
                poolPoints={props.game?.poolPoints}         
                reEntry={props.game?.reEntry}         
            />}

            {showGameSummeryMenu && <GameSummeryMenu                 
                show={showGameSummeryMenu}
                onCloseClick={() => onMenuClose("game")} 
                roomHistory={props.game?.roomHistory || []}
                historyPlayers={historyPlayers}
                playerTotals={playerTotals}
                onPointsStyleChange={setTotalStyle}
                onClearRoomRequest={clearRoomData}
                onAdjustTotalRequest={adjustTotalRequest}
                poolPoints={props.game?.gameType === GameType.Pool ? props.game?.poolPoints : undefined}        
                roomType={props.game?.type}
            />}

            {showHelpMenu && <HelpMenu show={showHelpMenu} onCloseClick={() => onMenuClose("help")} onRequestReload={reloadPage} />}
                
            {showAdjustTotalModal &&
                <AdjustTotalsModal
                    playerTotals={playerTotals} 
                    onSubmitNewAdjustTotals={onSubmitNewAdjustTotals} 
                    players={props.game?.players}
                    hideAdjustTotalPopup={hideAdjustTotalPopup}
                />}
            
        </div>
    )
}