import Store, { ProductDetails, Products } from "../Phonegap/Store";
import Ads from "../Phonegap/Ads/Ads";
import AppConfig from "../Config";
import { isAndroid } from "./Device";
import { gameConfirm, gameMessage, gamePopup } from "./Popups";
import React from "react";
import { TFunction } from "i18next";
import { Player } from "./Models";
import SoundFx from "./SoundFx";
import { Accordion } from "react-bootstrap";

export interface PremiumMeta {
   price: string;
   title: string;
   description: string;
}

export function isPremiumUser() {
   if (AppConfig.debug && AppConfig.force_no_purchase)
      return false;

   return Store.isOwned(Products.unlock_premium) || Store.isOwned(Products.super_premium) || Store.isOwned(Products.no_ads) || Store.isOwned(Products.no_ads_only_sub) ;
}

export function isNoAdsUser() {
   if (AppConfig.debug && AppConfig.force_no_purchase)
      return false;

   return Store.isOwned(Products.no_ads_only) || Store.isOwned(Products.super_premium) || Store.isOwned(Products.no_ads) || Store.isOwned(Products.no_ads_only_sub);

}

export function isSuperUser() {
   if (AppConfig.debug && AppConfig.force_no_purchase)
      return false;

   // return true;
   return Store.isOwned(Products.super_premium);

}


export function getPremiumMeta(item:Products) {
   return Store.getInfo(item);
}

// export function purchasePremium() {
//    Store.orderItem();
// }


export function showPriceMenu(t:TFunction){

   const data = {
      noAdsOnly: Store.getInfo(Products.no_ads_only),
      premium: Store.getInfo(Products.unlock_premium),
      noAds: Store.getInfo(Products.no_ads),
      superPremium: Store.getInfo(Products.super_premium),
      subscription: Store.getInfo(Products.no_ads_only_sub)
   }  

   const menuClick = (item: Products) => {
      
      SoundFx.play("click2");

      if(!AppConfig.debug || !AppConfig.force_no_purchase){

   
         if(item === Products.no_ads_only_sub){
            if(data.noAds?.owned || data.subscription?.owned){ 
               gameMessage(t("You already own this item."))
               return;
            }
            else if(data.noAdsOnly?.owned && data.premium?.owned){ 
            gameMessage(t("no_benefit_premium",{no_ads_only:data.noAdsOnly?.title,unlock_premium: data.premium?.title}))
            return;
            }
            else if(data.superPremium?.owned){ 
               gameMessage(t("no_benefit_super",{super_premium:data.superPremium?.title}))
               return;
            }
            
         }
         else if(item === "no_ads"){
            if(data.noAds?.owned || data.subscription?.owned){ 
               gameMessage(t("You already own this item."))
               return;
            }
            else if(data.noAdsOnly?.owned && data.premium?.owned){ 
              gameMessage(t("no_benefit_premium",{no_ads_only:data.noAdsOnly?.title,unlock_premium: data.premium?.title}))
              return;
            }
            else if(data.superPremium?.owned){ 
               gameMessage(t("no_benefit_super",{super_premium:data.superPremium?.title}))
               return;
            }
            
         }
         // else if(item === "super_premium"){
         //    if(data.superPremium?.owned){ 
         //       gameMessage(t("You already own this item."))
         //       return;
         //    }
         // }
         // else if(item === "no_ads_only"){
         //    if(data.noAdsOnly?.owned){ 
         //       gameMessage(t("You already own this item."))
         //       return;
         //    }
         //    else if(data.noAds?.owned){ 
         //       gameMessage(t("no_benefit_no_ads",{no_ads: data.noAds?.title}))
         //       return;
         //    }
         //    else if(data.superPremium?.owned){ 
         //       gameMessage(t("no_benefit_super",{super_premium: data.superPremium?.title}))
         //       return;
         //    }
         // }
         // else if(item === "unlock_premium"){
         //    if(data.premium?.owned ){ 
         //       gameMessage(t("You already own this item."))
         //       return;
         //    }
         //    else if(data.noAds?.owned){ 
         //       gameMessage(t("no_benefit_no_ads",{no_ads: data.noAds?.title}))
         //       return;
         //    }
         //    else if(data.superPremium?.owned){ 
         //       gameMessage(t("no_benefit_super",{super_premium: data.superPremium?.title}))
         //       return;
         //    }
         // }
      }
      Store.orderItem(item)
   }

   const payTMClick = () => {
      SoundFx.play("click2");

      const url = 'https://blog.paytm.com/now-recharge-your-google-play-account-with-paytm-1a927b44476a';
      window.open(url, '_system'); 
 
   }

   const manageSubscriptions = () => {
      Store.manageSubscriptions();
   }

   const getPricing = (item: ProductDetails) => {
      // @ts-ignore
      if(AppConfig.pricing && AppConfig.pricing[item.id] &&  AppConfig.pricing[item.id].discounts){
         // @ts-ignore
         for(var i in AppConfig.pricing[item.id].discounts){
             // @ts-ignore
            const a = AppConfig.pricing[item.id].discounts[i];

            if(a.currency === item.currency)
               return <span><s>{a.value}</s> {item.price}</span>;
         }
      }

      return <span>{item.price}</span>;
   }

   const isActive = (item: ProductDetails) : boolean => {
      // @ts-ignore
      if(AppConfig.pricing && AppConfig.pricing[item.id]){
         // @ts-ignore
         return AppConfig.pricing[item.id].active;
      }

      return true;
   }

   return gamePopup(t("Purchase"),(      
      <div className='price-list'>
         {AppConfig.price_menu_text && (data.subscription && data.subscription.owned ? <p>You are a Premium User.</p> : <p>{AppConfig.price_menu_text}</p> )}   
         

         <div className='row no-gutters justify-content-center' >
            {data.subscription && isActive(data.subscription) && 
            <div className='col-md-4' >
               <div className={'box box1 ' +(data.subscription.owned?'owned':'')} onClick={() => menuClick(Products.no_ads_only_sub)} >
                  <div className='title' ><i className='far fa-heart' ></i> {data.subscription.title}</div>
                  <div className='desc' >{data.subscription.description}</div>            
                  <div className='price' >
                     {getPricing(data.subscription)}
                     <small>{data.subscription.billingPeriodUnit !== undefined && "/"+data.subscription.billingPeriodUnit}</small>
                  </div>            
               </div>
            </div>}

            {data.noAds &&  isActive(data.noAds) &&
            <div className='col-md-4' >
               <div className={'box box3 ' + (data.noAds.owned?'owned':'')} onClick={() => menuClick(Products.no_ads)} >
                  <div className='title' ><i className='far fa-heart' ></i> {data.noAds.title}</div>
                  <div className='desc' >{data.noAds.description}</div>            
                  <div className='price' >
                     {getPricing(data.noAds)}                     
                  </div>            

               </div>
            </div>}
         </div>

         {/* <div className='row no-gutters justify-content-center' >
            {data.noAdsOnly && isActive(data.noAdsOnly) &&
            <div className='col-md-4' >
               <div className={'box box1 ' +(data.noAdsOnly.owned?'owned':'')} onClick={() => menuClick(Products.no_ads_only)} >
                  <div className='title' ><i className='fas fa-ban' ></i> {data.noAdsOnly.title}</div>
                  <div className='desc' >{data.noAdsOnly.description}</div>            
                  <div className='price' dangerouslySetInnerHTML={{__html: getPricing(data.noAdsOnly) }} ></div>            
               </div>
            </div>
            }           
            {data.noAds &&  isActive(data.noAds) &&
            <div className='col-md-4' >
               <div className={'box box3 ' + (data.noAds.owned?'owned':'')} onClick={() => menuClick(Products.no_ads)} >
                  <div className='title' ><i className='far fa-heart' ></i> {data.noAds.title}</div>
                  <div className='desc' >{data.noAds.description}</div>            
                  <div className='price' dangerouslySetInnerHTML={{__html: getPricing(data.noAds) }} ></div>            

               </div>
            </div>
            }
            {data.superPremium &&  isActive(data.superPremium) &&
            <div className='col-md-4' >
               <div className={'box box4 ' + (data.superPremium.owned?'owned':'')} onClick={() => menuClick(Products.super_premium)} >
                  <div className='title' ><i className='fab fa-superpowers' ></i> {data.superPremium.title}</div>
                  <div className='desc' >{data.superPremium.description}</div>            
                  <div className='price' dangerouslySetInnerHTML={{__html: getPricing(data.superPremium) }} ></div>            

               </div>
            </div>
            }
         </div> */}
         <hr />
         <Accordion className='the-accordion price-accord'  >
            <div className='the-group' >
                <Accordion.Toggle onClick={() => SoundFx.play("open")} className="the-toggle" eventKey="0" >{t("Need Help?")} <i className='fas fa-chevron-down the-icon' ></i></Accordion.Toggle>
                <Accordion.Collapse eventKey="0" >
                  <Accordion className='the-accordion price-sub-accord'  >
                     <div className='the-group' >
                        <Accordion.Toggle onClick={() => SoundFx.play("open")} className="the-toggle" eventKey="1" >{t("Pay with PayTM")} <i className='fas fa-chevron-down the-icon' ></i></Accordion.Toggle>
                        <Accordion.Collapse eventKey="1" >
                           <p>
                              <p dangerouslySetInnerHTML={{ __html: t("pay_with_paytm") }}  ></p>          
                              <button onClick={payTMClick} className='btn btn-block btn-secondary d-md-none' type='button'>{t("Know How")}</button>
                              <button onClick={payTMClick} className='btn btn-block btn-secondary d-none d-md-block' type='button'>{t("Know How")}</button>
                           </p>
                        </Accordion.Collapse>
                     </div>      
                     <div className='the-group' >
                        <Accordion.Toggle onClick={() => SoundFx.play("open")} className="the-toggle" eventKey="2" >{t("Manage your subscriptions")} <i className='fas fa-chevron-down the-icon' ></i></Accordion.Toggle>
                        <Accordion.Collapse eventKey="2" >
                           <p>
                              <p>You can manage your billing and subscriptions using the button below.</p>          
                              <button onClick={manageSubscriptions} className='btn btn-block btn-secondary d-md-none' type='button'>{t("Subscriptions")}</button>
                           </p>
                        </Accordion.Collapse>
                     </div>            
                  </Accordion>
                          
                </Accordion.Collapse>
            </div>
         </Accordion>
      </div>
   ),{size: "xl"});
}

export function showInterstitial() {
   return Ads.showInterstitial();
}

export function showRewardVideo() {
   return Ads.showRewardVideo();
}

export function checkVideoAvailable() {
   return Ads.isRewardVideoAvailable();
}


export function checkIfSuperUserExist(players: Player[]) {   
   const superUser = players.find(a => a.superUser);
   return superUser?true:false;
}

function loadingAdScreen(t:TFunction,skipCounter?:boolean){
   return new Promise<void>((res) => {
      const p = gameConfirm((
         <div style={{textAlign:"left"}} >
            <p>Spare a moment for our sponsers.</p>
            <p>
               You may also <b>remove ads</b> by making any purchase from our store.
            </p>
            
         </div>),{
            button_text1:"Show Ad",
            button_text2:"View Store",
            closeButton:false,
            title:"View Ad", 
            autoApproveIn:skipCounter ? undefined : 5, 
            preventSkip:true 
         });

      p.then((val) => {
         if(val === true){ // show ad
            res();
         }
         else if(val === null){
            loadingAdScreen(t,true).then(() => {
               res();
            })
         }
         else{
            showPriceMenu(t).then(() => {
               if(isNoAdsUser()){
                  res();
               }
               else{
                  loadingAdScreen(t,true).then(() => {
                     res();
                  })
               }               
            })
         }
      })
   });
}

export function showAd(t: TFunction){
   return new Promise<void>((proceed_callback) => {
      
      if (isAndroid() && !isNoAdsUser()) {
         loadingAdScreen(t).then(() => {
            if (AppConfig.show_reward_video_ads && checkVideoAvailable()) {         
               const p = showRewardVideo();
      
               if(p){
                  p.then(() => {
                     proceed_callback();
                  }, () => {
                     console.log("rewarddsddssd error");
                     proceed_callback();
                  })
                  return;
               }
            }
            else if (AppConfig.show_interstitial_if_no_reward) {
               const p = showInterstitial()
               if(p){
                  p.then(() => {
                     proceed_callback();
                  }, (e) => {
                     console.log("interstitial error");
                     proceed_callback();
                  })
                  return;
               }
               
            }
         })  
      }    

      proceed_callback();
   });   
}