import Modal from "react-bootstrap/Modal";
import React, { useCallback } from "react";
import { JokerCountType, AudioConnectedPlayer, AudioConnectedPlayerStatus } from "../../Helpers/Models";
import { useTranslation } from 'react-i18next';

interface Props{
  roomId: string;
  show: boolean;
  onCloseClick: (() => void);
  audioConnectedPlayers: AudioConnectedPlayer[];
  onRemovePlayerClick: ((player:AudioConnectedPlayer) => void),
  numberOfJokers?: number,
  jokerCount?: JokerCountType,
  timer?: number,
  deckJoker?: boolean,
  minimumDecks?: number,
  dropPoints?: number,
  middlePoints?: number,
  poolRummy? :boolean,
  poolPoints? : number,
  reEntry?: boolean
}


export default function RoomInfoMenu({
  audioConnectedPlayers,
  show,
  onCloseClick, 
  onRemovePlayerClick,
  roomId,
  jokerCount,
  numberOfJokers,  
  timer,
  deckJoker,
  minimumDecks,
  dropPoints,
  middlePoints,
  poolPoints,
  poolRummy,
  reEntry
}: Props){

  const { t } = useTranslation();

  const getIconClass = useCallback((player: AudioConnectedPlayer) => {
    switch(player.status){
      case AudioConnectedPlayerStatus.micconnected:
        return "fa-microphone"
      case AudioConnectedPlayerStatus.connected:
        return "fa-volume-up"
      case AudioConnectedPlayerStatus.connecting:
        return "fa-volume-down"
      default: 
        return "fa-volume-mute"
    }
  },[]);

  const numberOfDecksUsed = useCallback((players:number) => {
    
    let numberOfDecks =  1;// 1 or 2

    if(players > 9){
        numberOfDecks = Math.ceil(players / 2);
    }      
    else if(players> 6){// 7-9
        numberOfDecks = 3;
    }   
    else if(players > 2){// 3-6
        numberOfDecks = 2;
    }

    if(minimumDecks && minimumDecks > numberOfDecks)
      numberOfDecks = minimumDecks;

    return numberOfDecks;
  },[minimumDecks]);

  return (
    <>
      {show && <Modal show={show}  animation={false}  onHide={onCloseClick} size="sm" centered>
        <Modal.Header closeButton>{t("Room Info")}</Modal.Header>
        <Modal.Body>
          <div className='form-group menu'>           
            <div className='the-info' >
                            
              <table className='table table-bordered room-info' >
                <tbody>
                  <tr>
                    <th colSpan={2} >{t("Room")}</th>
                  </tr>
                  <tr><td><b>{t("Room Id")}</b>: </td><td>{roomId}</td></tr>
                  <tr><td><b>{t("Turn Timer")}</b>: </td><td>{(timer && timer > 0 )? t(timer+"s"): t("No Timer")}</td></tr>

                  <tr>
                    <th colSpan={2} >{t("Game")}</th>
                  </tr>
                  <tr><td><b>{t("Game Type")}</b>: </td><td>{poolRummy?t("Syndicate"):t("Free play")}</td></tr>
                  {poolRummy && (
                    <>
                      <tr><td><b>{t("Points")}</b>: </td><td>{poolPoints}</td></tr>
                      <tr><td><b>{t("Re-Entry")}</b>: </td><td>{reEntry?t("Allowed"):t("Not Allowed")}</td></tr>
                    </>
                  )}

                  <tr>
                    <th colSpan={2} >{t("Deck")}</th>
                  </tr>
                  <tr><td><b>{t("Decks Used")}</b>: </td><td>{numberOfDecksUsed(audioConnectedPlayers.length)}</td></tr>
                  <tr><td><b>{t("Cut Joker")}</b>: </td><td>{deckJoker?t("Yes"):t("No")}</td></tr>
                  <tr><td><b>{t("Paper Joker")}</b>: </td><td>{numberOfJokers} {jokerCount === JokerCountType.PerGame?t("per game"):t("per deck")}</td></tr>
                  <tr>
                    <th colSpan={2} >{t("Points")}</th>
                  </tr>
                  <tr><td><b>{t("Drop Points")}</b>: </td><td>{dropPoints}</td></tr>
                  <tr><td><b>{t("Middle Points")}</b>: </td><td>{middlePoints}</td></tr>                  
                  

                </tbody>         
              </table>

              <hr />
              <div className='subtitle' >{t("Players In Room")}:</div>
              <div>

                  <ul className="list-group audio-info">
                    {audioConnectedPlayers.map((a,i) => (
                      <li key={i} className='list-group-item d-flex justify-content-between align-items-center'>
                        <span>
                        {a.name} {a.host && <i style={{color:"#aaa"}} title='Host' className='fas fa-crown' ></i>} <span style={{color:"#aaa"}} className={"fas "+getIconClass(a)} ></span>
                        </span>
                        <span>
                          
                          <span onClick={(e) => {e.preventDefault(); onRemovePlayerClick(a)}} className={"clickable badge badge-pill badge-warning"}>{t("Remove")}</span>
                        </span>
                      </li>

                    ))}
                  </ul>              
                
              </div> 
            </div>    
    
          </div>
        </Modal.Body>
      </Modal>}
    </>
  )
}