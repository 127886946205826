import { Modal, Button } from "react-bootstrap"
import React, { useState, useEffect, useCallback } from "react"
import SoundFx from "../../Helpers/SoundFx"
import ToggleSwitch from "../../Components/ToggleSwitch"
import { getData, setData } from "../../Helpers/Storage";
import { useHistory } from "react-router-dom";
import { setLoader } from "../../Helpers/Helper";
import { isPremiumUser, isSuperUser } from "../../Helpers/Profits";
import Firebase from "../../Phonegap/Firebase";
import { httpLoad } from "../../Helpers/Http";
import { gameMessage } from "../../Helpers/Popups";
import ReactGA from 'react-ga';
import { useTranslation } from "react-i18next";
import { RoomOptions, JokerCountType, GameType, SelectOption } from "../../Helpers/Models";
import Select from 'react-select';

interface TheProps{
    showNewRoomOptions: boolean;
    playerName: string;
    checkPremium: (() => boolean);
    onCloseRequest: (() => void)
}
const defaultRoomOptions = {
    numberOfJokers: "1",
    openJoker: true,
    paperJoker:true,
    timerOn: false,
    jokerCount: JokerCountType.perDeck,
    timer: 0,
    decksCount:0,
    dropPoints: 20,
    middlePoints: 40,
    poolRummy: false,
    poolPoints: 101,
    reEntryAllowed: false,
}
let premium = false;

export default function CreateRoomX({showNewRoomOptions, playerName, checkPremium, onCloseRequest} : TheProps){

    const [numberOfJokers,setNumberOfJokers] = useState(defaultRoomOptions.numberOfJokers);
    const [openJoker,setOpenJoker] = useState(defaultRoomOptions.openJoker);
    const [paperJoker,setPaperJoker] = useState(defaultRoomOptions.paperJoker);    
    const [timerOn,setTimerOn] = useState(defaultRoomOptions.timerOn);    
    const [jokerCount,setJokerCount] = useState<JokerCountType>(defaultRoomOptions.jokerCount);
    const [showAdv,setShowAdv] = useState(false);
    const history = useHistory();
    const [timer,setTimer] = useState(defaultRoomOptions.timer);
    const [decksCount,setDecksCount] = useState(defaultRoomOptions.decksCount);
    const [dropPoints,setDropPoints] = useState(defaultRoomOptions.dropPoints);
    const [middlePoints,setMiddlePoints] = useState(defaultRoomOptions.middlePoints);
    const { t } = useTranslation();

    const [poolRummy,setPoolRummy] = useState(defaultRoomOptions.poolRummy);
    const [reEntryAllowed,setReEntryAllowed] = useState(defaultRoomOptions.reEntryAllowed);
    const [poolPoints,setPoolPoints] = useState(defaultRoomOptions.poolPoints);

    useEffect(() => {
        premium = isPremiumUser() || false;
    },[])


    const onNewRoomOptionsSubmit = useCallback(async (e: React.FormEvent<HTMLFormElement>) =>{
        e.preventDefault();
        SoundFx.play("click")
        
        setLoader(true);
        const options :RoomOptions = {
            playerName: playerName,
            numberOfJokers: paperJoker?parseInt(numberOfJokers):0,
            jokerCount: jokerCount,
            deckJoker: openJoker,
            timer: timer,
            gameOn: isSuperUser()?true:undefined,
            minDecks: decksCount,
            dropPoints: dropPoints,
            middlePoints: middlePoints,
            gameType: poolRummy ? GameType.Pool : GameType.Free,
            poolPoints: poolPoints,
            reEntry: reEntryAllowed,
        }

        setData("numberOfJokers",paperJoker?numberOfJokers:"0")
        setData("jokerCount",jokerCount)
        setData("openJoker",openJoker?"1":"0")
        setData("timer",timer.toString())
        setData("decks",decksCount.toString())
        setData("dropPoints",dropPoints.toString())
        setData("middlePoints",middlePoints.toString())
        setData("poolRummy",(poolRummy?"1":"0").toString() )
        setData("poolPoints",poolPoints.toString())
        setData("reEntry",reEntryAllowed ? "1": "0")

        ReactGA.set({
            "dimension4":openJoker.toString(),
            "dimension3":numberOfJokers.toString()
        });

        Firebase.logEvent("enter_room",{
            method: "join",
            paper_jokers: options.numberOfJokers.toString(),
            deck_joker: options.deckJoker.toString(),
            
        });

        // console.log("options",options);

        
        const res = await httpLoad("/newroom/",options);
        setLoader(false);
        if(res.ok){            
            const [pid,rid] = res.data;

            setData("last-pid",pid);
            setData("last-roomid",rid);    


            // window.location.href = "/room/"+rid+"/"+pid+'?u='+uuid();
            history.push("/room/"+rid+"/"+pid);
        }
        else{
            gameMessage(res.data)
        }
    },[decksCount, poolPoints,poolRummy,reEntryAllowed, dropPoints, history, jokerCount, middlePoints, numberOfJokers, openJoker, paperJoker, playerName, timer])

  
    const onJokerChange = useCallback((newval) => {
        SoundFx.play("toggle")
        setNumberOfJokers((newval.value));
    },[])

    const onJokerCountChange = useCallback((newval) => {
        SoundFx.play("toggle")
        switch(newval.value){
            case "per-deck": 
                setJokerCount(JokerCountType.perDeck)
                break;
            case "per-game":
                setJokerCount(JokerCountType.PerGame)
                break;
        }
        
    },[])

    const onOpenJokerChange = useCallback((newVal :boolean) => {
        SoundFx.play("toggle")
        setOpenJoker(newVal);
    },[])


    const onPaperJokerChange = useCallback((newVal :boolean) => {
        SoundFx.play("toggle")
        setPaperJoker(newVal)
    },[])


    const onTimerChange = useCallback((newval) => {
        SoundFx.play("toggle")
        setTimer(parseInt(newval.value));
    },[])

    
    const onTurnTimerChange = useCallback((on: boolean) => {
        SoundFx.play("toggle")
        setTimerOn(on);
        setTimer(on?60:0);
    },[])

    
    const onDecksChange = useCallback((newval) => {
        SoundFx.play("toggle")
        setDecksCount(parseInt(newval.value));
    },[])

    const onDropPointsChange = useCallback((newval) => {
        SoundFx.play("toggle")
        setDropPoints(parseInt(newval.value));
    },[])

    const onMiddlePointsChange = useCallback((newval) => {
        SoundFx.play("toggle")
        setMiddlePoints(parseInt(newval.value));
    },[])

    useEffect(() => {
        // loading previous room values
        const _numberOfJokers = getData("numberOfJokers")
        const _jokerCount = getData("jokerCount")
        const _openJoker = getData("openJoker")
        const _timer = getData("timer")
        const _decks = getData("decks")
        const _dropPoints = getData("dropPoints")
        const _middlePoints = getData("middlePoints")
        const _poolRummy = getData("poolRummy")
        const _poolPoints = getData("poolPoints")
        const _reEntry = getData("reEntry")

        if(_numberOfJokers){
            if(_numberOfJokers === "0"){
                setPaperJoker(false)                
            }
            else{
                setNumberOfJokers(_numberOfJokers);
                if(defaultRoomOptions.numberOfJokers !== _numberOfJokers){
                    setShowAdv(true)
                }
            }
        }

        if(_jokerCount){
            switch(_jokerCount){
                case "per-deck": 
                    setJokerCount(JokerCountType.perDeck)
                    if(defaultRoomOptions.jokerCount !== JokerCountType.perDeck){
                        setShowAdv(true)
                    }
                    break;
                case "per-game":
                    setJokerCount(JokerCountType.PerGame)
                    if(defaultRoomOptions.jokerCount !== JokerCountType.PerGame){
                        setShowAdv(true)
                    }
                    break;
            }
        }

        if(_openJoker){
            setOpenJoker(_openJoker === "1"?true:false);
        }

        if(_decks){
            setDecksCount(parseInt(_decks));
            if(defaultRoomOptions.decksCount !== parseInt(_decks)){
                setShowAdv(true)
            }
        }

        if(_timer){
            setTimer(parseInt(_timer))
            if(defaultRoomOptions.timer !== parseInt(_timer)){
                setShowAdv(true)
            }

            if(_timer === "0")
                setTimerOn(false);
            else
                setTimerOn(true);
        }

        
        if(_dropPoints){
            setDropPoints(parseInt(_dropPoints));
            if(defaultRoomOptions.dropPoints !== parseInt(_dropPoints)){
                setShowAdv(true)
            }
        }

        if(_middlePoints){
            setMiddlePoints(parseInt(_middlePoints));
            if(defaultRoomOptions.middlePoints !== parseInt(_middlePoints)){
                setShowAdv(true)
            }
        }

        if(_poolRummy){
            setPoolRummy(_poolRummy === "1"?true:false);
        }

        if(_poolPoints){
            setPoolPoints(parseInt(_poolPoints));
        }

        if(_reEntry){
            setReEntryAllowed(_reEntry === "1"?true:false);
        }

    },[])



    const onPoolRummyChange = useCallback((newVal :boolean) => {
        SoundFx.play("toggle")
        setPoolRummy(newVal)
    },[])

    const onReEntryChange = useCallback((newVal :boolean) => {
        SoundFx.play("toggle")

        if(checkPremium())
            setReEntryAllowed(newVal);

        console.log("reEntryAllowed",newVal);
    },[checkPremium])

    const onPoolPointsChange = useCallback((newval : any) => {
        SoundFx.play("toggle")
        setPoolPoints(parseInt(newval.value));

    },[]);

    
    const jokersOption: SelectOption[] = [
        new SelectOption("1","1"),
        new SelectOption("2","2 (PREMIUM)",!premium),
        new SelectOption("3","3"),
        new SelectOption("4","4"),
        new SelectOption("5","5"),
    ];

    const syndicateOptions: SelectOption[] = [
        new SelectOption("100"),
        new SelectOption("101"),
        new SelectOption("150","150 (PREMIUM)",!premium),
        new SelectOption("151","151 (PREMIUM)",!premium),
        new SelectOption("200"),
        new SelectOption("201"),
        new SelectOption("250","250 (PREMIUM)",!premium),
        new SelectOption("251","251 (PREMIUM)",!premium),
        new SelectOption("300"),
        new SelectOption("301"),
        new SelectOption("400"),
        new SelectOption("401"),
        new SelectOption("500"),
        new SelectOption("501"),
    
    ];

    const jokersCountOptions = [
        new SelectOption("per-game","per game"),
        new SelectOption("per-deck","per deck"),
    ];

    const timerOptions = [
        new SelectOption("15","15s"),
        new SelectOption("30","30s (PREMIUM)",!premium),
        new SelectOption("45","45s (PREMIUM)",!premium),
        new SelectOption("60","60s"),
        new SelectOption("90","90s"),
        new SelectOption("120","120s"),
        new SelectOption("180","180s"),
    ];

    const decksCountOptions = [
        new SelectOption("0","Auto"),
        new SelectOption("2","Minimum 2"),
        new SelectOption("3","Minimum 3 (PREMIUM)",!premium),
        new SelectOption("4","Minimum 4 (PREMIUM)",!premium),
        new SelectOption("5","Minimum 5"),
        new SelectOption("6","Minimum 6"),
    ];

    const dropPointOptions = [
        new SelectOption("0"),
        new SelectOption("5"),
        new SelectOption("10"),
        new SelectOption("15"),
        new SelectOption("20"),
        new SelectOption("25","25 (PREMIUM)",!premium),
        new SelectOption("30"),
        new SelectOption("40","40 (PREMIUM)",!premium),
        new SelectOption("50"),
        new SelectOption("60"),
        new SelectOption("70"),
        new SelectOption("80"),        
    ]

    const middlePointsOptions = [
        new SelectOption("0"),
        new SelectOption("5"),
        new SelectOption("10"),
        new SelectOption("15"),
        new SelectOption("20"),
        new SelectOption("25"),
        new SelectOption("30","30 (PREMIUM)",!premium),
        new SelectOption("40"),
        new SelectOption("50","50 (PREMIUM)",!premium),
        new SelectOption("60"),
        new SelectOption("70"),
        new SelectOption("80"),        
    ]

    return (
        <>
            {showNewRoomOptions && <Modal className="modal-popup" size="lg" show={showNewRoomOptions} centered onHide={() => {onCloseRequest && onCloseRequest()}}  >
                <Modal.Header closeButton>{t("Room Options")}</Modal.Header>
                <form onSubmit={onNewRoomOptionsSubmit}>
                    <Modal.Body>
                        <div style={{fontSize:"22px"}}>
                            <div className='row'>
                                <div className='col-sm' >
                                    <ToggleSwitch label={t('Syndicate')} value={poolRummy} onChange={onPoolRummyChange} />
                                </div>    
                                {poolRummy && <div className='col-sm' >
                                    <ToggleSwitch label={t('Re-Entry*')} value={reEntryAllowed} onChange={onReEntryChange} />
                                </div>}
                            </div>

                            {poolRummy && (
                                <>
                                    <hr />
                                    <p>{t("Syndicate Points")}:</p>

                                    <Select 
                                        onChange={onPoolPointsChange}                                                        
                                        options={syndicateOptions}                                        
                                        isOptionDisabled={(o) => o.disabled}
                                        defaultValue={syndicateOptions.find(a => a.value === poolPoints.toString())}
                                        isSearchable={false}
                                    />
    
                                </>
                            )}
                           
                            <hr />

                            <div className='row'>
                                <div className='col-sm' >
                                    <ToggleSwitch label={t('Paper Joker')} value={paperJoker} onChange={onPaperJokerChange} />
                                </div>    
                                <div className='col-sm' >
                                    <ToggleSwitch label={t('Cut Joker')} value={openJoker} onChange={onOpenJokerChange} />                                   
                                </div>    


                                <div className='col-lg' >
                                    <hr className='d-lg-none' />

                                    <ToggleSwitch label={t('Turn Timer')} value={timerOn} onChange={onTurnTimerChange} />
                                </div>   
                            </div>
                            
                       
                        
                            <div className='' >
                                <hr />

                                {!showAdv ? (
                                    <button onClick={() => SoundFx.play("click2") && setShowAdv(true)} type='button' className='btn btn-link' >{t("Show Advanced Options")} <i className="fas fa-angle-double-down"></i></button>
                                ) : (
                                    <>
                                        {paperJoker && (
                                            <>
                                                <p>{t("Number of paper jokers")}:</p>
                                                <div className='row' >
                                                    <Select 
                                                        onChange={onJokerChange}                                                        
                                                        options={jokersOption}
                                                        className="col-6"
                                                        isOptionDisabled={(o) => o.disabled}
                                                        defaultValue={jokersOption.find(a => a.value === numberOfJokers)}
                                                        isSearchable={false}
                                                    />

                                                    <Select 
                                                        onChange={onJokerCountChange}                                                        
                                                        options={jokersCountOptions}
                                                        isOptionDisabled={(o) => o.disabled}
                                                        className="col-6"
                                                        defaultValue={jokersCountOptions.find(a => a.value === jokerCount.toString())}
                                                        isSearchable={false}
                                                    />
                                                                   
                                                </div>
                                                <hr />

                                            </>
                                        )}
                                        
                                        
                                        {timerOn && (
                                            <>
                                                <p>{t("Turn timer options")}:</p>

                                                <Select 
                                                    onChange={onTimerChange}                                                        
                                                    options={timerOptions}
                                                    isOptionDisabled={(o) => o.disabled}
                                                    defaultValue={timerOptions.find(a => a.value === timer.toString())}
                                                    isSearchable={false}
                                                />    
                                                <hr />

                                            </>
                                        )}

                                        <div className='row' >
                                            <div className='col-lg' >
                                                <p>{t("Number of decks")}:</p>
                                                <Select 
                                                    onChange={onDecksChange}                                                        
                                                    options={decksCountOptions}
                                                    isOptionDisabled={(o) => o.disabled}
                                                    defaultValue={decksCountOptions.find(a => a.value === decksCount.toString())}
                                                    isSearchable={false}
                                                />
                                                <hr className='d-lg-none' />    
                                               
                                            </div>
                                            <div className='col-sm' >
                                                <p>{t("Drop Points")}:</p>
                                                
                                                <Select 
                                                    onChange={onDropPointsChange}                                                        
                                                    options={dropPointOptions}
                                                    isOptionDisabled={(o) => o.disabled}
                                                    defaultValue={dropPointOptions.find(a => a.value === dropPoints.toString())}
                                                    isSearchable={false}
                                                />
                                            </div>
                                            <div className='col-sm' >
                                                <p>{t("Middle Points")}:</p>
                                                <Select 
                                                    onChange={onMiddlePointsChange}                                                        
                                                    options={middlePointsOptions}
                                                    isOptionDisabled={(o) => o.disabled}
                                                    defaultValue={middlePointsOptions.find(a => a.value === middlePoints.toString())}
                                                    isSearchable={false}
                                                />
                                            </div>
                                        </div>
                                    </>
                                )}
                                

                            </div>
                        
                        </div>
                    </Modal.Body>

                    <Modal.Footer >
                        <Button variant="secondary"  size="lg" block className='min-width' type='submit' >{t("Create Room")}</Button>
                    </Modal.Footer>
                </form>
            </Modal>}
        </>
    )
}