import React, { useEffect } from 'react';
import "bootstrap";
import './res/bootstrap.css';

import "@fortawesome/fontawesome-free/css/all.css"

import './App.css';

import TheGame from './Controllers/Game';
import Main from './Controllers/Main/Main';

import {
  HashRouter as Router,
  // useLocation,
  Switch,
  Route
} from "react-router-dom";
import ReactGA from 'react-ga';
import Store, { ProductDetails, Products } from './Phonegap/Store';
import { getData, setData } from './Helpers/Storage';
import { gameMessage } from './Helpers/Popups';
import Firebase from './Phonegap/Firebase';
import { useTranslation } from 'react-i18next';


export default function App() {
  const { t } = useTranslation();

  useEffect(() => {

    // TriggerEvent("PageLoad",window.location.pathname + window.location.search);
    ReactGA.pageview(window.location.pathname + window.location.search);

    const onOwned = (item: ProductDetails) => {
      console.log("user ownes",item);
      switch(item.id){
        case Products.no_ads:{
          const val = getData("noAdsPurchase");
          if(val !== "done"){
            gameMessage(<div dangerouslySetInnerHTML={{__html: t("thank_you_no_ads_purchase",{item: item.title})}} ></div>);
            setData("noAdsPurchase","done");

            ReactGA.event({
              category: 'Purchase',
              action: 'NoAds'
            });

            Firebase.logEvent("purchase",{"item":"noads"});
          }
          break;
        }
        case Products.unlock_premium:{
          const val = getData("UnlockPremiumPurchase");
          if(val !== "done"){
            gameMessage(<div dangerouslySetInnerHTML={{__html: t("thank_you_no_ads_premium_purchase",{item: item.title})}} ></div>);
            setData("UnlockPremiumPurchase","done");

            ReactGA.event({
              category: 'Purchase',
              action: 'UnlockPremium'
            });

            Firebase.logEvent("purchase",{"item":"unlock_premium"});
          }
          break;
        }
        case Products.no_ads_only:{
          const val = getData("noAdsOnlyPurchase");
          if(val !== "done"){
            gameMessage(<div dangerouslySetInnerHTML={{__html: t("thank_you_no_ads_only_purchase",{item: item.title})}} ></div>);

            setData("noAdsOnlyPurchase","done");

            ReactGA.event({
              category: 'Purchase',
              action: 'NoAdsOnly'
            });

            Firebase.logEvent("purchase",{"item":"no_ads_only"});
          }
          break;
        }
        case Products.super_premium:{
          const val = getData("SuperPremiumPurchase");
          if(val !== "done"){
            gameMessage(<div dangerouslySetInnerHTML={{__html: t("thank_you_no_ads_super_premium_purchase",{item: item.title})}} ></div>);

            setData("SuperPremiumPurchase","done");

            ReactGA.event({
              category: 'Purchase',
              action: 'SuperPremium'
            });

            Firebase.logEvent("purchase",{"item":"super_premium"});
          }
          break;
        }
        case Products.no_ads_only_sub:{
          const val = getData("PremiumSubscription");
          if(val !== "done"){
            gameMessage(<div dangerouslySetInnerHTML={{__html: t("thank_you_no_ads_only_sub",{item: item.title})}} ></div>);

            setData("PremiumSubscription","done");

            ReactGA.event({
              category: 'Purchase',
              action: 'PremiumSub'
            });

            Firebase.logEvent("purchase",{"item":"premium_sub"});
          }
          break;
        }
      }
    }

    Store.setOwnedTrigger(onOwned);

    return () => {
      Store.setOwnedTrigger(undefined);
    }

  },[t])  

  return (
    <>      
        <Router >
          <Switch >
              <Route exact path='/' >
                <Main  />
              </Route>           
              <Route path='/room/:roomid/:playerid' >
                <TheGame />
              </Route>
              <Route exact path='/room/:roomid' >
                <Main  />
              </Route>
          </Switch>
        </Router>
        <div className='loading hide' ><span className='loader '></span></div>

    </>
  );
}


