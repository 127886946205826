import { takeMicPermission } from "../Phonegap/Utils";
import { getData } from "./Storage";

const screen = window.screen;

export function isAndroid(){
  // return true;
  // @ts-ignore
  return (window.cordova && window.cordova.platformId === "android")
}

export function getMicPermission(){
  return takeMicPermission();
}


export const updateDeviceFS = () => {
  // @ts-ignore
  const AndroidFullScreen = window.AndroidFullScreen;
  if(AndroidFullScreen){
    const fs = getData("fullscreen");
    if(fs && fs === "0"){
      // AndroidFullScreen.setSystemUiVisibility(AndroidFullScreen.SYSTEM_UI_FLAG_FULLSCREEN | AndroidFullScreen.SYSTEM_UI_FLAG_LOW_PROFILE);
      AndroidFullScreen.showSystemUI();
    }
    else
      AndroidFullScreen.immersiveMode();
  }
}

export const turnOffDeviceFS = () => {
  // @ts-ignore
  const AndroidFullScreen = window.AndroidFullScreen;
  if(AndroidFullScreen){
    AndroidFullScreen.showSystemUI();

  }
}

export function toggleFS(fsonly = false) {
  try{
    var doc = window.document;
    var docEl = doc.documentElement;
    
  //@ts-ignore
    var requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen;
    //@ts-ignore
    var cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen;
  //@ts-ignore
    if(!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
      requestFullScreen.call(docEl,{navigationUI: "hide"});
    }
    else if(!fsonly){    
      cancelFullScreen.call(doc);
    }
  }
  catch(e){
    
  }
}


export function lockOrientation(orientation: OrientationLockType){
  if(isAndroid() && screen && screen.orientation){    
    screen.orientation.lock(orientation);
  }
}

export function unlockOrientation(){  
  if(isAndroid() && screen && screen.orientation){
    screen.orientation.unlock();
  }
}