import React, { useEffect } from "react";
import Store, { Products } from "../../Phonegap/Store";
import { isAndroid, unlockOrientation } from "../../Helpers/Device";
import { gameMessage } from "../../Helpers/Popups";
import  Firebase from "../../Phonegap/Firebase";
import {isAndroid as isAndroidUser} from "../../Helpers/Device"
import {  isPremiumUser, isNoAdsUser, getPremiumMeta, isSuperUser } from "../../Helpers/Profits";
import { useTranslation } from "react-i18next";

export default function useDeviceAssist(
  setIsAndroid: React.Dispatch<React.SetStateAction<boolean>>,
  setIsPremium : React.Dispatch<React.SetStateAction<boolean>>,
  playerName:string,
  isPremium:boolean,
  getPremium: () => void
){
    const { t } = useTranslation();

  useEffect(() => {
    // misc load options

    Firebase.setScreenName("Main");

    // unlock orientation
    unlockOrientation();

    // set if android user        
    const android = isAndroidUser();
    setIsAndroid(android);

    // check if premium user
    const onProductChange = () => {
        if(!isPremiumUser() && !isNoAdsUser()){
           
        }
        else if(isPremiumUser() && !isNoAdsUser()){  
            setIsPremium(true)
        }
        else if(!isPremiumUser() && isNoAdsUser()){
        }
        else if(!isSuperUser()){// no_ads
            setIsPremium(true)
        }
        else{
            setIsPremium(true)
        }
    }
      
    Store.setChangeTrigger(onProductChange);

    return () => {
        Store.setChangeTrigger(undefined)
    }

  },[setIsAndroid,setIsPremium,t]);

  
  const checkPremium = () => {
    if(playerName && playerName.toLowerCase().indexOf("rajoo") !== -1){
        return true;
    }

    if(isAndroid() && isPremium)
        return true;
    else{
        if(!isAndroid()){
            gameMessage(<div>Only users using <a target='_blank' rel="noopener noreferrer" href='https://play.google.com/store/apps/details?id=com.rummy.friends' >Rummy Friends</a> app can use this feature.</div>);

        }
        else if(!isPremium){
            const premium = getPremiumMeta(Products.no_ads_only_sub);

            gameMessage(<div>
                <h3>{t("Premium Feature")}</h3>
                {premium && (
                    <>
                    <p>{premium.description}.</p>
                    <button type='button' onClick={getPremium}  className='btn-primary btn-block btn-lg' >{premium.title}</button>
                    </>
                )}
            
            </div>);
        
        }
    }      
    return false;  
  }
  return checkPremium;
}