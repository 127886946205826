import { Modal } from "react-bootstrap"
import React, { useState, useEffect } from "react"
import { PlayerTotal } from "./Menus/GameSummery";
import { useTranslation } from "react-i18next";
import { Player } from "../Helpers/Models";
import { useInputFocusFix } from "../Helpers/Helper";

interface TheProps{
    playerTotals: PlayerTotal[];
    onSubmitNewAdjustTotals: (totals: any) => void;
    players: Player[]|undefined;
    hideAdjustTotalPopup: (() => void)
}

var theTotals: any = {};

export default function AdjustTotalsModal({
    playerTotals,onSubmitNewAdjustTotals,
    players, hideAdjustTotalPopup
} : TheProps){

    const [adjustTotals,setAdjustTotals] = useState<any>();

    const onScoreChange = (event: React.ChangeEvent<HTMLInputElement>,playerId:string) => {
        const totals = {...adjustTotals};
        totals[playerId] = event.target.value;    
        setAdjustTotals(totals);
    }


    const { t } = useTranslation();

    useEffect(() => {
        if(adjustTotals !== undefined)
            return;
        
        const totals:any = {};
        playerTotals.forEach(a => {
            totals[a.id] = a.score;            
        });
        setAdjustTotals(totals);
        theTotals = totals;

    },[playerTotals,adjustTotals])

    const onSubmit = () => {
        let adjustedToPoints: any = {};
        for(var i in adjustTotals){
            adjustedToPoints[i] =  adjustTotals[i]  - theTotals[i];
        }
        onSubmitNewAdjustTotals(adjustedToPoints);
    }

    useInputFocusFix([adjustTotals])

    return (
    <Modal show={adjustTotals !== undefined} onHide={hideAdjustTotalPopup}  animation={false}  >
        <Modal.Header closeButton >{t("Adjust Totals")}</Modal.Header>
        <Modal.Body>
            <div>
                {players?.map(p => {
                    return (
                    <div className='form-group' key={p.id} >
                        <label htmlFor={`adjust-totals-${p.id}`} >{p.name}</label>
                        <input type='number' id={`adjust-totals-${p.id}`} className="form-control adjust-inputs" onChange={(e) => onScoreChange(e,p.id)} value={adjustTotals && adjustTotals[p.id]} />
                    </div>
                    )
                })}
                <button type='button' onClick={onSubmit} className='btn btn-lg btn-secondary btn-block' >{t("Update")}</button>
            </div>
        </Modal.Body>
    </Modal>
    )
}