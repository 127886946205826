import React, { useState, useEffect, useRef, useCallback } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useTranslation } from "react-i18next";
import { useInputFocusFix } from "../Helpers/Helper";



interface CustomModalProps{
    title: string;
    message: string | JSX.Element;
    onHide?: (a:boolean) => void;
    size?: "xl"|"sm"|"lg";
    closeButton?: boolean;
}

export function CustomModal({title,message, onHide,size,closeButton}: CustomModalProps){

    const [show,setShow] = useState(true);

    const onCloseClick = useCallback((a: boolean) => {
        setShow(false);
        onHide && onHide(a);
    },[onHide,setShow])

    
    useEffect(() => {
        const onBackButton = (e:Event) => {            
            if(show){
                e.preventDefault();
                onCloseClick(false);
            }
        }

        document.addEventListener("backbutton",onBackButton)

        return () => {
            document.removeEventListener("backbutton",onBackButton);
        }

    },[show,onCloseClick])
    
    return (
        <Modal show={show} size={size}  onHide={() => onCloseClick(false)} centered dialogClassName="game-modal" >
            <Modal.Header closeButton={closeButton !== false} >{title}</Modal.Header>
            <Modal.Body>
                <div style={{fontSize:"22px",padding:"10px"}}>{message}</div>
            </Modal.Body>
        </Modal>
    )
}


interface TheProps{
    message: string | JSX.Element;
    onHide?: (a:boolean) => void;
    buttonText?: string;
    title? :string;
    bigTitle?: boolean;
    size?: "xl" | "sm" | "lg";
    closeButton?: boolean;
}

export function AlertModal({message,buttonText,onHide,title,bigTitle,size,closeButton}: TheProps){

    const [show,setShow] = useState(true);
    const { t } = useTranslation();

    const onCloseClick = useCallback((a: boolean) => {
        setShow(false);
        onHide && onHide(a);
    },[setShow,onHide])

        
    useEffect(() => {
        const onBackButton = (e:Event) => {            
            if(show){
                e.preventDefault();
                onCloseClick(false);
            }
        }

        document.addEventListener("backbutton",onBackButton)

        return () => {
            document.removeEventListener("backbutton",onBackButton);
        }

    },[show,onCloseClick])
    
    return (
        <Modal show={show} onHide={() => onCloseClick(false)} centered dialogClassName="game-modal" size={size} >
            <Modal.Header closeButton={closeButton !== false} >
                {bigTitle ? 
                    <Modal.Title>{title || t("Game Info")}</Modal.Title> :
                    title || t("Game Info")}
            </Modal.Header>

            <Modal.Body>
                <div style={{fontSize:"22px",padding:"10px"}}>{message}</div>
            </Modal.Body>

            <Modal.Footer  >
                <Button variant="secondary" className='min-width' size="lg" block onClick={() => onCloseClick(true)} >{buttonText?buttonText:t("OK")}</Button>
            </Modal.Footer>
        </Modal>
    )
}

interface TheProps2{
    message: string | JSX.Element;
    onHide?: (accept:boolean|null) => void;
    button1_text?: string;
    button2_text?: string;
    size?: "xl" | "sm" | "lg";
    closeButton?: boolean;
    title?:string;
    autoApproveIn?: number;
    preventSkip?:boolean;
}

export function ConfirmModal({title,message,button1_text,button2_text,onHide,size,closeButton,autoApproveIn,preventSkip}: TheProps2){

    const [show,setShow] = useState(true);
    const { t } = useTranslation();

    const onCloseClick = useCallback((accept: boolean|null) => {
        setShow(false);
        onHide && onHide(accept);
    },[setShow,onHide])

        
    useEffect(() => {
        const onBackButton = (e:Event) => {            
            if(show){
                e.preventDefault();
                onCloseClick(null);
            }
        }

        document.addEventListener("backbutton",onBackButton)

        return () => {
            document.removeEventListener("backbutton",onBackButton);
        }

    },[show,onCloseClick]);

    const [count,setCount] = useState(autoApproveIn);

    useEffect(() => {
        let timer: NodeJS.Timeout;
        
        if(show){
            if(autoApproveIn !== undefined){
                timer = setInterval(() => {
                    setCount((count) => {
                        if((count as number)-1 <= 0)
                            onCloseClick(true);
                        return (count as number)-1;
                    });

                },1000)
            }
        }

        return () =>{
            timer && clearInterval(timer);
        }

    },[show,autoApproveIn,onCloseClick])
    
    return (
        <Modal show={show} onHide={() => !preventSkip && onCloseClick(null)} size={size} centered dialogClassName="game-modal" >
            <Modal.Header closeButton={closeButton !== false} >{title?title:t("Game Confirmation")}</Modal.Header>

            <Modal.Body>
                <div style={{fontSize:"22px",textAlign:"center",padding:"10px"}}>{message}</div>
            </Modal.Body>

            <Modal.Footer >
                <div className='col' >
                    <Button variant="secondary"  size="lg" block className='min-width' onClick={() => onCloseClick(true)} >{button1_text || t("Yes")}{autoApproveIn && `-${count}`}</Button>
                </div>
                <div className='col' >
                    <Button variant="primary"  size="lg" block className='min-width' onClick={() => onCloseClick(false)} >{button2_text || t("No")}</Button>
                </div>

            </Modal.Footer>
        </Modal>
    )
}

interface TheProps3{
    message: string;
    type: string;
    onHide?: (input: string, cancel: boolean) => void;
    size?: "xl" | "sm" | "lg";
    closeButton?: boolean;

}

export function InputModal({message,type,onHide,size,closeButton}: TheProps3){

    const [show,setShow] = useState(true);
    const [input,setInput] = useState("");
    const textInput = useRef(null);
    const { t } = useTranslation();

    const onCloseClick = useCallback(() => {
        setShow(false);
        onHide && onHide("",true);
    },[setShow,onHide])

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        
        setInput(e.target.value);
    }

    const onSubmit = (e:React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        setShow(false);
        onHide && onHide(input,false);
    }

    useInputFocusFix([]);

    useEffect(() => {
        // @ts-ignore
        textInput.current.focus();
    },[])


    useEffect(() => {
        const onBackButton = (e:Event) => {            
            if(show){
                console.log("input");
                e.stopPropagation();
                e.preventDefault();
                onCloseClick();
            }
        }

        document.addEventListener("backbutton",onBackButton)

        return () => {
            document.removeEventListener("backbutton",onBackButton);
        }

    },[show,onCloseClick])
    

    return (
        <Modal show={show} onHide={onCloseClick} dialogClassName="game-modal" size={size} >
            <Modal.Header closeButton={closeButton !== false} >{t("Game Request")}</Modal.Header>
            <form onSubmit={onSubmit}>
                <Modal.Body>
                        <div style={{fontSize:"22px",padding:"10px"}}>{message}</div>
                        <div className='form-group' >
                            <input type={type} ref={textInput} value={input} onChange={onChange} className='form-control this-input' />                    
                        </div>
                </Modal.Body>

                <Modal.Footer >
                    <Button variant="secondary"  size="lg" block className='min-width' type='submit' >{t("Submit")}</Button>

                </Modal.Footer>
            </form>
        </Modal>
    )
}