import {v4 as uuid} from 'uuid';


export enum AudioConnectedPlayerStatus{
	micconnected = "Mic Connected",
	connected = "Connected",
	notconnected = "Not Connected",
	connecting = "Connecting..."
}

export interface AudioConnectedPlayer{
	id: string;
	name: string;
	status: AudioConnectedPlayerStatus;
	host?: boolean;
}



export enum CardType{
    closed = "closed",
    hearts = "hearts",
    dimonds = "dimonds",
    spades = "spades",
    joker = "joker",
    clubs = "clubs"
}

export class TheCard{
	id = uuid();
    type: CardType;
    number?: number;

    constructor(type : CardType =  CardType.closed,number? : number){
        this.type = type;
        this.number = number;
    }
}


export enum Room{
	MainMenu,
	NewTable,
	Game,
	Show,
}

export class Player{
	name: string;
	id: string;
	state: PlayerState;
	score: number = 0;
	cards: TheCard[] = [];
	declaredCards: TheCard[][] = [];
	dealer: boolean = false;
	removeNextGame: boolean= false;
	played: boolean = false;
	playing: boolean = false;
	superUser: boolean = false;

	constructor(id:string,name: string, state: PlayerState,score?: number,superUser? :boolean){
		this.id = id;
		this.name = name;
		this.state = state;

		if(score)
			this.score = score;
		
		if(superUser)
			this.superUser = superUser;
		
	}
}

export enum PlayerState{
	HostWaiting,
	Waiting,
	TurnNotPicked,
	TurnPicked,
	Drop,
	Declared,
	NotDeclared,
	ShowNotDeclared,
	Show,
	Ready,

}

export enum GameState{
	WaitingToStart,
	Playing,
	ShowNotDeclared,
	ShowDeclared,
	EveryoneDeclared,
}
export enum JokerCountType{
	PerGame = "per-game",
	perDeck = "per-deck"
}

export class GamePlayerInfo{
	id: string;
	name: string;
	score: number = 0;
	state: PlayerState;

	constructor(id:string,name: string, state: PlayerState,score?: number){
		this.id = id;
		this.name = name;
		this.state = state;

		if(score)
			this.score = score;
		
	}
}

export class GameInfo{
	id: string = uuid();
	hostAdjustment: boolean = false;
	playerInfo: GamePlayerInfo[] = [];

	constructor(adjustment? : boolean) {
		if(adjustment){
			this.hostAdjustment= adjustment;
		}
	}
}

export enum RoomType{
	Friends,
	StrangerPlay,
}

export enum GameType{
	Free,
	Pool
}


export class Game{
	players: Player[] = [];
	myCards: TheCard[] = [];
	previousCards: TheCard[] = [];
	openCard: TheCard | null = null;
	timer: number = 0;
	gameState: GameState = GameState.WaitingToStart;
	numberOfJokers: number = 1;
	jokerCount: JokerCountType = JokerCountType.PerGame;
	deckJoker: TheCard|undefined = undefined;
	roomHistory: GameInfo[] = [];
	roomChat: Chat[] = [];
	timeLeft?: number;
	minimumDecks: number = 0;
	dropPoints: number = 20;
	middlePoints: number = 40;
	type: RoomType = RoomType.Friends;
	gameType: GameType = GameType.Free;
	poolPoints: number = 201;
	reEntry: boolean = false;
}

export class StrangerSlot {
	id: string = uuid();
	lastRequestTime: number = Date.now();
	roomId: string = "";
	playerId: string = "";
	creatorName: string;
	constructor(name:string){
		this.creatorName = name;

	}
}

export enum ChatType{
	Emotion,
	Message
}

export class Chat{
	id: string = uuid();
	playerId: string;
	message: string;
	type: ChatType = ChatType.Emotion;
	time: number = new Date().getTime();

	constructor(playerId:string, message:string){
		this.playerId = playerId;
		this.message = message;
	}

}

export class RoomData{
	id: string
	players: Player[] = [];
	deck: TheCard[] = [];
	previousCards: TheCard[] = [];
	gameState: GameState = GameState.WaitingToStart;
	timer: number = 0;
	turnTime?: number;
	round: number = 0;
	lastRequestTime: number = Date.now();
	numberOfJokers: number = 1;
	jokerCount: JokerCountType = JokerCountType.PerGame;
	deckJokerReq: boolean = true;
	deckJoker: TheCard|undefined = undefined;
	roomHistory: GameInfo[] = [];
	roomChat: Chat[] = [];
	minimumDecks: number = 0;
	dropPoints: number = 20;
	middlePoints: number = 40;
	type: RoomType = RoomType.Friends;
	gameType: GameType = GameType.Free;
	poolPoints: number = 101;
	reEntry: boolean = false;

	constructor(id : string){
		this.id = id;
	}
}

export enum GameAction{
    Start = "Start",
    SkipStart = "SkipStart",
    PickCard = "PickCard",
    PickDeck= "PickDeck",
    Drop= "Drop",
    ReleaseCard = "ReleaseCard",
    Show = "Show",
    DeclareShow = "DeclareShow",
    Declare = "Declare",
		Ready = "Ready",
		Invite = "Invte",
		ReEnter = "ReEnter",
		SkipReEnter = "SkipReEnter"
}


export class RoomOptions{
	playerName: string = "";
	numberOfJokers: number = 0;
	jokerCount: JokerCountType = JokerCountType.PerGame;
	deckJoker: boolean = true;
	timer: number = 0;
	gameOn?: boolean;
	minDecks: number = 0;
	dropPoints: number = 20;
	middlePoints: number = 40;
	gameType: GameType = GameType.Free;
	poolPoints: number = 101;
	reEntry: boolean = false;
}

export class SelectOption{
	label: string;
	value: string;
	disabled: boolean = false;
	
	constructor(value:string, label?:string,disabled?:boolean ){
		this.value = value;
		this.label = label ? label : value;
		if(disabled)
			this.disabled = disabled;
	}
}