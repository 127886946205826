import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import phonegap from "./Phonegap/Index"
import './i18n';
import {v4 as uuid} from "uuid";
import { getData, setData } from './Helpers/Storage';
import { updateDeviceFS } from './Helpers/Device';
import  Firebase  from './Phonegap/Firebase';
import ReactGA from 'react-ga';
import Play  from './Phonegap/Play';

const init = () => {
  const splash = document.getElementById("splash-screen");
  splash.remove();

  Firebase.init();
  Play.init();

  const checkUserId = (id) => {
    
    let userid = id || getData("userid");
    if(!userid){
      userid = uuid();
      setData("userid",userid);
    }
 
    Firebase.setUserId(userid);    
    ReactGA.set({ userId: userid })  
  }

  Play.signIn()
    .then((result) => {
      if(result){
        Play.getUser()
          .then(user => {
            checkUserId(user.playerId);
          }) 
      }
      else{
        checkUserId();
      }
    })
    .catch(() => {
      checkUserId();
    })


  updateDeviceFS();

  var GA_LOCAL_STORAGE_KEY = 'ga:clientId';
  if(localStorage){
    const item = localStorage.getItem(GA_LOCAL_STORAGE_KEY);
    
    ReactGA.initialize('UA-166777470-1',{
      gaOptions:{
        'storage': 'none',
        'clientId': item?item:undefined
      }
    });

    ReactGA.ga(function(tracker){
      localStorage.setItem(GA_LOCAL_STORAGE_KEY, tracker.get('clientId'));
    })

    ReactGA.ga('set', 'checkProtocolTask', function(){ /* noop */});

  }

  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root')
  );    
};

var splashTimeup = false;
var deviceReady = false;
setTimeout(function(){
  splashTimeup = true;
  if(deviceReady)
    init();
},2000);

if(!window.cordova){
  console.log("is not android");
  deviceReady = true;
}
else{
  console.log("is android");
  phonegap.initialize();
  document.addEventListener('deviceready', function(){
    deviceReady = true;

    if(splashTimeup)
      init();
  });
}



serviceWorker.unregister();
