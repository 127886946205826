import { AdsLibrary, AdsOptions } from "./Ads";
import AppConfig from "../../Config";

export default class FacebookAds implements AdsLibrary{
  interstitialAvailable = false;
  videoAvailable = false;

  debug = false;
  // @ts-ignore
  plugin = window.cordova.plugins.codeplayfacebookads;
  name = "facebook";

  onInterstitialLoad?:(() => void) = undefined;
  onVideoLoad?:(() => void) = undefined;
  onVideoReward?:(() => void) = undefined;
  onVideoClose?:(() => void) = undefined;
  onInterstitialClose?:(() => void) = undefined;

  constructor(options: AdsOptions){
    this.debug = options.debug; 
    this.onInterstitialLoad = options.onInterstitialLoad;
    this.onVideoLoad = options.onVideoLoad;
    this.onVideoReward = options.onVideoReward;
    this.onVideoClose = options.onVideoClose;
    this.onInterstitialClose = options.onInterstitialClose;

    this.init();
  }
  

  init(){

    this.loadInterstitial();
    //this.loadVideo();
  
  }

  loadInterstitial(){
    const onInterstitialLoad = (event:string) => {
      if(event === "AdLoaded"){
        this.interstitialAvailable = true;
        this.onInterstitialLoad && this.onInterstitialLoad();
      }
      else if(event === "AdClosed"){
        this.onInterstitialClose && this.onInterstitialClose();
      }
    }
    let interstitialFANTimer : NodeJS.Timeout;

    const onInterstitialFail = () =>{
      clearTimeout(interstitialFANTimer);
      interstitialFANTimer =setTimeout(() => { 
        this.loadInterstitial(); 
      },10000);
    }    

    this.plugin.loadInterstitialAds({
      interstitialid: AppConfig.fan_interstitial_id,
      isTesting: false,
    },onInterstitialLoad,onInterstitialFail)
  
  }

  loadVideo() {
    
    const onVideoEvent = (e:any) => {
      switch(e){
        case "AdLoaded":
          this.onVideoLoad && this.onVideoLoad();
          this.videoAvailable = true;
          break;
        case "AdCompleted":
          this.onVideoReward && this.onVideoReward();
          this.loadVideo();
          break;
        case "AdClosed":
          this.loadVideo();
          this.onVideoClose && this.onVideoClose();
          break;
        default:
          break;
      }
      console.log("reward load eve ",e)
    }

    let videoFANTimer: NodeJS.Timeout;
    const onVideoFail = () => {
      //console.log("reward load failed");
      clearTimeout(videoFANTimer);
      videoFANTimer = setTimeout(() => { 
        this.loadVideo(); 
      },10000);
    }


    this.plugin.loadRewardVideoAd({
      videoid: AppConfig.fan_reward_vid_id,
      isTesting:false
    },onVideoEvent,onVideoFail)
  
  }
  
  showInterstital = () => {
    this.plugin.showInterstitialAds("");
    this.loadInterstitial();
    this.interstitialAvailable = false;          
  }

  showVideo = () =>{
    this.plugin.showRewardVideoAd("");
    this.videoAvailable = false;
  }


  isInterstialAvailable(){    
    return this.interstitialAvailable && AppConfig.load_fb_ads;
  }

  isVideoAvailable(){
    return this.videoAvailable && AppConfig.load_fb_ads;
  }
}