import React, { useCallback, useEffect, useState } from "react";
import { Player, Chat } from "../Helpers/Models";
import Emoji from "../Emojis";

interface TheProps{
  players?: Player[];
  chatData?: Chat[];
  userChat?: Chat;
}

interface ChatX extends Chat{
  top?:number;
  left?: number;
}
const emojiChatWidth = 120;
const alreadyShow: string[] = [];
var componentOpen = false;

export default function ChatMessage({userChat,chatData,players}:TheProps){
  const [messages,setMessages] = useState<ChatX[]>();

  useEffect(() => {
    componentOpen = true;
    const beforeNow = new Date();
    beforeNow.setSeconds( beforeNow.getSeconds() - 10);// 10 secs

    const chatDataClone = chatData && [...chatData];
    chatDataClone && userChat && chatDataClone.push(userChat);

    const msgs = chatDataClone?.filter(a => {
      if(alreadyShow.indexOf(a.id) !== -1)
        return false;

      if(a.time > beforeNow.getTime())
        return true;
      
      return false;

    }).map(a => {
      const sp = document.querySelector(".seated-players .player[data-playerid='"+a.playerId+"']");
      
      let top = undefined;
      let left = undefined;
      if(sp){
        const rect = sp.getBoundingClientRect();
        top = rect.top +  rect.height;
        left = rect.left + rect.width/2 - emojiChatWidth/2;
        console.log("sdsd",a.playerId,sp,left,top);  
      }

      return {...a,top:top,left:left,message: decodeURI(a.message)}      
    })

    if(msgs && msgs.length){
      setMessages((m)=>{
        if(m)
          return [...m,...msgs];
        return [...msgs];
      })
    }

    msgs?.forEach(a => {
      alreadyShow.push(a.id);

      setTimeout(() => {
        if(!componentOpen)
          return;

       setMessages((m) => {
          if(!m)
            return m;

          const clone = m.filter(ma => ma.id !== a.id);
          return clone;
        })
      },3000);


    })    

    return () => {
      componentOpen = false;
    }

  },[chatData,userChat]);

  const onChatClick = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) =>{
    event.currentTarget.classList.add("hide");
  },[])

  return (
    <div>
      {messages?.map(a => (
        <div key={a.id} style={{top:a.top,left:a.left,display:a.top?"block":"none"}} className={"chat-popup-message"} onClick={onChatClick} data-playerid={a.playerId} >
          <ChatText chat={a} />
        </div>
      ))}
    </div>
  )
}

export function ChatText({chat}:{chat:Chat}){
  
  const findEmojiByName = useCallback((name:string) => {
    const f = Emoji.find(chat => chat.name === name);

    return f?.path;
  },[])

  return (
    <>
        {findEmojiByName(chat.message) ?
          <img src={findEmojiByName(chat.message)} style={{width:100}} alt="Emoji" /> :
        <>{chat.message}</>}
    </>
  )
}