import Modal from "react-bootstrap/Modal";
import React, { useState, useEffect } from "react";
import { GameInfo, PlayerState, RoomType } from "../../Helpers/Models";
import ToggleSwitch from "../../Components/ToggleSwitch";
import { useTranslation } from 'react-i18next';
import AppConfig from "../../Config";
import { isPremiumUser } from "../../Helpers/Profits";

export interface PlayerTotal{
  id:string;
  score:number
}

interface HistoryPlayerInfo{
  name: string;
  id: string;
}

interface Props{
  show: boolean;
  onCloseClick: (() => void);
  roomHistory: GameInfo[];
  playerTotals: PlayerTotal[];
  historyPlayers: HistoryPlayerInfo[];
  onPointsStyleChange: ((pointsRummyStyle: boolean) => void);
  onClearRoomRequest: (() => void);
  onAdjustTotalRequest: (() => void);
  poolPoints?: number;
  roomType?: RoomType;
}


export function useManageScore(roomHistory: GameInfo[],pointsRummyStyle: boolean){
  const [playerTotals,setPlayerTotals] = useState<PlayerTotal[]>([]);
  const [historyPlayers,setHistoryPlayers] = useState<HistoryPlayerInfo[]>([]);
  
  useEffect(() => {
    const currentPlayers = [...historyPlayers];
    roomHistory.forEach(history => {
      history.playerInfo.forEach(pinfo => {
        if(!currentPlayers.find(currentPlayer => currentPlayer.id === pinfo.id)){
          currentPlayers.push({id:pinfo.id,name:pinfo.name});
        }
      })
    })
    
    if(historyPlayers.length !== currentPlayers.length){
      setHistoryPlayers(currentPlayers)
    }

  },[roomHistory,historyPlayers])


  
  useEffect(() => {
    const totals: {id:string,score:number}[] = [];
    
    historyPlayers.forEach(p => {
      totals.push({
        id: p.id,
        score: 0
      });
    });

    if(!pointsRummyStyle)
      roomHistory.forEach(a => {      
        a.playerInfo.forEach(p => {
          const thePlayer = totals.find(b => b.id === p.id)
          if(!thePlayer)
            return;     

          thePlayer.score += p.score;
        })
      });
    else
      roomHistory.forEach(game => {
        if(game.hostAdjustment)
          return;
          
        const winner = game.playerInfo.find(p => p.score === 0);
        if(!winner)
          return;
        
        let total = 0;
        game.playerInfo.forEach(p => {
          total += p.score;      
            
          const player = totals.find(b => b.id === p.id);
          if(player){
            player.score -= p.score;
          }
        })

        
        const winnerPlayer = totals.find(p => p.id === winner.id);
        if(winnerPlayer)
          winnerPlayer.score += total;

        
      })

    if(JSON.stringify(playerTotals) !== JSON.stringify(totals))
      setPlayerTotals(totals);

  },[roomHistory,pointsRummyStyle,historyPlayers,playerTotals])


  return {historyPlayers, playerTotals}
}

const FormatedPlayerHistory = ({playerId, game} : {playerId:string, game:GameInfo}) => {
  const { t } = useTranslation();

  const player = game.playerInfo.find(a => a.id === playerId);
  if(!player){
    return <div>-</div>
  }

  let state = "";
  if(player.state === PlayerState.Drop){
    state = `(${t("Drop").toLowerCase()})`;
  }

  return (
    <div>
      <span>{player.score}</span>
      <i>{state}</i>
    </div>
  )
}

const FormatedPlayerTotal = ({playerId,playerTotals}:{playerId:string, playerTotals: PlayerTotal[]}) =>{
  const total = playerTotals.find(a => a.id === playerId);
  return (
    <div>{total?.score}</div>
  )
}

export default function GameSummeryMenu({
  show,
  onCloseClick, 
  roomHistory,
  playerTotals,
  historyPlayers,
  onPointsStyleChange,
  onClearRoomRequest,
  onAdjustTotalRequest,
  poolPoints,
  roomType
}: Props){

  const [pointsRummyStyle,setPointsRummyStyle] = useState(false);
  // const [playerTotals,setPlayerTotals] = useState<PlayerTotal[]>([]);
  // const [historyPlayers,setHistoryPlayers] = useState<HistoryPlayerInfo[]>([]);

  const { t } = useTranslation();

  const onTotalPointsChange = (val: boolean) => {
    setPointsRummyStyle(val);
    onPointsStyleChange(val);
  }

  return (
    <>
      <Modal show={show}  animation={false}  onHide={onCloseClick} size="lg" centered>
        <Modal.Header closeButton>{t("Games Summary")}</Modal.Header>
        <Modal.Body>
          <div className='form-group menu'>           
            <div className='the-info' >
              <div style={{textAlign:"center"}}>
                {poolPoints && <h3>{poolPoints} Points</h3>}
              </div>
              {historyPlayers.length ? (
                <>
                  <div style={{overflow:"auto"}} >
                    <table className='table table-bordered table-striped' >
                      <thead>                              
                        <tr>
                          <th>#</th>
                          {historyPlayers.map(p => (
                            <th key={p.id} >{p.name}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>                               
                        {roomHistory.map((g,i) => (
                          <tr key={i} >
                            <td>{i}{g.hostAdjustment ? " - Adjusted" : ""}</td>
                            {(isPremiumUser() || i < AppConfig.game_summery_premium_only)?
                            historyPlayers.map(p => (
                              <td key={p.id} >
                                <FormatedPlayerHistory playerId={p.id} game={g} />
                              </td>
                            )):(
                              <td colSpan={historyPlayers.length} style={{textAlign:"center"}} >
                                A free user is limited to view only {AppConfig.game_summery_premium_only} records.
                              </td>
                            )}
                          </tr>
                        ))}                      
                        <tr>
                          <th>Total</th>
                          {historyPlayers.map(p => (
                            <th key={p.id} >
                                <FormatedPlayerTotal playerId={p.id} playerTotals={playerTotals} />                 
                            </th>
                          ))}
                        </tr>
                      </tbody>

                    </table>
                  </div> 
                  <div className='row' >
                    <div className='col-md-auto mb-3' >
                      <ToggleSwitch label={t("Total points rummy wise?")} value={pointsRummyStyle} onChange={onTotalPointsChange} ></ToggleSwitch>
                    </div>
                    
                    {roomType !== RoomType.StrangerPlay && (
                    <>
                      {!pointsRummyStyle  && <div className='col-md-auto mb-3' >
                        <button type='button' className='btn btn-secondary btn-block' onClick={onAdjustTotalRequest} >{t("Adjust Totals")}</button>
                      </div>}
                      <div className='col-md-auto' >
                        <button type='button' className='btn btn-primary btn-block' onClick={onClearRoomRequest} >{t("Clear Data")}</button>
                      </div>           
                    </>
                    )}
                   </div>
                </>
              ):(
                <div className='info-box' >{t("Nothing to show yet.")}</div>
              )}
      
            </div>
          </div>
        </Modal.Body>
      </Modal>

    </>
  )
}