import { getData, setData } from "./Storage";
import AppConfig from "../Config";

export function getPlayPoints(){
  let playPoints = getData("playPoints");
  if(!playPoints)
    playPoints = AppConfig.initial_playing_points.toString();
  
  return parseInt(playPoints)
}

export function consumePlayPoints(points: number){
  let p = getPlayPoints();
  p-=points;
  if(p < 0)
    p = 0;
    
  setData("playPoints",p.toString())
}

export function addPlayPoints(points: number){
  let p = getPlayPoints();
  p+=points;
  setData("playPoints",p.toString())
}
