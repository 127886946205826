

export async function takeMicPermission() {
  // @ts-ignore
  const cordova = window.cordova;
    if(cordova.platformId !== "android")
      return;

  return new Promise((res,rej) => {    

    var permissions = cordova.plugins.permissions;
    console.log("cordova "+typeof cordova.plugins.permissions);

    permissions.checkPermission(permissions.RECORD_AUDIO, function( status:any ){
      if ( status.hasPermission ) {
        console.log("Yes :D ");
        res();
      }
      else {
        console.log("No :( ");
        const error = () => {
          console.log('Camera permission is not turned on');
          rej();
        }
          
        const success = ( status :any) => {
          if( !status.hasPermission ) error();
          console.log("Yes :D ");
          res();

        }
        permissions.requestPermission(permissions.RECORD_AUDIO, success, error);
          
    
      }
    }, function error(msg:any){
      console.log("permission error"+msg)
      rej();
    });

  });
}

export function tryAskingReview(){
  // @ts-ignore
  const LaunchReview = window.LaunchReview;
  if(!LaunchReview)
    return;
    
  if(LaunchReview.isRatingSupported()){
    LaunchReview.rating();
  }
}