import React, { useState, useEffect, useCallback } from "react";
import { GameState, PlayerState, AudioConnectedPlayer, AudioConnectedPlayerStatus } from "../Helpers/Models";
import { PlayerTotal } from "../Controllers/Menus/GameSummery";

export class TheSeatedPlayer{
    id: string = "";
    name: string = "";
    active: boolean =  false;
    super: boolean =  false;
    dealer: boolean =  false;
    info:string =  "";
    state: PlayerState;

    constructor(id:string,name:string,active:boolean,info:string,state: PlayerState){
      this.id = id;
      this.name = name;
      this.active = active;
      this.info = info;
      this.state = state;
    }
}

interface TheProps{
    players: TheSeatedPlayer[];
    voiceConnections: AudioConnectedPlayer[];
    currentPlayer: string;
    currentPlayerAudioStatus: string;
    timeLeft?: number;
    gameState?: GameState;
    playerTotals: PlayerTotal[];
}


export function SeatedPlayers(props: TheProps){
  const [playerTimers,setPlayerTimers] = useState<string[]>([]);

  function getVolumeIconClass(id:string){
    // if(id === props.currentPlayer){
    //   switch(props.currentPlayerAudioStatus){
    //     case "mic":
    //       return "fa-microphone";
    //     case "sound":
    //       return "fa-volume-up"
    //     case "connecting":        
    //       return "fa-volume-down";
    //     default: 
    //       return "fa-user hide"
    //   }
    // }
    
    const c = props.voiceConnections.find(a => a.id === id);

    switch(c?.status){
      case AudioConnectedPlayerStatus.micconnected:        
        return "fa-microphone";
      case AudioConnectedPlayerStatus.connected:
        // return "fa-volume-up"
        return "fa-microphone";
      case AudioConnectedPlayerStatus.connecting:        
        return "fa-volume-down";  
      // case AudioConnectedPlayerStatus.notconnected:        
      //   return "fa-user hide";  
    }

    return "fa-user hide";
  }

  const [timeLeft,setTimeLeft] = useState<number>();

  useEffect(() => {
    let additional = 0;
    if(props.timeLeft && props.gameState !== undefined){

      if(props.gameState === GameState.Playing || props.gameState  === GameState.ShowNotDeclared){
        const p  = props.players.find(a => a.active);
        if(p)
          setPlayerTimers([p.id]);

      }
      else if( props.gameState === GameState.WaitingToStart ||  props.gameState === GameState.EveryoneDeclared){
        setTimeLeft(undefined)
        return;
      }
      else if(props.gameState === GameState.ShowDeclared){
        const p = props.players.filter(a => a.state !== PlayerState.Ready);
        
        setPlayerTimers(p.map(a => a.id));
        
      }
      

      let diff = Math.round((props.timeLeft ) / 1000);
      if(diff < 1)
        diff = 1;

      setTimeLeft(diff);
    }
    else{
      setTimeLeft(undefined)
    }


    const updateTimeLeft = () => {
      if(!props.timeLeft)
        return;

      let diff = Math.round((props.timeLeft) / 1000) - (additional++);
      if(diff < 1)
        diff = 1;

      setTimeLeft(diff);
    }

    const timer = setInterval(updateTimeLeft,1000);   
    // console.log('trigger',Date.now());

    return () => {
      clearInterval(timer);
    }
    

  },[props])

  const FormatedPlayerTotal = useCallback(({playerId}:{playerId:string}) =>{
    const total = props.playerTotals.find(a => a.id === playerId);
    return (
      <>{total && ("("+total.score+")")}</>
    )
  },[props.playerTotals])

  return (
      <div className='seated-players' >
        {props.players.map((a,i) => {
          return (
            <div key={i} data-playerid={a.id} className={"player" + (a.active?" active":"") + (a.id === props.currentPlayer?" self":"")}>
              <div>
                <audio style={{width:100,height:100,background:"red"}} id={"video"+a.id} className="the-audio-players"  ></audio>
                <div className='name'>                  
                  <i className={"fa "+getVolumeIconClass(a.id)} style={{marginRight:2}} ></i> 
                  {a.dealer && <i className={"far fa-hand-paper"} style={{marginRight:2}} ></i>}
                  {a.super && <i className={"fab fa-superpowers"} style={{marginRight:2}} ></i>}
                  <span>{a.name} </span>
                  <FormatedPlayerTotal playerId={a.id} />
                </div>
                <div className='info'>                  
                  <span>{a.info}</span>
                  <span className="timer" >{playerTimers.findIndex(b => b === a.id) !== -1 && timeLeft && ` - ${timeLeft}s`}</span>
                </div>
              </div>
            </div>
          )
        })}
      
      </div>
  );
}