import ReactDOM from "react-dom";
import React from "react";
import { ConfirmModal, InputModal, AlertModal, CustomModal } from "../Components/Modals";
import SoundFx from "./SoundFx";

interface CommonOptions{
  size?: "xl" | "sm" | "lg";
  closeButton?: boolean;
}

interface MessageOptions extends CommonOptions{
  button_text?: string;
  title?: string;
  big_title?: boolean;
}


export function gamePopup(title: string, msg: string | JSX.Element,options?: CommonOptions){
  const p = new Promise((res) => {
    let container = document.createElement("div");
    document.body.appendChild(container);

    const onHide = (a:boolean) => {
      container.remove();
      // @ts-ignore
      container = null;
      SoundFx.play("close")
      res(a);
    }

    ReactDOM.render(<CustomModal title={title} message={msg} onHide={onHide} closeButton={options?.closeButton} size={options?.size || "sm"}  />, container);  
  });

  return p;
}

export function gameMessage(msg: string | JSX.Element,options?: MessageOptions){
  const p = new Promise((res) => {
    let container = document.createElement("div");
    document.body.appendChild(container);

    const onHide = (a:boolean) => {
      container.remove();
      // @ts-ignore
      container = null;
      SoundFx.play("close")
      res(a);
    }
    ReactDOM.render(<AlertModal message={msg} buttonText={options?.button_text} onHide={onHide} title={options?.title} bigTitle={options?.big_title}  closeButton={options?.closeButton} size={options?.size || "sm"}  />, container);  
  });

  return p;
}


interface ConfirmOptions extends CommonOptions{
  button_text1?: string;
  button_text2?: string;
  title?: string;
  autoApproveIn?:number;
  preventSkip?:boolean;
}


export function gameConfirm(msg: string | JSX.Element,options?: ConfirmOptions){


  const p = new Promise<boolean|null>((res) => {
    let container = document.createElement("div");
    document.body.appendChild(container);
    const onHide = (accept: boolean|null) => {
      container.remove();
      // @ts-ignore
      container = null;
      SoundFx.play("close")
      res(accept);

    } 
  
    ReactDOM.render(<ConfirmModal 
      title={options?.title} 
      autoApproveIn={options?.autoApproveIn} 
      message={msg} 
      onHide={onHide} 
      closeButton={options?.closeButton} 
      size={options?.size || "sm"} 
      button1_text={options?.button_text1} 
      button2_text={options?.button_text2} 
      preventSkip={options?.preventSkip}
      />, container); 
  });

 
  return p;
}


export function gameInput(msg: string,type:string = "text",options?: CommonOptions){
  const p = new Promise<[string,boolean]>((res) => {
    let container = document.createElement("div");
    document.body.appendChild(container);
    const onHide = (input: string,cancel: boolean) => {
      container.remove();
      // @ts-ignore
      container = null;
      SoundFx.play("close")
      res([input,cancel]);

    } 
  
    ReactDOM.render(<InputModal type={type} message={msg} onHide={onHide} closeButton={options?.closeButton} size={options?.size || "sm"}  />, container); 
  });

 
  return p;
}