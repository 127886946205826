import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ReactGA from 'react-ga';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { showPriceMenu, showAd} from "../../Helpers/Profits";
import {  gameMessage, gameConfirm } from "../../Helpers/Popups";
import {  getData, setData } from "../../Helpers/Storage";
import SoundFx from "../../Helpers/SoundFx";
import { useTranslation } from 'react-i18next';
import Firebase from "../../Phonegap/Firebase";
import useCollectName from "./CollectName";
import useCreateRoomHelper from "./CreateRoomHelper";
import useDeviceAssist from "./DeviceAssist";
import useStrangerPlay from "./StrangerPlay";
import SettingsMenu from "../Menus/Settings";
import CreateRoomX from "./CreateRoom";
import Play from "../../Phonegap/Play";
import AppConfig from "../../Config";
import { tryAskingReview } from "../../Phonegap/Utils";

export default function  Main(){

    const [playerName,setPlayerName] = useState("");
    const { roomid } = useParams<{roomid: string}>();

    const [showNewRoomOptions,setShowNewRoomOptions] = useState(false);
    const [isAndroid,setIsAndroid] = useState(false);
    const [isPremium,setIsPremium] = useState(false);
    const [showSPModal,setShowSPModal] = useState(false);
    const [isNameLoaded,setIsNameLoaded] = useState(false);
    const [showRejoin,setShowRejoin] = useState(false);

    const { t } = useTranslation();

    const collectName = useCollectName(t,setPlayerName);
 
    const {
        joinRoom,
        createRoom
    } = useCreateRoomHelper(
        t,
        playerName,
        collectName,
        setShowNewRoomOptions        
    );

    const getPremium = () => {
        SoundFx.play("click2");
        showPriceMenu(t);
    }
    
    const checkPremium = useDeviceAssist(
        setIsAndroid,
        setIsPremium,
        playerName,
        isPremium,
        getPremium
    );

    const rejoinRoom = () => {
        SoundFx.play("click");
        const lastRoomId = getData("last-roomid");
        if(lastRoomId)
            joinRoom(lastRoomId);
        else{
            gameMessage(t("There is no last room"))
        }
    }

    const {
        cancelStrangePlay,
        formTime,
        strangerPlay,
        strangerPlayAvail
    } = useStrangerPlay(
        playerName,
        collectName,
        setShowSPModal
    );

    const onShowRoomOptionsClose = () => {
        SoundFx.play("close") && setShowNewRoomOptions(false)
    }

    useEffect(() => {
        // load user name, if exist from the local
        const name = getData("name");
        if(name){
            ReactGA.set({"dimension1": name});
            setPlayerName(name);
            Firebase.setUserProperty("Name",name);
        }
        setIsNameLoaded(true);

    },[])

    useEffect(() => {
        // checkin logins and asking review

        let logins = getData("userLogins");
        let loginVal = 0;
        if(logins){ 
            // if it is null, it means this is first login            
            loginVal = parseInt(logins);
        }

        loginVal++;

        if(loginVal % AppConfig.minimimum_games_for_review === 0){    
            tryAskingReview();
        }

        setData("userLogins",loginVal.toString());
    },[]);

    useEffect(() => {
        // if name is loaded join player to room
        // users coming from invite link use
        // this way to auto-join
        // we need to wait till name is fetched
        // and set in state

        if(!isNameLoaded)
            return;

        setIsNameLoaded(false)
        if(roomid){
            joinRoom(roomid)
        }
        console.log("if name");
        
    },[isNameLoaded,joinRoom,roomid])
   


    useEffect(() => {
        const onBackButton = (e:Event) => {
            console.log("main");
            e.preventDefault();
            const gameModalAvailable = document.querySelector(".modal-dialog.game-modal");
            if(gameModalAvailable){
                // ignore
            }
            else if(showNewRoomOptions){
                setShowNewRoomOptions(false);
            }
            else if(showSPModal){
                setShowSPModal(false)
            }
            else{
                // @ts-ignore
                if(isAndroid && navigator && navigator.app && navigator.app.exitApp){
                    gameConfirm("Do you want to exit the app?")
                    .then(function(value){
                        if(value){

                            AppConfig.show_ad_on_game_exit && 
                                showAd(t)
                                    .then(() => {
                                        // @ts-ignore
                                        navigator.app.exitApp();
                                    })
                            
                        }
                    })
                    
                }
            }        
        }

        document.addEventListener("backbutton",onBackButton)

        return () => {
            document.removeEventListener("backbutton",onBackButton);
        }

    },[showNewRoomOptions,showSPModal,isAndroid,setShowSPModal,setShowNewRoomOptions,t])

    

    useEffect(() => {
        // check if rejoin optino is available
        const pid = getData("last-pid");    
        const rid = getData("last-roomid");

        if(pid && rid)
            setShowRejoin(true);
        
    },[t])

    const [showSettings,setShowSettings] = useState(false);
    const onSettingsClick = () => {
        SoundFx.play("open");
        setShowSettings(true);
    }

    const onSettingsHide = () => {
        SoundFx.play("close");
        setShowSettings(false);
    }

    // const onLeaderboardClick = () => {
    //     SoundFx.play("open");
    //     Play.showAllLeaderboards();
    // }


    const onAcheivementsClick = () => {
        SoundFx.play("open");
        Play.showAchievements();
    }

    return (
        <div className='main-page' >
            <div className='holder' >
                <div className='box' >
                    {playerName && 
                        <button className='btn btn-link user' type='button' onClick={() => SoundFx.play("click2") && collectName()} >{t("Hi")} {playerName}! <span style={{opacity:"0.5"}} >({t("Change")})</span></button>}

                    <div>
                        <div className='button newroom' onClick={createRoom} >{t("New room")} <i className='fas fa-plus' ></i></div>
                        <div className='button joinroom' onClick={() => joinRoom()} >{t("Join a room")} <i className='fas fa-sign-in-alt' ></i> </div>                   
                    <   div className='button playstranger' onClick={() => strangerPlay()} >{t("Play a stranger")}  {!strangerPlayAvail? <i className='fas fa-globe-americas' ></i> : <small style={{whiteSpace:"nowrap"}} >({t("open slot")})</small>}</div>                   
                    </div>             
                    {playerName && (
                        <div className='quick-actions' >
                            {isAndroid && (<div  >
                                <button type='button' className='btn btn-link btn-flying bottom-right2' onClick={getPremium} ><i className='fas fa-store' ></i></button>
                            </div>)}
                            {showRejoin && <div >
                                <button type='button' className='btn btn-link btn-flying bottom-left' onClick={rejoinRoom} ><i className='fas fa-undo' ></i> <span>Rejoin<br />Last Room</span></button>
                            </div>}

                            {isAndroid && <button type='button' className='btn btn-link btn-flying bottom-right' onClick={onAcheivementsClick} ><i className='fas fa-crown' ></i></button>}

                            <button type='button' className='btn btn-link btn-flying top-right' onClick={onSettingsClick} ><i className='fas fa-cog' ></i></button>

                            {/* <button type='button' className='btn btn-link' onClick={onLeaderboardClick} ><i className='fas fa-cog' ></i> {t("Leaderboard")}</button> */}
                            

                        </div>
                    )}
                </div>
            </div>
            
            <CreateRoomX showNewRoomOptions={showNewRoomOptions} onCloseRequest={onShowRoomOptionsClose} playerName={playerName} checkPremium={checkPremium} />

            {showSPModal && <Modal className="modal-popup" show={showSPModal} centered onHide={() => {SoundFx.play("close") && cancelStrangePlay()}}  >
                <Modal.Header closeButton>{t("Play a stranger")}</Modal.Header>
                <Modal.Body>
                    <div style={{fontSize:"22px"}}>
                        <p>
                            {t("Please wait while we try to find someone to play with you.")}                            
                        </p>                            
                        <p style={{textAlign:"center",fontWeight:"bold"}} >
                            Time Elapsed: {formTime}
                        </p>
                    </div>
                </Modal.Body>

                <Modal.Footer >
                    <Button variant="secondary" onClick={cancelStrangePlay}  size="lg" block className='min-width' type='submit' >{t("Cancel")}</Button>
                </Modal.Footer>
            </Modal>}
                      
            {showSettings &&<SettingsMenu 
                show={showSettings}
                onCloseClick={onSettingsHide}              
            />}
            
        </div>
    )
}