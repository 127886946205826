import { TheCard, Game, GameAction, Player } from "../Helpers/Models";
import { useCallback } from "react";
import { animate, setLoader, } from "../Helpers/Helper";
import { gameAction } from "../Helpers/GameAction";
import ReactGA from "react-ga"
import React from "react";
import SoundFx from "../Helpers/SoundFx";
import { checkIfSuperUserExist, showAd } from "../Helpers/Profits";
import { gameMessage, gameConfirm } from "../Helpers/Popups";

import { useTranslation } from 'react-i18next';
import { TFunction } from "i18next";
import Firebase from "../Phonegap/Firebase";
import Play from "../Phonegap/Play";
import { destroyCurrentPeerNStream } from "./VoiceChat";
import { useHistory } from "react-router-dom";
import AppConfig from "../Config";


export function actionReleaseCard(
    selectedCard: TheCard | undefined,
    setSelectedCard: React.Dispatch<React.SetStateAction<TheCard | undefined>>,
    onGameChange: (game: Game) => void,
    roomId: string,
    playerId: string,
    noOfPlayers: number,
    setModHandCards: React.Dispatch<React.SetStateAction<TheCard[] | undefined>>,
    setModPrevCards: React.Dispatch<React.SetStateAction<TheCard[] | undefined>>,
    currentHandCards: TheCard[] | undefined,
    currentPrevCards: TheCard[] | undefined,
    t: TFunction,
) {    
    if (selectedCard) {
        animate(false);
        // setLoader(true);
        if(currentPrevCards !== undefined && currentHandCards !== undefined){
            const deckCards = [...currentPrevCards,selectedCard];
            let handCards = [...currentHandCards];
            handCards = handCards.filter(a => a.id !== selectedCard.id)
            setModHandCards(handCards);
            setModPrevCards(deckCards);
        }


        SoundFx.play("release")

        gameAction(roomId, playerId, GameAction.ReleaseCard, selectedCard).then(g => {
            // setLoader(false);
            setSelectedCard(undefined);
            onGameChange(g);
            setModHandCards(undefined)
            setModPrevCards(undefined)

        }, (e) => {
            // setLoader(false);
            gameMessage(e);
        });
    }
    else{
        gameMessage(t("Select a single card."));
    }
}

export default function useOnAction(
    playerHand: TheCard[][],
    roomId: string,
    playerId: string,
    selectedCard: TheCard | undefined,
    noOfPlayers: number,
    setSelectedCard: React.Dispatch<React.SetStateAction<TheCard | undefined>>,
    onGameChange: (game: Game) => void,
    setModHandCards: React.Dispatch<React.SetStateAction<TheCard[] | undefined>>,
    setModPrevCards: React.Dispatch<React.SetStateAction<TheCard[] | undefined>>,
    currentHandCards: TheCard[] | undefined,
    currentPrevCards: TheCard[] | undefined,
    players: Player[] | undefined
) {
    const { t } = useTranslation();
    const history = useHistory();

    return useCallback(async (action: GameAction) => {

        switch (action) {
            case GameAction.ReleaseCard: {
                // console.log("card", selectedCard);
                actionReleaseCard(selectedCard,  setSelectedCard, onGameChange, roomId, playerId,noOfPlayers,setModHandCards,setModPrevCards,currentHandCards,currentPrevCards,t)

                break;
            }
            case GameAction.Show: {
                if (selectedCard) {

                    gameConfirm(t("Are you sure you want to call show?"))
                    .then((a) => {
                        if (a) {
                            animate(false);
                            setLoader(true);
                            SoundFx.play("release")

                            gameAction(roomId, playerId, GameAction.Show, selectedCard)
                            .then(g => {
                                setLoader(false);

                                onGameChange(g);
                                setSelectedCard(undefined);
                            }, (e) => {
                                setLoader(false);
                                gameMessage(e);
                            });


                        }
                    })

                }
                else
                    gameMessage(t("Select a card"));

                break;
            }
            case GameAction.Declare:
            case GameAction.DeclareShow: {

                console.log("player hand", playerHand);

                if (playerHand.length <= 1) {
                    gameMessage(t("You should have atleast {{theCount}} groups of cards.",{theCount:t("2")}));
                    return;
                }

                setLoader(true);
                SoundFx.play("declare");

                

                gameAction(roomId, playerId, action, undefined, playerHand)
                .then((g:Game) => {
                    setLoader(false);
                    onGameChange(g);
                    setSelectedCard(undefined);

                    AppConfig.show_ad_on_declare && players && (checkIfSuperUserExist(players) || showAd(t))
                    
                    if(action === GameAction.DeclareShow)
                        Play.updatePlayerAchivements(noOfPlayers,g,playerHand);


                }, (e) => {
                    setLoader(false);
                    gameMessage(e);
                });

                

                break;
            }
            case GameAction.Drop: {
                gameConfirm(t("Are you sure you want to Drop?"))
                .then(a => {
                    if (a) {
                        SoundFx.play("drop")  
                    
                        setLoader(true);
                        gameAction(roomId, playerId, action,undefined, playerHand).then((g) => {
                            setLoader(false);
                            onGameChange(g);
                            AppConfig.show_ad_on_drop && players && (checkIfSuperUserExist(players) || showAd(t))

                        }, (e) => {
                            setLoader(false);
                            gameMessage(e);
                        });                        
                    }
                })

                break;
            }
            case GameAction.Invite: {
                ReactGA.event({
                    category: 'GameAction',
                    action: "Invite"
                });

                Firebase.logEvent("game_invite",{
                    noOfPlayers
                });

                Firebase.logEvent("game_action",{
                    type: "invite",
                    noOfPlayers
                });

                SoundFx.play("click")

                const manual = () => {
                    gameMessage(
                        <div>
                            <p dangerouslySetInnerHTML={{__html: t("to_invite_friends")}} ></p>
                            <input type='text' className='form-control' value='https://rummyfriends.page.link/play' />
                            <br />           
                            <p>{t("and join room id")}: <b>{roomId}</b></p>
                        </div>
                    )
                }

                const shareOption = {
                    title: t('Invite Friends'),
                    text: `${t("I invite you for a game of rummy.")} ${t("Room id")}: ${roomId}. ${t("To play install the android app")}`,
                    url: "https://rummyfriends.page.link/play" 
                }

                //@ts-ignore
                if (navigator.share) {
                    try {
                        //@ts-ignore
                        navigator.share(shareOption)
                        .catch((e:any) => {
                            manual();
                        })
                    }
                    catch (e) {
                        manual();
                    }
                }               
                else {
                    manual();
                }

                break
            }
            case GameAction.SkipStart:                
            case GameAction.Start:
            case GameAction.Ready: {
                SoundFx.play("click")     
                if (noOfPlayers <= 1) {

                    gameMessage(<div>{t("There are no playing opponent players in this room.")} <br /><br /><span dangerouslySetInnerHTML={{__html: t("use_the_invite")}} ></span></div>)
                    return;
                }

                if(action === GameAction.SkipStart){
                    const confirm = await gameConfirm(t("Are you sure you want to skip players who haven't pressed ready for next game?"));
                    if(!confirm){
                        return;
                    }
                }
    
                if (action === GameAction.Start){
                    // on start
                    Firebase.logEvent("game_start",{
                        type: "start",
                        noOfPlayers
                    });

                }
                else {
                    // ready
                    Firebase.logEvent("game_start",{
                        type: "ready",
                        noOfPlayers
                     });
                }

                // console.log("SuperTabe",superTable);
                setLoader(true);
                ReactGA.set({"metric1": 1});        

                gameAction(roomId, playerId, action, selectedCard).then((g) => {
                    setLoader(false);
                    onGameChange(g);
                    setSelectedCard(undefined);                    
                    if(action === GameAction.Ready){
                        AppConfig.show_ad_on_ready && players && (checkIfSuperUserExist(players) || showAd(t));
                    }

                }, (e) => {
                    setLoader(false);
                    gameMessage(e);
                });
                
                break;
            }
            case GameAction.SkipReEnter:{
                setLoader(true);
                SoundFx.play("click")

                Firebase.logEvent("leave_room",{
                    method: "resign",
                });

                destroyCurrentPeerNStream();
                gameAction(roomId, playerId, action, selectedCard).then((game) => {
                    setLoader(false);
                    history.push("/");
                    AppConfig.show_ad_on_room_exit && game && (checkIfSuperUserExist(game?.players) || showAd(t))

                }, (e) => {
                    setLoader(false);
                    gameMessage(e);
                });
                break;
            }
            default: {
                setLoader(true);
                if (action !== GameAction.PickCard && (action !== GameAction.PickDeck))
                    SoundFx.play("click")

                gameAction(roomId, playerId, action, selectedCard).then((g) => {
                    setLoader(false);
                    onGameChange(g);
                    setSelectedCard(undefined);

                }, (e) => {
                    setLoader(false);
                    gameMessage(e);
                });
            }
        }
    }, [noOfPlayers, history, playerHand, playerId, roomId, selectedCard, onGameChange, setSelectedCard, currentHandCards,currentPrevCards,setModHandCards,setModPrevCards,t,players]);
}
