import $ from 'jquery';
import { GameInfo, TheCard } from './Models';
import _ from "underscore";
import CardModel from './CardModel';

export function setLoader(show = true){
  const loader = document.body.querySelector(".loading");
  if(loader){
    if(show)
      $(loader).removeClass("hide");
    else
      $(loader).addClass("hide");
  }

}

export function animate(fromDeck: boolean,otherPlayer = false){
    const card = document.body.querySelector(".anim-card");
    if(card){
      const deckOffset = $(".playing-deck").offset();
      const deckHeight = $(".playing-deck").height();

      const handOffset = $(".player-hand").offset();

      const scroll  = $(window).scrollTop();

      if(deckOffset === undefined || scroll === undefined || deckHeight === undefined || handOffset === undefined)
        return;

      console.log("anima",fromDeck,otherPlayer);
      
      const deckPos = deckOffset.top  + deckHeight/2 + "px";
      let handPos = handOffset.top  + "px";

      if(otherPlayer)
        handPos = "0px";

      if(fromDeck){
        $(card).css({
          "display":"block",
          "opacity":1,
          "top": deckPos,
        });

        $(card).animate({
          opacity:0,
          top:handPos
        },300,"linear",() => {

          $(card).css({
            "display":"none",            
          });

        });

      }
      else{
        $(card).css({
          "display":"block",
          "opacity":1,
          "top": handPos,
        });

        $(card).animate({
          opacity:0,
          top:deckPos
        },300,"linear",() => {

          $(card).css({
            "display":"none",            
          });
          
        });
      }
    }
}


export function cloneObject<T>(anything:T): T{
  return JSON.parse(JSON.stringify(anything));
}

export function getHandJokers(hand: TheCard[][],deckJoker? : TheCard){
  const cardModel = new CardModel(deckJoker);
  let count = 0;
  const flat = hand.flat()
  flat.forEach(card => {
    if(cardModel.isJokerCard(card))
      count++;
  });
  
  return count;
}

export function checkIfHandHasTanala(hand: TheCard[][]){
  return _.any(hand,(cards) => {
    return CardModel.isTanala(cards);
  })
}


export function checkIfHandHas1Color(hand: TheCard[][]){
  const flat = hand.flat();
  const color = flat[0];
  return (_.every(flat,(card) => {
    return (card.type === color.type)
  }))
}

export function getPlayerTotal(roomHistory: GameInfo[],playerId: string){
  let score = 0;
  roomHistory.forEach(game => {
      const p = game.playerInfo.find(p => p.id === playerId)
      if(p){
          score += p.score;
      }
  })

  return score;
}

export function getHighestScore(roomHistory: GameInfo[],poolPoints:number){
  const players: {[key:string]:number} = {};
  roomHistory.forEach(game => {
      game.playerInfo.filter(a => a.score < poolPoints).forEach((p) => {
          if(players[p.id])
              players[p.id] += p.score;
          else
              players[p.id] = p.score;
      })        
  })

  let highest = 0;
  for(const score of Object.values(players)){
      if(score > highest)
          highest = score;
  }
  return highest;
}


export function useInputFocusFix(toggles: any[]){
  // useEffect(() => {
  //   console.log("test");

  //   const inputs = document.querySelectorAll("input[type=text],input[type=number]");
  
  //   const turnOffFS = () => {
  //     console.log("FS OFF")
  //     turnOffDeviceFS();
  //   }

  //   const turnOnFS = () => {
  //     console.log("FS ON")
  //     updateDeviceFS();
  //   }

  //   inputs.forEach(a => {
  //     a.addEventListener("focus",turnOffFS)
  //   })

  //   inputs.forEach(a => {
  //     a.addEventListener("blur",turnOnFS)
  //   })

  //   return () => {
  //     inputs.forEach(a => {
  //       a.removeEventListener("focus",turnOffFS)
  //       a.removeEventListener("blur",turnOnFS)
  //     })     
  //   }    
  // },[...toggles])
}
