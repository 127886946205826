import React, { useCallback } from "react";
import { TheCard, CardType } from "../Helpers/Models";
import { ReactSortable } from "react-sortablejs";
import { CardList } from "./CardList";
import { Card } from "./Card";
import { useTranslation } from "react-i18next";

interface Props{
    cards?: TheCard[];
    handCards?: TheCard[][];
    hideClosedCard?: boolean;
    onClick?: (isCard : boolean) => void;
    flash?: boolean;
    onDrop?: (card:TheCard) => void;
    acceptDrops?:boolean;
    name?:string;
    deckJoker?:TheCard;
}

const existingCard = new TheCard(CardType.closed);

export function PlayingDeck(props: Props){
    const items : TheCard[] = [existingCard];
    const {t} = useTranslation();

    const { onDrop } = props;
    const setList = useCallback((newList:TheCard[]) => {
        const newCard = newList.find(a => a.id !== existingCard.id);
        if(newCard)
            onDrop && onDrop(newCard);
    },[onDrop])

    
    return (
        <div className={(props.flash ? "flash":"") +' playing-deck '} >        
            {props.name && <div className='title' ><span className='name'>{props.name}{t("'s declaration")}</span></div>}
            <ReactSortable list={items} setList={setList} key={props.acceptDrops?1:2} disabled={!!!props.acceptDrops} filter={() => true} group="shared" className='deck-holder'  >
                {props.hideClosedCard ? 
                <>
                    <div className='list-holder' onClick={() => props.onClick && props.onClick(true)} >
                        {props.handCards && (
                                <>
                                    {props.handCards.map((cards,i) => (
                                        <CardList key={i} cards={cards} deckJoker={props.deckJoker} allHalfs />
                                    ))}
                                </>
                        )}
                        {props.cards && (
                            <CardList cards={props.cards} deckJoker={props.deckJoker} />
                        )}
                    </div>
                </> :
                <>
                    <div className='deck-holder-container' >
                        <div className='list-holder clickable prev-cards' onClick={() => props.onClick && props.onClick(true)} >
                            {props.cards && <CardList cards={[...props.cards].reverse()} reverse deckJoker={props.deckJoker} />}
                        </div>
                        <div className='list-holder clickable the-deck' style={{marginLeft:"15px"}} onClick={() => props.onClick && props.onClick(false)} >
                            <Card type={CardType.closed} className="deck-card" ></Card>
                            {props.deckJoker &&
                             <Card type={props.deckJoker.type} className="deck-joker" number={props.deckJoker?.number} cutJoker ></Card>}
                             
                        </div>
                    </div>
                </>}
            </ReactSortable>
        </div>
    )
}
