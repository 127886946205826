import { TheCard, GameAction } from "./Models";
import ReactGA from 'react-ga';
import { httpLoad } from "./Http";
import  Firebase from "../Phonegap/Firebase"

export async function gameAction(roomId:string, playerId:string,action:GameAction,card?: TheCard,hand?: TheCard[][]){
    // console.log("Action",action,card);

    ReactGA.event({
        category: 'GameAction',
        action: action
      });

    if(action === GameAction.Ready){
    }
    else if(action === GameAction.Start){
    }
    else if(action === GameAction.Declare){
        Firebase.logEvent("game_complete",{type: "declare"});
    }
    else if(action === GameAction.DeclareShow){
        Firebase.logEvent("game_complete",{type: "declare_show"});
    }


    let url = "/action/"+roomId+"/"+playerId+"/"+action;
    if(card){
        url += "/"+card.id;
    }    
    try{
        const res = await httpLoad(url,hand);
        if(res.ok)
            return res.data;
        else{
            throw (res.data)            
        }
    }
    catch(exp){
        throw exp;        
    }


}

