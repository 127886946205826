import { useEffect, } from 'react';

import { AudioConnectedPlayer, AudioConnectedPlayerStatus } from '../Helpers/Models';
import { httpLoadX } from '../Helpers/Http';
// const ConnectyCube = require("../connectycube/connectycube");
// import "../connectycube/dist/connectycube.min"


// @ts-ignore
const ConnectyCube = window.ConnectyCube;

let connectList:AudioConnectedPlayer[] = [];
let currentPlayerConnect: AudioConnectedPlayer;

let confSession:any;

let checkRoomRequest :() => void | undefined;
let updateMeetingId :() => void | undefined;

let storeConnectyCubeUserNames: string[] = [];

 function beginProcessingRequest(userSession:any,userId:string,roomId:string,playerId:string,allowMic:boolean){
   const mediaParams = {
      audio: true,
      video: false
   };

   confSession = ConnectyCube.videochatconference.createNewSession()
   confSession
      .getUserMedia(mediaParams)
      .then((localStream:any) => {
         // const elem = document.querySelector(".seated-players .player.self");
         // console.log(elem);

         // confSession.attachMediaStream("video"+playerId, localStream);
     
         // alert(allowMic);
      })


   const [roomRequest,p] = httpLoadX(`/store/${roomId}`);
   checkRoomRequest = roomRequest;
   
   p.then(a => {
     

      if(a.data){
         const [configId] = a.data;
         // console.log("room exists",configId)

         confSession.join(configId, userSession.id, playerId)
         .then((a:any) => {             
               if(!confSession)
                  return;
             confSession.unmuteAudio();

             if(!allowMic)
             setTimeout(() => {
               confSession.muteAudio();
             })
             currentPlayerConnect.status = allowMic ? AudioConnectedPlayerStatus.micconnected : AudioConnectedPlayerStatus.connected;
            // console.log("meeting joined",playerId,confSession.isAudioMuted())
         })
          
      }
      else{
         // console.log("room dpest exists")

         const params = {
            name: "My meeting",
            record: false,
            chat: false
            };
         //  debugger;
            ConnectyCube.meeting.create(params)
            .then((meeting:any) => {
               const confRoomId = meeting._id;
               // console.log("meeting requst",confRoomId);

               const [updateMeetingId,] = httpLoadX(`/store/${roomId}`,[confRoomId]);

               confSession.join(confRoomId, userSession.id, playerId)
               .then((a:any) => {
                  if(!confSession)
                     return;

                  confSession.unmuteAudio();
                  if(!allowMic)
                  setTimeout(() => {
                     confSession.muteAudio();
                   })
                   currentPlayerConnect.status = allowMic ? AudioConnectedPlayerStatus.micconnected : AudioConnectedPlayerStatus.connected;
                  // console.log("meeting joined",playerId,confSession.isAudioMuted())
               })
            })
      }
   })
 }

function signupLogin(userId:string,roomId:string,playerId:string,allowMic:boolean){
   const userProfile = {
      login: userId,
      password: userId,
      email: userId+"@rummyfriends.com",
      full_name: userId
    };
    
   ConnectyCube.users
   .signup(userProfile)
   .then((user:any) => {
      // console.log("signup")

      ConnectyCube.login(userProfile)
      .then((userSession:any) => {
         // console.log("login1")
         beginProcessingRequest(userSession,userId,roomId,playerId,allowMic);
      })
      .catch((error:any) => {
         // console.log("login fail1")
      });
   })
   .catch((error:any) => {
      // console.log("signup fail")

      ConnectyCube.login(userProfile)
      .then((userSession:any) => {
         // console.log("login2")
         beginProcessingRequest(userSession,userId,roomId,playerId,allowMic);
      })
      .catch((error:any) => {
         // console.log("login fail2",error)
      });
   });
}

 
 
export default function useVoiceChat(roomId:string, playerId:string, players: string[], allowSound: boolean, allowMic: boolean, refreshCounter: number) {

   useEffect(() => {
      const newPlayers:AudioConnectedPlayer[] = [];
      players.forEach(a => {
         if(connectList.findIndex(b => b.id === a) < 0){
            const p  = {id:a,name:a,status:AudioConnectedPlayerStatus.notconnected};

            newPlayers.push(p)
            if(a === playerId){
               currentPlayerConnect = p;
            }
         }
         
      })

      if(newPlayers.length){
         connectList = [...connectList,...newPlayers];
      }
   },[players,playerId])

   
   useEffect(() => {
      if(confSession){         
         // Toggle mute

         if(allowMic === false){
            // confSession.muteVideo()
            confSession.muteAudio()
            // console.log("muted")

         }
         else{
            // confSession.unmuteVideo()
            confSession.unmuteAudio()
            // console.log("unmuted")
         }
      }
      

      if(!confSession && (allowMic === true || allowSound === true)){
         // no session so create session

         

         const credentials = {
            appId: 5066,
            authKey: "KKCSY8k6NCK299B",
            authSecret: "TnySsZwdPKtNgGT"
          }
          
          const MULTIPARTY_SERVER_ENDPOINT = 'wss://janus.connectycube.com:8989';
          
          const appConfig = {
            debug: { mode: 1 },
            conference: { server: MULTIPARTY_SERVER_ENDPOINT },
          }
   
         ConnectyCube.init(credentials, appConfig);
         currentPlayerConnect.status = AudioConnectedPlayerStatus.connecting;
   
         const storedSession = sessionStorage.getItem("ConnectyCube:session");
         const userId = localStorage.getItem("userid");
         if(!userId)
          return;

         if(storedSession){
            const ccSession = JSON.parse(storedSession);
            ConnectyCube.setSession(ccSession);   
            signupLogin(userId,roomId,playerId,allowMic)
         }
         else{
   
            ConnectyCube.createSession()
            .then((appSession:any) => {
      
               sessionStorage.setItem("ConnectyCube:session",JSON.stringify(appSession));
               signupLogin(userId,roomId,playerId,allowMic)
            
            })
         }
        
         ConnectyCube.videochatconference.onRemoteStreamListener = (session:any, userId:any, stream:any) => {
            // console.log("onRemoteStreamListener",session);
   
            session.listOfOnlineParticipants()
            .then((participants:any) => {
               // console.log("participants",participants,session);
               const streamUser = participants.find((p:any) => {
                  return p.id === userId;
               })
   
               if(streamUser){
                  session.attachMediaStream("video"+streamUser.display, stream);
                 
               }
            })
   
         };  

         ConnectyCube.videochatconference.onParticipantJoinedListener = (session:any, userId:any, userDisplayName:any, isExistingParticipant:any) => {

            // session.listOfOnlineParticipants()
            // .then((participants:any) => {
            //    // console.log("participants",participants);
            //    const streamUser = participants.find((p:any) => {
            //       return p.id === userId;
            //    })
   
            //    if(streamUser){
            //       const connectedPlayer = connectList.find(b => b.id === streamUser.display);
            //       if(connectedPlayer){
            //          connectedPlayer.status = AudioConnectedPlayerStatus.micconnected;
            //       }
                  
            //    }
            // })

            storeConnectyCubeUserNames[userId] = userDisplayName;

            console.log("onparticipant join",userDisplayName)
            const connectedPlayer = connectList.find(b => b.id === userDisplayName);
            if(connectedPlayer){
               connectedPlayer.status = AudioConnectedPlayerStatus.micconnected;
            }
         }
         ConnectyCube.videochatconference.onParticipantLeftListener = (session:any, userId:any) => {
            
            if(storeConnectyCubeUserNames[userId]){
            console.log("onparticipant left",storeConnectyCubeUserNames[userId])

               const connectedPlayer = connectList.find(b => b.id === storeConnectyCubeUserNames[userId]);
               if(connectedPlayer){
                  connectedPlayer.status = AudioConnectedPlayerStatus.notconnected;
               }
            }
         }

      

      }

      if(confSession && allowMic === false && allowSound === false){
         // session exit but user 

         destroyCurrentPeerNStream();

      }
      


   },[allowMic,allowSound,playerId,roomId])



   useEffect(() => {
      document.querySelectorAll(".the-audio-players").forEach((a:any) => {
         a.muted = !allowSound;
      }) 

   },[allowSound])
 
   return connectList;
}

export function  destroyCurrentPeerNStream(){
   if(confSession && confSession.leave){
      confSession.leave()
      .then(() => {})      
      confSession = undefined;
      sessionStorage.setItem("ConnectyCube:session","");
      updateMeetingId && updateMeetingId()
      checkRoomRequest && checkRoomRequest();
      currentPlayerConnect.status = AudioConnectedPlayerStatus.notconnected;

   }
      
}