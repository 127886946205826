import { TheCard, CardType } from "../Helpers/Models";
import React from "react";
import { Card } from "./Card";
import CardModel from "../Helpers/CardModel";

interface TheCardList{
    cards : TheCard[];
    reverse?: boolean;
    deckJoker?: TheCard;
    allHalfs?: boolean;
}


export function CardList(props: TheCardList){
    const cardLen = props.cards.length;

    const isJoker = (card: TheCard) => {
        if(props.deckJoker){
            const cardModel = new CardModel(props.deckJoker);
            return cardModel.isJokerCard(card) && card.type !== CardType.joker;
        }
        return false;
    }
    
    return (
        <>
            <div className={'card-list '+(props.reverse?" reverse":"")} >
                {props.cards.map((a,i) => {                    
                    let halfcard = props.allHalfs || cardLen !== i+1;
                    if(props.reverse){
                        halfcard = props.allHalfs ||  i !== 0;
                    }
                    return (
                        <div key={i} className={"holder" + (halfcard?" half":"")} style={props.reverse ? {zIndex:props.cards.length-i}: {}} >
                            <Card type={a.type} number={a.number} half={halfcard} cutJoker={isJoker(a)} ></Card>
                        </div>
                    )
                })}          
    
            </div>
            
       </>
    )
}
